import { useMemo, useState } from 'react';
import { Package } from '@phosphor-icons/react';
import { AppTable, TableExpandCollapse } from '../../../../components/AppTable';
import { BodyContainer, HeaderBar, PageTitle } from '../../../../elements';
import { InventoryListHook } from '../../../../hooks/inventory';
import {
  InventoryAdjustmentPopup,
  InventoryItemRow,
} from '../../../../page-components';
import { CommonUtility } from '../../../../utility';
import { WarehouseListHook } from '../../../../hooks';
import { SearchInput, WarehouseDropdown } from '../../../../components';
import {
  DesktopMode,
  useTabletAndBelowMediaQuery,
} from '../../../../layouts/ResponsiveMedia';

function Header({
  warehouseOptions,
  warehouseLoading,
  warehouseId,
  setWarehouseId,
  search,
  setSearch,
  defaultWarehouse,
}) {
  const isMobile = useTabletAndBelowMediaQuery();
  return (
    <HeaderBar className="row">
      <div
        className={`d-flex ${
          isMobile ? 'flex-column col' : 'pt-2 col-8 align-items-center'
        }`}
      >
        <PageTitle className="d-flex align-items-center">
          <Package size={25} />
          <span className="mx-2">Inventory Management for</span>
        </PageTitle>
        <div className={`d-flex align-items-center ${isMobile ? 'ml-2' : ''}`}>
          <WarehouseDropdown
            warehouseOptions={warehouseOptions}
            warehouseId={warehouseId}
            warehouseLoading={warehouseLoading}
            setWarehouseId={setWarehouseId}
            defaultWarehouseId={defaultWarehouse}
          />
        </div>
      </div>
      <DesktopMode>
        <div className="col-4 d-flex justify-content-end">
          <SearchInput
            search={search}
            setSearch={setSearch}
            placeholder="Search title, SKU..."
          />
        </div>
      </DesktopMode>
    </HeaderBar>
  );
}

export function InventoryListScreen() {
  const [adjustmentItem, setAdjusmentItem] = useState(null);
  const [warehouseId, setWarehouseId] = useState('');
  const [customProps, setCustomProps] = useState({
    allExpandCollapse: {
      expand: false,
    },
  });

  const { data: warehouseData, loading: warehouseLoading } =
    WarehouseListHook();

  const warehouseOptions = useMemo(() => {
    if (CommonUtility.isValidArray(warehouseData)) {
      return warehouseData?.map(wh => ({
        text: wh?.name,
        key: wh?._id,
        value: wh?._id,
      }));
    }
    return [];
  }, [warehouseData]);

  const openAdjustmentPopup = item => {
    setAdjusmentItem(item);
  };

  const closeAdjustmentPopup = () => {
    setAdjusmentItem(null);
  };

  const {
    data,
    loading,
    totalData,
    totalPages,
    filter,
    filterChanged,
    pageChanged,
    refresh,
  } = InventoryListHook(warehouseId);

  const columns = [
    {
      text: 'Product Description',
      key: 'description',
      width: 5,
      textAlign: 'left',
    },
    {
      text: 'Location',
      key: 'location',
      width: 2,
      textAlign: 'left',
    },
    {
      text: 'On Hand',
      key: 'on_hand',
      width: 1,
      textAlign: 'center',
    },
    {
      text: 'Transfer',
      key: 'transfer',
      width: 1,
      textAlign: 'center',
    },
    {
      text: 'Allocated',
      key: 'allocated',
      width: 1,
      textAlign: 'center',
    },
    {
      text: 'Total',
      key: 'total',
      width: 1,
      textAlign: 'center',
    },
    {
      text: 'Lots',
      key: 'lots',
      width: 2,
      textAlign: 'left',
      component: (
        <TableExpandCollapse
          text="Lots"
          expand={customProps.allExpandCollapse.expand}
          clickHandler={(e, toggle) => {
            setCustomProps({
              ...customProps,
              allExpandCollapse: { expand: !toggle },
            });
            e.stopPropagation();
          }}
          spaceFromLeft={50}
        />
      ),
    },
  ];

  const defaultWarehouse = useMemo(
    () =>
      CommonUtility.isValidArray(warehouseData) ? warehouseData[0]._id : null,
    [warehouseData],
  );

  return (
    <BodyContainer>
      <Header
        setWarehouseId={setWarehouseId}
        warehouseId={warehouseId}
        warehouseOptions={warehouseOptions}
        warehouseLoading={warehouseLoading}
        search={filter.search}
        setSearch={search => filterChanged('search', search)}
        defaultWarehouse={defaultWarehouse}
      />
      <AppTable
        columns={columns}
        data={data}
        loading={loading}
        totalData={totalData}
        totalPages={totalPages}
        RowComponent={
          <InventoryItemRow
            customProps={customProps}
            openPopup={openAdjustmentPopup}
          />
        }
        currentPage={filter.page}
        pageSizeChanged={size => filterChanged('limit', size)}
        pageSize={filter.limit}
        pageChanged={pageChanged}
        customProps={customProps}
      />
      <InventoryAdjustmentPopup
        onClose={closeAdjustmentPopup}
        open={!!adjustmentItem}
        item={adjustmentItem}
        refresh={refresh}
        warehouseId={warehouseId}
      />
    </BodyContainer>
  );
}
