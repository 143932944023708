import { TableCell, TableRow } from 'semantic-ui-react';
import { Circle, Note } from '@phosphor-icons/react';
import styled from 'styled-components';
import { DarkPopup, OrganisationAvatar } from '../../components';
import { ColoredPill, MuteText } from '../../elements';
import { CommonUtility, DateFormat, DateUtility, theme } from '../../utility';

const statusColors = {
  pending: theme.colors.darkBlue,
};

const NoteContainer = styled.div`
  .note-icon {
    min-width: 21px;
  }
`;

const ReasonClip = styled.span`
  white-space: nowrap;
  max-width: 9vw;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export function ItemLogRow({ item }) {
  return (
    <TableRow>
      <TableCell>
        <div className="d-flex flex-column">
          <span>
            {DateUtility.formatDate(item.createdAt, DateFormat.HoursMinutes12)}
          </span>
          <span>
            {DateUtility.formatDate(item.createdAt, DateFormat.barChartMonth)}
          </span>
        </div>
      </TableCell>
      <TableCell>
        <ColoredPill color={statusColors[item.status] || theme.colors.grey}>
          <Circle weight="fill" />
          {CommonUtility.toTitleCase(item.status)}
        </ColoredPill>
      </TableCell>
      <TableCell>
        <div className="d-flex align-items-center">
          <OrganisationAvatar name={item?.user?.firstName} />
          &nbsp;{item?.user?.firstName}
        </div>
      </TableCell>
      <TableCell>
        <NoteContainer className="d-flex align-items-center">
          <Note className="note-icon" size={21} color={theme.colors.grey} />
          <div className="d-flex flex-column ml-2">
            <DarkPopup trigger={<ReasonClip>{item.notes}</ReasonClip>}>
              {item.notes}
            </DarkPopup>
            <MuteText>{item?.user?.firstName}</MuteText>
          </div>
        </NoteContainer>
      </TableCell>
      <TableCell>
        <div>
          {item.previousQuantity.available}
          <MuteText className="ml-1">Units</MuteText>
        </div>
      </TableCell>
      <TableCell>
        <div>
          {item.newQuantity.available > item.previousQuantity.available
            ? '+'
            : '-'}
          {item.quantityChange.amount}
          <MuteText className="ml-1">Units</MuteText>
        </div>
      </TableCell>
      <TableCell>
        <div>
          {item.newQuantity.allocated}
          <MuteText className="ml-1">Units</MuteText>
        </div>
      </TableCell>
      <TableCell>
        <div>
          {item.newQuantity.available}
          <MuteText className="ml-1">Units</MuteText>
        </div>
      </TableCell>
    </TableRow>
  );
}
