import { TableCell, TableRow } from 'semantic-ui-react';
import { RectangleDashed } from '@phosphor-icons/react';
import { ColorSwatch } from '../../../elements/ColorPicker';
import { ColoredPill, HoverBorderButton, MuteText } from '../../../elements';
import { CommonUtility } from '../../../utility';

export function PopupToteRow({ item, assignToExistingTote }) {
  return (
    <TableRow>
      <TableCell>{item.toteNumber}</TableCell>
      <TableCell>
        <ColorSwatch backgroundColor={item.color} />
      </TableCell>
      <TableCell>{CommonUtility.toTitleCase(item.type)}</TableCell>
      <TableCell>
        {item.zone?.code ? (
          <ColoredPill color={item.zone?.color}>
            <RectangleDashed /> {item.zone?.code}
          </ColoredPill>
        ) : (
          <MuteText>Not Set</MuteText>
        )}
      </TableCell>
      <TableCell>
        {item.dimensions?.length ? (
          <div>
            <span>{item.dimensions?.length}</span>
            <MuteText className="mx-1">x</MuteText>
            <span>{item.dimensions?.height}</span>
            <MuteText className="mx-1">x</MuteText>
            <span>{item.dimensions?.width}</span>
          </div>
        ) : (
          <MuteText>Not Set</MuteText>
        )}
      </TableCell>
      <TableCell>
        {item.dimensions?.weightLimit ? (
          item.dimensions.weightLimit
        ) : (
          <MuteText>Not Set</MuteText>
        )}
      </TableCell>
      <TableCell>
        <HoverBorderButton
          onClick={() => assignToExistingTote(item)}
          className="text-nowrap"
        >
          Select
        </HoverBorderButton>
      </TableCell>
    </TableRow>
  );
}
