import { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { Confetti } from '@phosphor-icons/react';
import { useNavigate } from 'react-router-dom';
import { CommonUtility, theme } from '../../../utility';
import { BoldText } from '../../../elements';
import { MobileWaveDetailFooter } from './footer';
import { ItemImage } from '../../../components';
import { MobileToteSelect } from './tote-mobile';
import { ExistingToteMobile } from './existing-tote';
import { ItemAllocationToteDetails } from '../../../hooks/tote';

const ActionHeaderText = styled.div`
  font-size: 2.6em;
  column-gap: 10px;
  row-gap: 25px;
  display: flex;
  white-space: nowrap;
  flex-wrap: wrap;
`;

const ProductContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 55vh;
  flex-direction: column;
  row-gap: 20px;
  .sku {
    font-size: 2em;
  }
`;

const PageContainer = styled.div`
  position: fixed;
  top: 67px;
  left: 0px;
  background: ${({ theme }) => theme.colors.box};
  width: 100vw;
  height: calc(100vh - 70px);
  z-index: 100;
  padding: 30px 20px;
  transition: all 0.5s linear;
  transform: translateY(102vh);
  &.visible {
    transform: translateY(0px);
  }
`;

export function WaveDetailMobileOnePage({
  data,
  pickItem,
  sortItem,
  itemMarkedTracker,
  refresh,
  allocations,
  waveLoading,
  isSorting,
  isActionAllowed,
  onePageView,
  setOnePageView,
  index,
  setIndex,
  warehouse,
}) {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const startPicking = () => {
    setOnePageView(true);
  };

  const showPickList = () => {
    setOnePageView(false);
  };

  const loadNextItem = (index = -1) => {
    const items = data?.allocations;
    for (let i = 0; i < items?.length; i += 1) {
      const item = items[i];
      const itemStatus = itemMarkedTracker[item.id];

      if (i !== index) {
        // prevent same item
        if (
          (isSorting && itemStatus !== 'sorted') ||
          (!isSorting && itemStatus !== 'picked')
        ) {
          setIndex(i);
          return;
        }
      }
    }

    // no item found
    setIndex(-1);
  };

  useEffect(() => {
    if (CommonUtility.isValidArray(allocations)) {
      loadNextItem();
    }
  }, [data, allocations, isSorting]);

  const currentItem = useMemo(() => {
    const items = data?.allocations;
    if (CommonUtility.isValidArray(items) && index > -1) {
      return items[index];
    }
    return null;
  }, [allocations, index, isSorting, data]);

  const actionText = currentItem?.status === 'picked' ? '' : 'Pick';

  const onConfirm = async () => {
    setLoading(true);

    if (currentItem.status !== 'picked') {
      if (CommonUtility.isValidArray(currentItem.sameItems)) {
        const promises = currentItem.sameItems.map(item => pickItem(item));
        Promise.all(promises).then(() => {
          setLoading(false);
          loadNextItem(index);
        });
      } else {
        await pickItem(currentItem);
        setLoading(false);
        loadNextItem(index);
      }
    } else {
      await sortItem(currentItem);
      setLoading(false);
      loadNextItem(index);
    }
  };

  const onCompletionClick = () => {
    if (isSorting) {
      navigate('/app/fulfill/waves/list');
    } else {
      refresh();
    }
  };

  const totalQty = useMemo(() => {
    if (CommonUtility.isValidArray(currentItem?.sameItems)) {
      let sum = 0;
      currentItem.sameItems.forEach(item => {
        sum += item.quantity;
      });
      return sum;
    }
    return currentItem?.quantity;
  }, [currentItem]);

  const requiresTote = currentItem?.sorting?.requiresTote;

  const useTote = async tote => {
    setLoading(true);
    await sortItem(currentItem, tote._id || tote.id);
    setLoading(false);
    loadNextItem(index);
  };

  const { data: toteData, loading: assignedToteLoading } =
    ItemAllocationToteDetails(requiresTote ? currentItem?.id : null);

  function Header() {
    if (!currentItem) {
      return <></>;
    }

    if (isSorting) {
      if (requiresTote) {
        return (
          <ActionHeaderText>
            <span>Place</span>
            <BoldText>{currentItem?.quantity}</BoldText>
            <span>Item(s)</span>
            <span>in a</span>
            <BoldText>Tote</BoldText>
          </ActionHeaderText>
        );
      }

      return (
        <ActionHeaderText>
          <span>Ship in Box</span>
          <BoldText>{currentItem?.quantity}</BoldText>
          <span>Item(s)</span>
        </ActionHeaderText>
      );
    }

    return (
      <ActionHeaderText>
        <span>Pick</span>
        <BoldText>{totalQty}</BoldText>
        <span>Item(s)</span>
        <span>from</span>
        <BoldText>{currentItem?.inventory?.location?.code}</BoldText>
      </ActionHeaderText>
    );
  }

  function Footer() {
    const hasTote = toteData?.tote;
    const hideButton = isSorting && requiresTote && !hasTote && onePageView;
    const clickAction = () => {
      if (!onePageView) {
        startPicking();
      } else if (!currentItem) {
        onCompletionClick();
      } else if (isSorting && requiresTote && hasTote) {
        useTote(toteData?.tote);
      } else {
        onConfirm();
      }
    };

    const buttonText = useMemo(() => {
      if (!onePageView) {
        return isSorting ? 'Start Sorting' : 'Start Picking';
      }
      if (!currentItem) {
        return isSorting ? 'Pick Next Wave' : 'Sort Wave';
      }
      if (isSorting && hasTote) {
        return 'Confirm';
      }
      return `Confirm ${actionText}`;
    }, [isSorting, onePageView, currentItem]);

    return (
      <MobileWaveDetailFooter
        onClick={!hideButton && clickAction}
        text={buttonText}
        loading={loading || waveLoading || assignedToteLoading}
        showPickList={onePageView && showPickList}
      />
    );
  }

  function Body() {
    if (!currentItem) {
      return (
        <div className="py-4">
          <ProductContainer onConfirm={onConfirm}>
            <Confetti color={theme.colors.green} size={45} />
            <ActionHeaderText className="justify-content-center">
              <span>You&apos;ve</span>
              <span>{isSorting ? 'Sorted' : 'Picked'}</span>
              <span>the</span>
              <span>entire</span>
              <span>Wave</span>
            </ActionHeaderText>
          </ProductContainer>
        </div>
      );
    }

    if (isSorting && requiresTote) {
      const hasTote = toteData?.tote;
      if (hasTote) {
        return (
          <ExistingToteMobile tote={toteData?.tote} currentItem={currentItem} />
        );
      }

      return (
        <MobileToteSelect
          currentItem={currentItem}
          warehouse={warehouse}
          useTote={useTote}
        />
      );
    }

    return (
      <ProductContainer>
        <ItemImage
          item={currentItem?.inventory?.product}
          imageKey="images"
          displayImageKey="display_image"
          size="small"
        />
        <span className="sku">{currentItem?.inventory?.product?.sku}</span>
      </ProductContainer>
    );
  }

  return (
    <>
      <PageContainer className={onePageView ? 'visible' : ''}>
        <Header />
        <Body />
      </PageContainer>
      {isActionAllowed && <Footer />}
    </>
  );
}
