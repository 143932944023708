import styled from 'styled-components';
import { ArrowDown, Handbag } from '@phosphor-icons/react';
import { ItemImage } from '../../../components';
import { BoldText, MuteText } from '../../../elements';
import { theme } from '../../../utility';

const ProductContainer = styled.div`
  .large-text {
    font-size: 20px;
  }

  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 65vh;
`;

const ArrowContainer = styled.div`
  margin: 20px 25px;
`;

const ToteContainer = styled.div`
  background: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.grey};
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
`;

export function ExistingToteMobile({ currentItem, tote }) {
  return (
    <ProductContainer>
      <div className="d-flex align-items-center">
        <ItemImage
          item={currentItem?.inventory?.product}
          imageKey="images"
          displayImageKey="display_image"
          size="tiny"
        />
        <div className="d-flex flex-column ml-3">
          <BoldText className="large-text">
            {currentItem?.inventory?.product?.sku}
          </BoldText>
          <span className="large-text mt-3">
            {currentItem?.inventory?.location?.code}
          </span>
        </div>
      </div>
      <ArrowContainer>
        <ArrowDown size={32} color={theme.colors.grey} />
      </ArrowContainer>
      <div className="d-flex align-items-center">
        <ToteContainer>
          <Handbag size={54} />
        </ToteContainer>
        <div className="ml-3">
          <MuteText className="large-text">Tote Number</MuteText>
          <span className="large-text mt-3">{tote?.toteNumber}</span>
        </div>
      </div>
    </ProductContainer>
  );
}
