import { DotsThree } from '@phosphor-icons/react';
import { useState } from 'react';
import { Button, Popup } from 'semantic-ui-react';
import styled from 'styled-components';

const ActionEditButton = styled.button`
  position: absolute;
  background: ${({ theme }) => theme.colors.black};
  border-radius: 5px;
  width: 26px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  cursor: pointer;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  transition: opacity 0.2s ease-in-out;
  opacity: 0;
`;

const ActionEditButtonContainer = styled.div`
  position: absolute;
  width: 40px;
  height: 40px;
  top: 0;
  right: 0;
  box-shadow: 0px 0px 25px rgba(57, 57, 72, 0.05);
  opacity: 0;
  &.open {
    opacity: 1;
  }
  ${ActionEditButton} {
    opacity: 1;
    position: relative;
    right: 0;
    margin: 0 auto;
  }

  &.show-default {
    opacity: 1;
  }

  &.inline {
    position: static;
  }

  button {
    height: 40px;
    width: 40px;
  }
`;

const ActionPopup = styled(Popup)`
  background: ${({ theme }) => theme.colors.box};
  &:hover {
    ${({ preventChangingBg, theme }) =>
      !preventChangingBg &&
      `
        &:before{
            background: ${theme.colors.grey} !important;
        }
    `}
  }
`;

const ActionContainer = styled.div`
  flex-direction: column;
  display: flex;
  div {
    padding: 10px;
    cursor: pointer;
  }
`;

export const ActionMenuItem = styled(Button)`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.colors.box};
  min-width: 100px;
  margin: 0px !important;
  background: ${({ theme }) => theme.colors.white} !important;
  &:hover {
    background: ${({ theme }) => theme.colors.box} !important;
  }
  svg {
    margin-right: 5px;
    height: 18px;
    width: 18px;
    margin-bottom: -4px;
  }
  &.danger {
    color: ${({ theme }) => theme.colors.danger} !important;
  }
  &.disabled {
    opacity: 0.7;
    cursor: not-allowed;
    div {
      cursor: not-allowed;
    }
  }

  &.icon-btn {
    display: flex;
    &.loading img {
      opacity: 0;
    }

    img {
      width: 18px;
      height: 18px;
      margin-right: 5px;
    }
  }
`;

export function ActionEditDots({
  children,
  position,
  preventChangingBg = false,
  showByDefault = false,
  inline = false,
  ...props
}) {
  const [open, setOpen] = useState(false);

  return (
    <ActionEditButtonContainer
      className={`action-container ${inline ? 'inline' : ''} ${
        showByDefault ? 'show-default' : ''
      } ${open ? 'open' : ''}`}
    >
      <ActionPopup
        preventChangingBg={preventChangingBg}
        hoverable
        open={open}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        trigger={
          <ActionEditButton
            className="action-edit-button"
            role="button"
            type="button"
          >
            <DotsThree color="white" weight="bold" size={20} />
          </ActionEditButton>
        }
        position={position || 'top center'}
        style={{ padding: 0 }}
        className="action-popup"
        basic
        {...props}
      >
        <ActionContainer onClick={() => setOpen(false)}>
          {children}
        </ActionContainer>
      </ActionPopup>
    </ActionEditButtonContainer>
  );
}
