import { useState } from 'react';
import { TableCell, TableRow } from 'semantic-ui-react';
import styled from 'styled-components';
import {
  AppTable,
  LoaderBar,
  SearchInput,
  SkupremeModal,
} from '../../../components';
import { useDebouncedMemo, UsersListHook } from '../../../hooks';
import { CommonUtility, ToastMessage, WaveService } from '../../../utility';
import { HoverBorderButton, StyledButton } from '../../../elements';

const UsersContainer = styled.div`
  max-height: 50vh;
  overflow-y: auto;
`;

export function AssignWaveToUserPopup({ onClose, open, waveId, refresh }) {
  const [search, setSearch] = useState('');

  const { data: userData, loading: userLoading } = UsersListHook();

  const [loading, setLoading] = useState(false);

  const columns = [
    {
      text: 'Name',
      key: 'name',
      width: 3,
      textAlign: 'left',
    },
    {
      text: 'Email Address',
      key: 'EmailAddress',
      width: 4,
      textAlign: 'left',
    },
    {
      text: 'Role',
      key: 'role',
      width: 2,
      textAlign: 'left',
    },
    {
      text: '',
      width: 3,
      key: 'Actions',
      textAlign: 'right',
    },
  ];

  const filteredUsers = useDebouncedMemo(
    () => {
      if (search) {
        return userData?.filter(
          user =>
            CommonUtility.searchQueryCompare(user.firstName, search) ||
            CommonUtility.searchQueryCompare(user.lastName, search) ||
            CommonUtility.searchQueryCompare(user.email, search),
        );
      }
      return userData;
    },
    [userData, search],
    100,
  );

  const assignWave = async user => {
    try {
      setLoading(true);
      await WaveService.assignUser(waveId, { userId: user._id });
      ToastMessage.success('Wave assigned successfully');
      refresh();
      onClose();
    } catch (error) {
      ToastMessage.apiError(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <SkupremeModal
      onClose={onClose}
      size="tiny"
      open={open}
      title="Assign Wave"
    >
      {loading && <LoaderBar content="Assigning" />}
      <div className="d-flex justify-content-between align-items-center">
        <span>Please select a user to assign a wave</span>
        <SearchInput
          placeholder="Search Users"
          search={search}
          setSearch={e => setSearch(e)}
        />
      </div>
      <UsersContainer>
        <AppTable
          data={filteredUsers}
          RowComponent={<UserRow assignWave={assignWave} />}
          columns={columns}
          noDataMessage="No user data found"
          loading={userLoading}
          loadingRows={6}
          noShadow
        />
      </UsersContainer>
      <div className="mt-3">
        <HoverBorderButton onClick={onClose}>Close</HoverBorderButton>
      </div>
    </SkupremeModal>
  );
}

function UserRow({ item, assignWave }) {
  return (
    <TableRow>
      <TableCell>{item.firstName}</TableCell>
      <TableCell>{item.email}</TableCell>
      <TableCell>{item.role}</TableCell>
      <TableCell textAlign="right">
        <StyledButton onClick={() => assignWave(item)}>Assign</StyledButton>
      </TableCell>
    </TableRow>
  );
}
