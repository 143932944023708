import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { GearSix, SignOut } from '@phosphor-icons/react';
import { useState } from 'react';
import { Loader } from 'semantic-ui-react';
import { MenuIconContainer } from './components';
import { DarkPopup, OrganisationAvatar } from '../components';
import { useAuth } from '../contexts/auth';
import { AccountService } from '../utility/services/account';
import { BrowserUtility, ToastMessage, UserService } from '../utility';

const Footer = styled.div`
  height: 40px;
  display: flex;
  justify-content: space-between;
  padding: 5px 20px;
  column-gap: 10px;
  margin-bottom: 20px;
  margin-top: 10px;
`;

const OrganisationContainer = styled.div`
  display: flex;
  column-gap: 10px;
  .name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 200px;
  }
  border-radius: 5px;

  &:hover {
    background: ${({ theme }) => theme.colors.primaryLight};
  }

  &.active {
    background: ${({ theme }) => theme.colors.primaryLight};
  }

  padding: 7px;
  cursor: pointer;
`;

const OrganisationList = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 3px;
`;

const AvatarContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.colors.primaryLight};
  width: 40px;
  height: 40px;
  border-radius: 5px;
`;

export function MobileSecureFooter() {
  const [orgLoading, setOrgLoading] = useState(false);
  const { logout: providerLogout, currentOrg, user, setUserData } = useAuth();

  const logoutClick = () => {
    providerLogout();
  };

  const changeOrg = async item => {
    try {
      setOrgLoading(true);
      const response = await AccountService.switchOrganization(item._id);
      BrowserUtility.removeAll();
      setTimeout(() => {
        UserService.storeUser(response);
        setUserData(response);
        window.location.reload();
      }, [100]);
    } catch (error) {
      ToastMessage.apiError(error);
    } finally {
      setOrgLoading(false);
    }
  };

  return (
    <Footer>
      <DarkPopup
        trigger={
          <AvatarContainer className="cursor-pointer">
            {orgLoading ? (
              <Loader size="tiny" active inline inverted />
            ) : (
              <OrganisationAvatar name={currentOrg.name} />
            )}
          </AvatarContainer>
        }
        hoverable
        position="bottom right"
        basic
        offset={[0, -5]}
      >
        <OrganisationList>
          <b className="mb-2">Organizations</b>
          {user.organizations.map(org => (
            <OrganisationContainer
              className={org._id === currentOrg._id ? 'active' : ''}
              onClick={() => {
                if (!orgLoading && org._id !== currentOrg._id) {
                  changeOrg(org);
                }
              }}
              disabled={orgLoading}
            >
              <OrganisationAvatar name={org.name} mini />
              <span className="name">{org.name}</span>
            </OrganisationContainer>
          ))}
        </OrganisationList>
      </DarkPopup>
      <div className="d-flex">
        <Link to="/app/settings">
          <MenuIconContainer>
            <GearSix size={24} />
          </MenuIconContainer>
        </Link>
        <MenuIconContainer className="ml-4" onClick={logoutClick}>
          <SignOut size={24} />
        </MenuIconContainer>
      </div>
    </Footer>
  );
}
