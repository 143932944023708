import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { useState } from 'react';
import { BodyContainer } from '../../../elements';
import {
  ShipmentDetailHook,
  shipmentDetailsExtractor,
} from '../../../hooks/shipments';
import {
  DetailsLeftTab,
  DetailsRightTab,
  EditShipmentItems,
} from '../../../page-components/shipments';
import { MetricImperialToggle } from '../../../components/MetricImperialToggle';
import { SearchInput } from '../../../components';
import { ShipmentDetailHeader } from '../../../page-components/shipments/Headers/DetailHeader';
import { MarkRecievedPopup } from '../../../page-components/shipments/Popups';
import { ShipmentsService, ToastMessage } from '../../../utility';
import { useAuth } from '../../../contexts/auth';

const TabsContainer = styled.div`
  column-gap: 15px;
  row-gap: 15px;

  .tab-right,
  .tab-left {
    flex: 1;
  }
`;

const ShipmentBodyConatiner = styled(BodyContainer)`
  padding-bottom: 100px;
`;

export function ShipmentDetailScreen() {
  const { id } = useParams();
  const [itemRecieving, setItemRecieving] = useState(null);
  const { data, loading, refresh } = ShipmentDetailHook(id);
  const [imperial, setImperial] = useState(false);
  const [search, setSearch] = useState('');
  const { summary, cbm } = shipmentDetailsExtractor(data);
  const [userValue, setUserValue] = useState({});
  const [processing, setProcessing] = useState(false);
  const { user } = useAuth();

  const closeRecievingPopup = () => {
    setItemRecieving(null);
  };

  const openRecievingPopup = (item, attribute) => {
    setItemRecieving({
      item,
      attribute,
    });
  };

  const isRecount = false;
  const isViewOnly = false;
  const isStorageView = true;

  const onConfirm = async () => {
    let totalQty = 0;

    let items = [];
    data.items.forEach(item => {
      item.aliases.forEach(alias => {
        // if item is not aleady recieved and some qty is entered as recieved
        if (
          !alias.metadata?.checked_quantity &&
          userValue?.[item._id]?.received?.[alias._id]
        ) {
          totalQty += Number(userValue[item._id].received[alias._id]);

          items.push({
            productId: item.item_id,
            inventoryType: 'case',
            alias: alias.alias,
            quantity: {
              available: userValue[item._id].received[alias._id],
              uom: 'ea',
            },
          });
        }
      });
    });

    const payload = {
      customerOrganizationId: data.organization_id,
      warehouseId: data?.destination?.wmsMetadata?.wmsWarehouseId,
      note: '',
      items,
    };

    payload.note = `${user?.firstName} checked ${totalQty} quantity from shipment ${data.display_id}`;

    try {
      setProcessing(true);
      await ShipmentsService.checkQuantities(data._id, payload);
      ToastMessage.success('Item(s) updated successfully');
      refresh();
    } catch (error) {
      ToastMessage.apiError(error);
    } finally {
      setProcessing(false);
    }
  };

  const commonProps = {
    isRecount,
    isViewOnly,
    isStorageView,
    data,
    imperial,
    loading,
    processing,
    search,
    openRecievingPopup,
    warehouseId: data?.destination?.wmsMetadata?.wmsWarehouseId,
    userValue,
    setUserValue,
    onConfirm,
  };

  return (
    <ShipmentBodyConatiner>
      <ShipmentDetailHeader
        data={data}
        imperial={imperial}
        cbm={cbm}
        summary={summary}
      />
      <TabsContainer className="row">
        <DetailsLeftTab data={data} loading={loading} />
        <DetailsRightTab data={data} loading={loading} />
      </TabsContainer>
      <div className="d-flex justify-content-between mt-4">
        <MetricImperialToggle
          checked={imperial}
          onChange={() => setImperial(!imperial)}
        />
        <SearchInput
          search={search}
          setSearch={setSearch}
          placeholder="Search by Title, SKU, UPC"
          className="no-border col-3 pr-0"
        />
      </div>
      <EditShipmentItems {...commonProps} />
      <MarkRecievedPopup
        itemRecieving={itemRecieving}
        open={!!itemRecieving}
        onClose={closeRecievingPopup}
        shipmentData={data}
        refresh={refresh}
      />
    </ShipmentBodyConatiner>
  );
}
