import { BodyContainer } from '../../../../elements';
import { DesktopMode, MobileMode } from '../../../../layouts/ResponsiveMedia';
import { DesktopWaveDetailScreen } from './desktop-detail';
import { MobileWaveDetailScreen } from './mobile-detail';

export function WaveDetailScreen() {
  return (
    <BodyContainer>
      <DesktopMode>
        <DesktopWaveDetailScreen />
      </DesktopMode>
      <MobileMode>
        <MobileWaveDetailScreen />
      </MobileMode>
    </BodyContainer>
  );
}
