import styled from 'styled-components';
import { RectangleDashed } from '@phosphor-icons/react';
import { ActionEditDots, ActionMenuItem, ItemImage } from '../../../components';
import { MobileTable } from '../../../components/MobileTable';
import { ToteListWarehouseHook } from '../../../hooks/tote';
import { ColoredPill, MuteText } from '../../../elements';

function MobileToteRow({ item, useTote }) {
  return (
    <div className="row mobile-row">
      <div className="col-4 d-flex justify-content-center flex-column">
        <span>{item.toteNumber}</span>
        <div className="d-flex">
          <MuteText className="mr-1">Zone</MuteText>
          {item.zone?.code ? (
            <ColoredPill color={item.zone?.color}>
              <RectangleDashed /> {item.zone?.code}
            </ColoredPill>
          ) : (
            <MuteText>Not Set</MuteText>
          )}
        </div>
      </div>
      <div className="col-6 d-flex flex-column justify-content-center">
        <div className="d-flex">
          <MuteText>Dimensions</MuteText>
          <span className="ml-1">
            {item.dimensions?.length ? (
              <div>
                <span>{item.dimensions?.length}</span>
                <MuteText className="mx-1">x</MuteText>
                <span>{item.dimensions?.height}</span>
                <MuteText className="mx-1">x</MuteText>
                <span>{item.dimensions?.width}</span>
              </div>
            ) : (
              <MuteText>Not Set</MuteText>
            )}
          </span>
        </div>
        <div className="d-flex">
          <MuteText>Weight Limit</MuteText>
          <span className="ml-1">
            {item.dimensions?.weightLimit ? (
              item.dimensions.weightLimit
            ) : (
              <MuteText>Not Set</MuteText>
            )}
          </span>
        </div>
      </div>
      <div className="col-2 d-flex align-items-center">
        <ActionEditDots
          preventChangingBg
          on="hover"
          position="bottom left"
          showByDefault
          offset={[0, -10]}
        >
          <ActionMenuItem
            onClick={() => {
              useTote(item);
            }}
          >
            Select
          </ActionMenuItem>
        </ActionEditDots>
      </div>
    </div>
  );
}

const NameContainer = styled.div`
  .product-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 120px;
  }
  margin-left: 10px;
`;

const ProductContainer = styled.div`
  margin-top: 20px;
`;

const TableContainer = styled.div`
  max-height: 48vh;
  overflow-y: auto;
  margin: -5px;
  padding: 5px;
`;

export function MobileToteSelect({ currentItem, warehouse, useTote }) {
  const { loading: toteLoading, data: totes } = ToteListWarehouseHook(
    warehouse?._id,
    'open',
  );

  const columns = [
    {
      text: 'ID',
      key: 'id',
      width: 4,
      textAlign: 'left',
    },
    {
      text: 'Constraints',
      key: 'type',
      textAlign: 'left',
      width: 6,
    },
    {
      text: '',
      textAlign: 'left',
      key: 'actions',
      width: 2,
    },
  ];
  return (
    <ProductContainer>
      <div className="d-flex mb-4">
        <ItemImage
          item={currentItem?.inventory?.product}
          imageKey="images"
          displayImageKey="display_image"
          size="mini"
        />
        <NameContainer>
          <span className="product-name">
            {currentItem?.inventory?.product?.name}
          </span>
          <div className="d-flex">
            <MuteText>SKU</MuteText>
            <span className="ml-2">{currentItem?.inventory?.product?.sku}</span>
          </div>
        </NameContainer>
      </div>
      <TableContainer>
        <MobileTable
          columns={columns}
          loading={toteLoading}
          data={totes}
          noDataMessage="No Totes"
          RowComponent={<MobileToteRow useTote={useTote} />}
        />
      </TableContainer>
    </ProductContainer>
  );
}
