import { useMemo, useState } from 'react';
import { Image } from 'semantic-ui-react';
import { AppTable } from '../../../components/AppTable';
import { BodyContainer, HeaderBar, PageTitle } from '../../../elements';
import { InventoryTransferListHook } from '../../../hooks/inventory';
import { InventoryTransferRow } from '../../../page-components';
import {
  CommonUtility,
  InventoryAdjustmentTypes,
  InventoryService,
  ToastMessage,
} from '../../../utility';
import { WarehouseListHook } from '../../../hooks';
import { Images } from '../../../images';
import { WarehouseDropdown } from '../../../components';
import { useTabletAndBelowMediaQuery } from '../../../layouts/ResponsiveMedia';

function Header({
  warehouseOptions,
  warehouseLoading,
  warehouseId,
  setWarehouseId,
  defaultWarehouse,
}) {
  const isMobile = useTabletAndBelowMediaQuery();

  return (
    <HeaderBar className="row">
      <div
        className={`d-flex ${
          isMobile ? 'flex-column col' : 'pt-2 col-8 align-items-center'
        }`}
      >
        <PageTitle className="d-flex align-items-center">
          <Image src={Images.Icons.transferLogo} />
          <span className="mx-2">Inventory Transfers for</span>
        </PageTitle>
        <div className={`d-flex align-items-center ${isMobile ? 'ml-2' : ''}`}>
          <WarehouseDropdown
            warehouseOptions={warehouseOptions}
            warehouseId={warehouseId}
            warehouseLoading={warehouseLoading}
            setWarehouseId={setWarehouseId}
            defaultWarehouseId={defaultWarehouse}
            className="mb-1"
          />
        </div>
      </div>
    </HeaderBar>
  );
}

export function InventoryTransferListScreen() {
  const [warehouseId, setWarehouseId] = useState('');

  const { data: warehouseData, loading: warehouseLoading } =
    WarehouseListHook();

  const warehouseOptions = useMemo(() => {
    if (CommonUtility.isValidArray(warehouseData)) {
      return warehouseData?.map(wh => ({
        text: wh?.name,
        key: wh?._id,
        value: wh?._id,
      }));
    }
    return [];
  }, [warehouseData]);

  const {
    data,
    loading,
    totalData,
    totalPages,
    filter,
    filterChanged,
    pageChanged,
    refresh,
    setLoading,
  } = InventoryTransferListHook(warehouseId);

  const columns = [
    {
      text: 'Product Description',
      key: 'description',
      width: 4,
      textAlign: 'left',
    },
    {
      text: 'Status',
      key: 'status',
      width: 2,
      textAlign: 'left',
    },
    {
      text: 'From',
      key: 'from',
      width: 2,
      textAlign: 'left',
    },
    {
      text: 'To',
      key: 'to',
      width: 2,
      textAlign: 'left',
    },
    {
      text: 'Amount',
      key: 'amount',
      width: 1,
      textAlign: 'left',
    },
    {
      text: 'Assignee',
      key: 'assignee',
      width: 2,
      textAlign: 'left',
    },
    {
      text: 'Date Created',
      key: 'date_created',
      width: 1,
      textAlign: 'left',
    },
    {
      text: 'Note',
      key: 'note',
      width: 2,
      textAlign: 'left',
    },
    {
      text: '',
      key: 'action',
      width: 1,
      textAlign: 'center',
    },
  ];

  const completeTransfer = async item => {
    try {
      setLoading(true);
      const payload = {
        transferId: item.transferId,
        quantity: item.quantity,
        adjustmentType: InventoryAdjustmentTypes.completeTransfer,
        destinationLocationId: item.destinationLocation._id,
      };

      await InventoryService.updateInventory(item.inventoryId, payload);
      refresh();
      ToastMessage.success('Inventory transferred successfully');
    } catch (error) {
      ToastMessage.apiError(error);
      setLoading(false);
    }
  };

  const defaultWarehouse = useMemo(
    () =>
      CommonUtility.isValidArray(warehouseData) ? warehouseData[0]._id : null,
    [warehouseData],
  );

  return (
    <BodyContainer>
      <Header
        setWarehouseId={setWarehouseId}
        warehouseId={warehouseId}
        warehouseOptions={warehouseOptions}
        warehouseLoading={warehouseLoading}
        defaultWarehouse={defaultWarehouse}
      />
      <AppTable
        columns={columns}
        data={data}
        loading={loading}
        totalData={totalData}
        totalPages={totalPages}
        RowComponent={
          <InventoryTransferRow completeTransfer={completeTransfer} />
        }
        currentPage={filter.page}
        pageSizeChanged={size => filterChanged('limit', size)}
        pageSize={filter.limit}
        pageChanged={pageChanged}
      />
    </BodyContainer>
  );
}
