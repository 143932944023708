import { useParams } from 'react-router-dom';
import { useEffect, useMemo, useState } from 'react';
import { Loader } from 'semantic-ui-react';
import { WaveDetailHook } from '../../../../hooks/waves';
import { CommonUtility, ToastMessage, WaveService } from '../../../../utility';
import { ColoredBanner, HeaderBar, MuteText } from '../../../../elements';
import { MobileTable } from '../../../../components/MobileTable';
import { useGlobalContext } from '../../../../contexts';
import {
  MobileWaveDetailRow,
  WaveDetailMobileOnePage,
} from '../../../../page-components/waves/detail-mobile';
import { ActionEditDots, ActionMenuItem } from '../../../../components';

function WaveDetailsHeader({
  data,
  loading,
  itemMarkedTracker,
  isSorting,
  isActionAllowed,
  processing,
  generateDocument,
  canGenerateDocument,
}) {
  const total = data?.allocations?.length;
  const done = useMemo(
    () =>
      Object.values(itemMarkedTracker)?.filter(itemStatus => {
        if (isActionAllowed) {
          if (isSorting) {
            return itemStatus === 'sorted';
          }
          return itemStatus === 'picked';
        }
        return itemStatus === data.status;
      })?.length,
    [data, itemMarkedTracker, isActionAllowed],
  );

  return (
    <HeaderBar className="row">
      <div className="col-12 d-flex justify-content-between align-items-center">
        {loading ? (
          <Loader active inline size="small" />
        ) : (
          <MuteText className="mr-2">{data?.waveNumber}</MuteText>
        )}
        {canGenerateDocument ? (
          <ActionEditDots
            preventChangingBg
            on="hover"
            position="bottom left"
            showByDefault
            offset={[0, -10]}
            inline
          >
            <ActionMenuItem loading={processing} onClick={generateDocument}>
              Generate Document
            </ActionMenuItem>
          </ActionEditDots>
        ) : (
          total && (
            <MuteText className="ml-4">
              {done}/{total} Items
            </MuteText>
          )
        )}
      </div>
    </HeaderBar>
  );
}

export function MobileWaveDetailScreen() {
  const { id } = useParams();

  const { data, loading, refresh, allocations } = WaveDetailHook(id);
  const { setShowSidebar } = useGlobalContext();
  const [index, setIndex] = useState(-1);
  const [processing, setProcessing] = useState(false);

  const [itemMarkedTracker, setItemMarkedTracker] = useState({});
  const [onePageView, setOnePageView] = useState(false);

  const columns = [
    {
      text: 'Item',
      key: 'item',
      width: 7,
      textAlign: 'left',
    },
    {
      text: 'Content',
      key: 'quantity',
      width: 4,
    },
    {
      text: '',
      key: 'action',
      width: 1,
    },
  ];

  const setItem = (itemId, value) => {
    setItemMarkedTracker(itemMarkedTracker => ({
      ...itemMarkedTracker,
      [itemId]: value,
    }));
  };

  const markItemPicked = async (item, showMessage = false) => {
    const previousStatus = item.status;
    try {
      setItem(item.id, 'loading');
      const payload = {
        pickedQuantity: {
          value: item.quantity,
          uom: 'units',
        },
      };
      const res = await WaveService.pickWaveAllocation(id, item.id, payload);
      setItem(item.id, res?.data?.status);
      showMessage && ToastMessage.success('Item marked as picked successfully');
    } catch (error) {
      setItem(item.id, previousStatus);
      ToastMessage.apiError(error);
    }
  };

  const sendItemForAllocation = async (item, toteId, showMessage = false) => {
    const previousStatus = item.status;
    try {
      setItem(item.id, 'loading');

      const payload = {};
      if (item.sorting?.requiresTote) {
        payload.toteId = toteId;
      }

      const res = await WaveService.sortSingleItem(id, item.id, payload);
      setItem(item.id, res?.data?.status);
      showMessage && ToastMessage.success('Item sorted successfully');
    } catch (error) {
      setItem(item.id, previousStatus);
      ToastMessage.apiError(error);
    }
  };

  const isActionAllowed = [
    'ready',
    'in_progress',
    'picked',
    'sorting',
  ].includes(data?.wave?.status);

  const isSorting = useMemo(
    () => ['sorting', 'picked'].includes(data?.wave?.status),
    [data],
  );

  useEffect(() => {
    if (CommonUtility.isValidObject(data?.wave)) {
      const currentStatus = {};
      data?.wave?.allocations?.forEach(allocation => {
        currentStatus[allocation?.id] = allocation.status;
      });

      setItemMarkedTracker(currentStatus);
    }
  }, [data]);

  const onClick = item => {
    let index = -1;
    if (CommonUtility.isValidArray(item.sameItems)) {
      for (let i = 0; i < item.sameItems.length; i += 1) {
        const sameItem = item.sameItems[i];
        if (
          (isSorting && itemMarkedTracker[sameItem.id] !== 'sorted') ||
          (!isSorting && itemMarkedTracker[sameItem.id] !== 'picked')
        ) {
          index = i;
          break;
        }
      }
    } else {
      index = data?.wave?.allocations?.findIndex(x => x.id === item.id);
    }
    setIndex(index);
    setOnePageView(true);
  };

  useEffect(() => {
    setShowSidebar(!isActionAllowed);
  }, [isActionAllowed]);

  useEffect(
    () => () => {
      setShowSidebar(true);
    },
    [],
  );

  const generateDocument = async () => {
    try {
      setProcessing(true);
      await WaveService.generateDocument(id);
      ToastMessage.success('The labels have been sent to your email.');
    } catch (error) {
      ToastMessage.apiError(error);
    } finally {
      setProcessing(false);
    }
  };

  return (
    <div>
      <WaveDetailsHeader
        loading={loading}
        data={data?.wave}
        summary={data?.summary}
        itemMarkedTracker={itemMarkedTracker}
        isSorting={isSorting}
        isActionAllowed={isActionAllowed}
        generateDocument={generateDocument}
        processing={processing}
        canGenerateDocument={['sorted', 'completed'].includes(
          data?.wave?.status,
        )}
      />
      {!isActionAllowed && (
        <ColoredBanner>
          The Wave is {CommonUtility.toTitleCase(data?.wave?.status)}
        </ColoredBanner>
      )}
      <h3 className="mt-1">
        {isActionAllowed
          ? isSorting
            ? 'Sort List'
            : 'Pick List'
          : 'Item List'}
      </h3>
      <MobileTable
        columns={columns}
        loading={loading}
        data={allocations}
        RowComponent={
          <MobileWaveDetailRow
            isActionAllowed={isActionAllowed}
            isSorting={isSorting}
            onClick={onClick}
            itemMarkedTracker={itemMarkedTracker}
          />
        }
      />
      <WaveDetailMobileOnePage
        pickItem={markItemPicked}
        sortItem={sendItemForAllocation}
        data={data?.wave}
        isSorting={isSorting}
        itemMarkedTracker={itemMarkedTracker}
        refresh={refresh}
        allocations={allocations}
        waveLoading={loading}
        isActionAllowed={isActionAllowed}
        setOnePageView={setOnePageView}
        onePageView={onePageView}
        index={index}
        setIndex={setIndex}
        warehouse={data?.wave?.warehouseId}
      />
    </div>
  );
}
