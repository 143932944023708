import styled from 'styled-components';
import { Plus } from '@phosphor-icons/react';
import { Loader } from 'semantic-ui-react';
import { BoldText, ShadowBox } from '../../elements';
import { CommonUtility } from '../../utility';
import { AddRack } from './RackBox';
import { StaticAisleRenderer } from './AisleRendererStatic';
import { NoAisleRendererStatic } from './NoAisleRendereStatic';

const AilesContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
`;

const AddAisleBox = styled.div`
  height: 45px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px dashed ${({ theme }) => theme.colors.box};
  color: ${({ theme }) => theme.colors.grey};
  border-radius: 5px;
  cursor: pointer;
  transition: all ease 0.2s;
  &:hover {
    background-color: ${({ theme }) => theme.colors.box};
  }
  user-select: none;
`;

export function StaticRackViewer({
  data,
  addRackInAsile,
  aloneRacks,
  aisleView,
  loading,
  onClickEditAisle,
  openAislePopup,
  color,
}) {
  if (
    (!CommonUtility.isValidArray(data) &&
      !CommonUtility.isValidArray(aloneRacks)) ||
    loading
  ) {
    return (
      <ShadowBox className="mt-2">
        {loading && <Loader inline active />}
        {aisleView && (
          <AddAisleBox className="mb-2" onClick={openAislePopup}>
            <Plus className="mr-1" /> Aisle
          </AddAisleBox>
        )}
        <AddRack onClick={() => addRackInAsile()} />
      </ShadowBox>
    );
  }

  return (
    <ShadowBox className="mt-2">
      <BoldText>
        There are too many racks for the drag and drop interface. Please use the
        Location Feed upload feature to manage the locations.
      </BoldText>
      <AilesContainer className="mt-2">
        {aisleView && (
          <>
            {data?.map((aisle, index) => (
              <StaticAisleRenderer
                onClickEditAisle={onClickEditAisle}
                addRackInAsile={addRackInAsile}
                aisle={aisle}
                key={aisle._id}
                aisleIndex={index}
                color={color}
              />
            ))}
            <AddAisleBox onClick={openAislePopup}>
              <Plus className="mr-1" /> Aisle
            </AddAisleBox>
          </>
        )}
        {aisleView && <b className="name mt-2">No Aisle</b>}
        <NoAisleRendererStatic
          aloneRacks={aloneRacks}
          addRackInAsile={addRackInAsile}
          color={color}
        />
      </AilesContainer>
    </ShadowBox>
  );
}
