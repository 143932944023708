import { Image, TableCell, TableRow } from 'semantic-ui-react';
import styled from 'styled-components';
import { MapPin, Pencil, RectangleDashed } from '@phosphor-icons/react';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  ActionEditDots,
  ActionMenuItem,
  ItemImage,
  OrganisationAvatar,
} from '../../components';
import { BoldText, ColoredPill, MuteText } from '../../elements';
import { ExpandCollapseToggle } from '../shipments/Items/commonComponents';
import { DateFormat, DateUtility } from '../../utility';
import { Images } from '../../images';
import { ItemTags } from '../common';

const ActionRow = styled(TableRow)`
  position: relative;

  .action-container {
    margin: auto;
    background: transparent;
    right: 10px;
    bottom: 0;
  }

  &:hover {
    .action-container {
      opacity: 1 !important;
    }
  }
`;

export function InventoryItemRow({ item, openPopup, customProps }) {
  const [open, setOpen] = useState(customProps.allExpandCollapse.expand);

  useEffect(() => {
    setOpen(customProps.allExpandCollapse.expand);
  }, [customProps.allExpandCollapse.expand]);

  return (
    <>
      <ActionRow>
        <TableCell>
          <div className="d-flex">
            <ItemImage
              imageKey="p_images"
              displayImageKey="display_image"
              item={item.product}
            />
            <div className="ml-4">
              <div>{item.product.p_title}</div>
              <div className="d-flex">
                <MuteText className="mr-2">SKU</MuteText>
                <BoldText>{item.product.p_sku}</BoldText>
              </div>
              <div className="d-flex mt-2">
                <OrganisationAvatar
                  name={item?.customerOrganization?.company_name}
                  image={item?.customerOrganization?.logo?.url}
                  mini
                />
                &nbsp;{item?.customerOrganization?.company_name}
              </div>
              <div className="mt-2">
                <ItemTags state={item.product?.state} />
              </div>
            </div>
          </div>
        </TableCell>
        <TableCell textAlign="left">
          {item.locationSummary?.length === 1 && (
            <div>
              <ColoredPill color={item.locationSummary[0].zone.color}>
                <RectangleDashed /> {item.locationSummary[0].zone.code}
              </ColoredPill>
              <ColoredPill
                className="mt-1"
                color={item.locationSummary[0].zone.color}
              >
                <MapPin />
                {item.locationSummary[0]?.location.code}
              </ColoredPill>
            </div>
          )}
          {item.locationSummary?.length > 1 && (
            <MuteText>Multiple Locations</MuteText>
          )}
        </TableCell>
        <TableCell textAlign="center">{item.quantity.available}</TableCell>
        <TableCell textAlign="center">{item.quantity.transfer}</TableCell>
        <TableCell textAlign="center">{item.quantity.allocated}</TableCell>
        <TableCell textAlign="center">{item.quantity.total}</TableCell>
        <TableCell>
          <ExpandCollapseToggle
            collapseText="Collapse Lots"
            expandText="Expand Lots"
            title="Lots"
            open={open}
            setOpen={setOpen}
          />
          <ActionEditDots preventChangingBg on="hover" position="left center">
            <ActionMenuItem
              onClick={() => {
                openPopup({ ...item, lotIndex: -1 });
              }}
            >
              <Pencil />
              Edit Quantity
            </ActionMenuItem>
            <ActionMenuItem
              onClick={() => {
                openPopup({ ...item, lotIndex: -1, transferLot: true });
              }}
              className="icon-btn"
            >
              <Image src={Images.Icons.transferLogo} />
              Transfer
            </ActionMenuItem>
          </ActionEditDots>
        </TableCell>
      </ActionRow>
      {open &&
        item.locationSummary?.map((locationSummary, index) => (
          <LotRow
            item={locationSummary}
            openPopup={() => openPopup({ ...item, lotIndex: index })}
            transferLot={() =>
              openPopup({ ...item, lotIndex: index, transferLot: true })
            }
          />
        ))}
    </>
  );
}

const BoxShadowTableRow = styled(TableRow)`
  background: ${({ theme }) => theme.colors.box};
  position: relative;

  .action-container {
    margin: auto;
    background: transparent;
    right: 10px;
    bottom: 0;
  }

  &:hover {
    .action-container {
      opacity: 1 !important;
    }
  }
`;

function LotRow({ item, openPopup, transferLot }) {
  return (
    <BoxShadowTableRow textAlign="center">
      <TableCell textAlign="left">
        <div className="d-flex justify-content-between">
          <div>
            <MuteText>Lot #</MuteText>
            <Link
              to={`/app/inventory/item/${item.inventoryId}`}
              className="ml-2"
            >
              {item.lotNumber}
            </Link>
          </div>
          {item.expiryDate && (
            <div className="d-flex mr-4">
              <MuteText>Expiry</MuteText>
              <span className="ml-2">
                {DateUtility.formatDate(
                  item.expiryDate,
                  DateFormat.barChartMonth,
                )}
              </span>
            </div>
          )}
        </div>
      </TableCell>
      <TableCell>
        <div>
          <ColoredPill color={item.zone.color}>
            <RectangleDashed /> {item.zone.code}
          </ColoredPill>
          <ColoredPill className="mt-1" color={item.zone.color}>
            <MapPin />
            {item?.location.code}
          </ColoredPill>
        </div>
      </TableCell>
      <TableCell>{item.quantity.available}</TableCell>
      <TableCell>{item.quantity.transfer}</TableCell>
      <TableCell>{item.quantity.allocated}</TableCell>
      <TableCell>{item.quantity.total}</TableCell>
      <TableCell>
        <ActionEditDots preventChangingBg on="hover" position="left center">
          <ActionMenuItem
            onClick={() => {
              openPopup();
            }}
          >
            <Pencil />
            Edit Quantity
          </ActionMenuItem>
          <ActionMenuItem
            onClick={() => {
              transferLot();
            }}
            className="icon-btn"
          >
            <Image src={Images.Icons.transferLogo} />
            Transfer
          </ActionMenuItem>
        </ActionEditDots>
      </TableCell>
    </BoxShadowTableRow>
  );
}
