import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { MuteText, ShadowBox } from '../../elements';
import { OrderTime, WaveStatusRenderer } from './common';
import { ProgressBar } from '../../components';
import { theme } from '../../utility';

const StyledLink = styled(Link)`
  color: black;
  &:hover {
    color: black;
  }
`;

export function MobileWaveRow({ item }) {
  const total = item.allocations.length || 1;

  const done = useMemo(() => {
    const isSorted = item.status === 'sorted';

    const completedItems = item.allocations.filter(allocation => {
      if (isSorted) {
        return allocation.status === 'sorted';
      }

      return allocation.status === 'picked';
    });

    return completedItems?.length || 0;
  }, [item]);

  const percentage = useMemo(() => (done * 100) / total, [total, done]);

  return (
    <StyledLink to={`/app/fulfill/waves/${item._id}`}>
      <ShadowBox>
        <div className="d-flex justify-content-between">
          <div className="d-flex">
            <MuteText>ID</MuteText>
            <span className="ml-2">{item.waveNumber}</span>
          </div>
          <div className="d-flex">
            <MuteText>Items</MuteText>
            <span className="ml-2">{item.metadata.itemCount}</span>
          </div>
        </div>
        <div className="d-flex justify-content-between align-items-center">
          <OrderTime time={item.createdAt} isEnabled />
          <WaveStatusRenderer status={item.status} />
        </div>
        {total && (
          <div className="d-flex text-nowrap align-items-center">
            <ProgressBar
              customColor={theme.colors.green}
              progress={percentage}
            />
            <MuteText className="ml-2">
              {Math.round(percentage)}% Complete
            </MuteText>
          </div>
        )}
      </ShadowBox>
    </StyledLink>
  );
}
