import styled from 'styled-components';
import { AddRack } from './RackBox';
import { StaticRackBox } from './RackBoxStatic';

const RowContainer = styled.div`
  display: flex;
  column-gap: 10px;
  row-gap: 10px;
  flex-wrap: wrap;
`;

export function NoAisleRendererStatic({ aloneRacks, addRackInAsile, color }) {
  return (
    <RowContainer>
      {aloneRacks?.map((rack, index) => (
        <StaticRackBox
          levelCount={rack.levelCount}
          bayCount={rack.bayCount}
          code={rack.code}
          key={rack._id}
          index={index}
          _id={rack._id}
          aisleIndex="-1"
          aisleSide="-1"
          color={color}
        />
      ))}
      <AddRack onClick={() => addRackInAsile()} />
    </RowContainer>
  );
}
