import { APIPath } from '../constants';
import { BaseService } from './base';
import { CRUDService } from './crud';

class Shipments extends CRUDService {
  constructor() {
    super(APIPath.shipments);
  }

  checkInShipment(id, payload) {
    return BaseService.post(`${APIPath.shipments}/${id}/checkin`, payload);
  }

  checkQuantities(id, payload) {
    return BaseService.post(
      `${APIPath.shipments}/${id}/check-quantities`,
      payload,
    );
  }
}

const ShipmentsService = new Shipments();
Object.freeze(ShipmentsService);
export { ShipmentsService };
