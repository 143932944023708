import { Button as Btn } from 'semantic-ui-react';
import styled from 'styled-components';

const Button = styled(Btn)`
  font-weight: 400 !important;
  &.flex-btn {
    display: flex !important;
    align-items: center !important;
  }

  &.icon-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px;
    width: 40px;
  }

  &:not(.small, .tiny, .mini, .large) {
    height: 40px;
  }
`;

export const StyledButton = styled(Button)`
  background: ${({ theme }) => theme.colors.primary} !important;
  color: ${({ theme }) => theme.colors.white} !important;
  &:hover {
    background: #252533 !important;
  }
  &:active {
    background: #101018 !important;
  }
  &:disabled {
    background: #393948 !important;
  }
`;

export const GreenButton = styled(Button)`
  background: ${({ theme }) => theme.colors.green} !important;
  color: ${({ theme }) => theme.colors.white} !important;
  &:hover {
    background: #2eb237 !important;
  }
  &:active {
    background: #1ea227 !important;
  }
`;

export const FlatButton = styled(Button)`
  border-radius: 0px !important;
  background: white !important;
  &:hover {
    background: rgba(0, 0, 0, 0.1) !important;
  }
`;

export const StyleGreenHoverButton = styled(StyledButton)`
  &:hover {
    background: ${({ theme }) => theme.colors.green} !important;
    color: ${({ theme }) => theme.colors.white} !important;
  }
`;

export const BorderButton = styled(Button)({
  background: ({ backColor }) => `${backColor || 'none'}!important`,
  border: '0.5px solid !important',
  borderColor: ({ theme, backColor }) =>
    `${backColor || theme.colors.grey}!important`,
  color: ({ theme, color }) => `${color || theme.colors.black}!important`,
});

export const HoverBorderButton = styled(BorderButton)`
  &:hover {
    background: ${({ theme }) => theme.colors.black} !important;
    color: ${({ theme }) => theme.colors.white} !important;
  }
`;

export const DangerBorderButton = styled(BorderButton)`
  border-color: ${({ theme }) => theme.colors.danger} !important;
  color: ${({ theme }) => theme.colors.danger} !important;

  &:hover {
    background: ${({ theme }) => theme.colors.danger} !important;
    color: ${({ theme }) => theme.colors.white} !important;
  }
`;

export const PurpleBorderButton = styled(BorderButton)`
  border-color: ${({ theme }) => theme.colors.purple} !important;
  color: ${({ theme }) => theme.colors.purple} important;
`;

export const LightBorderButton = styled(BorderButton)`
  border-color: ${({ theme }) => theme.colors.white} !important;
  color: ${({ theme }) => theme.colors.white} !important;

  &:hover {
    background: ${({ theme }) => theme.colors.white} !important;
    color: ${({ theme }) => theme.colors.black} !important;
  }
`;

export const WhiteBackgroundButton = styled(StyledButton)`
  background-color: ${({ theme }) => theme.colors.white} !important;
  color: ${({ theme }) => theme.colors.black} !important;
  &:hover {
    color: ${({ theme }) => theme.colors.white} !important;
    background-color: ${({ theme }) => theme.colors.primary} !important;
  }
`;

export const DangerButton = styled(Button)`
  background: ${({ theme }) => theme.colors.danger} !important;
  color: ${({ theme }) => theme.colors.white} !important;
  &:hover {
    background: #df3208 !important;
  }
  &:active {
    background: #cf2200 !important;
  }
`;

export const MuteButton = styled(Button)`
  background: ${({ theme }) => theme.colors.grey} !important;
  color: ${({ theme }) => theme.colors.white} !important;
  &:hover {
    background: #9c9da6 !important;
  }
  &:active {
    background: #8c8d96 !important;
  }
`;

export const AccentButton = styled(Button)`
  background: ${({ theme }) => theme.colors.accent} !important;
  color: ${({ theme }) => theme.colors.white} !important;
  &:hover {
    background: #3a9d97 !important;
  }
  &:active {
    background: #2a8d87 !important;
  }
`;

export const SuccessButton = styled(Button)`
  background: ${({ theme }) => theme.colors.success} !important;
  color: ${({ theme }) => theme.colors.white} !important;
  &.catalog-merge {
    display: inline-flex;
    align-items: center;
  }
  &.assign-supplier {
    opacity: 0.5;
    background: ${({ theme }) => theme.colors.primary} !important;
    &:hover {
      opacity: 1 !important;
    }
  }
  &:hover {
    background: #2aa064 !important;
  }
  &:active {
    background: #0a9044 !important;
  }
`;

export const PurpleButton = styled(Button)`
  background: ${({ theme }) => theme.colors.purple} !important;
  color: ${({ theme }) => theme.colors.white} !important;
  &:hover {
    background: #47186f !important;
  }
  &:active {
    background: #27004f !important;
  }
`;

export const YellowButton = styled(Button)`
  background: ${({ theme }) => theme.colors.yellow} !important;
  color: ${({ theme }) => theme.colors.white} !important;
  &:hover {
    background: #dfb700 !important;
  }
  &:active {
    background: #cfa700 !important;
  }
`;

export const StyledLink = styled.a`
  color: ${({ theme }) => theme.colors.accent};
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.colors.accent};
  }
`;

export const TextLink = styled.a`
  color: ${({ theme }) => theme.colors.black};
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.colors.black};
  }
`;

export const NormalLink = styled.a`
  cursor: pointer;
  ${({ disabled, theme }) => disabled && `color: ${theme.colors.grey};`}
  color: ${({ isMobile }) => (isMobile ? 'black' : '')};
`;

export const GreyLink = styled.a`
  color: ${({ theme }) => theme.colors.grey};
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.colors.grey};
  }
`;

export const ActiveLink = styled.a`
  font-size: 12px;
  line-height: 14px;
  font-weight: bold;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.primary};
  &:hover,
  &:focus {
    color: ${({ theme }) => theme.colors.primary};
  }
`;

export const PrimaryLink = styled.a`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.link};
  text-decoration: underline;
  ${({ disabled, theme }) =>
    disabled && `color: ${theme.colors.grey}; cursor: not-allowed;`}
  &:hover {
    color: ${({ theme }) => theme.colors.primary};
  }
  &:visited {
    color: ${({ theme }) => theme.colors.linkVisited};
  }
`;

export const SuccessHoverButton = styled(Button)`
  background: transparent !important;
  color: ${({ theme }) => theme.colors.green} !important;
  &:hover {
    background: ${({ theme }) => theme.colors.green} !important;
    color: ${({ theme }) => theme.colors.white} !important;
  }
`;

export const PrimaryHoverButton = styled(Button)`
  background: transparent !important;
  color: ${({ theme }) => theme.colors.primary} !important;
  &:hover {
    background: ${({ theme }) => theme.colors.primary} !important;
    color: ${({ theme }) => theme.colors.white} !important;
  }
`;
