import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Form, FormGroup } from 'semantic-ui-react';
import { useEffect, useMemo, useState } from 'react';
import { CommonUtility, ToastMessage, WaveService } from '../../../utility';
import { SkupremeModal } from '../../../components';
import {
  ControlledColorPickerField,
  ControlledTextFormField,
  DropdownFormField,
  HoverBorderButton,
  NumberFormField,
  StyledButton,
} from '../../../elements';
import { WarehouseZonesHook } from '../../../hooks/zone';
import { RandomUtility } from '../../../utility/random';

const ZoneSchema = yup.object().shape({
  toteNumber: yup.string().required('Rule name is Required'),
  color: yup.string(),
  zoneId: yup.string().optional(),
  length: yup
    .number()
    .nullable()
    .transform((_, val) => (!isNaN(val) ? Number(val) : null)),
  width: yup
    .number()
    .nullable()
    .transform((_, val) => (!isNaN(val) ? Number(val) : null)),
  height: yup
    .number()
    .nullable()
    .transform((_, val) => (!isNaN(val) ? Number(val) : null)),
  weight: yup
    .number()
    .nullable()
    .transform((_, val) => (!isNaN(val) ? Number(val) : null)),
  weightLimit: yup
    .number()
    .nullable()
    .transform((_, val) => (!isNaN(val) ? Number(val) : null)),
});

export function AddTotePopup({ refresh, open, onClose, warehouseId, item }) {
  const [loading, setLoading] = useState(false);
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({
    resolver: yupResolver(ZoneSchema),
    shouldUnregister: true,
  });

  const { data: zones } = WarehouseZonesHook(warehouseId);

  useEffect(() => {
    if (CommonUtility.isValidObject(item)) {
      reset({ ...item, ...(item.dimensions || {}), zoneId: item?.zone?.id });
    } else {
      reset({
        toteNumber: '',
        color: RandomUtility.generateDarkColor(),
        zoneId: '',
        length: '',
        width: '',
        height: '',
        weight: '',
        weightLimit: '',
      });
    }
  }, [open]);

  const zoneOptions = useMemo(() => {
    if (CommonUtility.isValidArray(zones)) {
      return zones?.map(wh => ({
        text: wh?.name,
        key: wh?._id,
        value: wh?._id,
      }));
    }
    return [];
  }, [zones]);

  const onSubmit = async formData => {
    try {
      setLoading(true);
      let payload = {
        warehouseId,
        toteNumber: formData.toteNumber,
        zoneId: formData.zoneId,
        color: formData.color,
        dimensions: {
          length: formData.length,
          width: formData.width,
          height: formData.height,
          weight: formData.weight,
          weightLimit: formData.weightLimit,
        },
      };

      payload = CommonUtility.removeEmptyFields(payload);

      if (CommonUtility.isValidObject(item)) {
        await WaveService.updateTote(payload, item.id);
      } else {
        await WaveService.createTote(payload);
      }

      ToastMessage.success('Zone successfully created.');
      onClose();
      refresh();
    } catch (error) {
      ToastMessage.apiError(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <SkupremeModal
      size="tiny"
      open={open}
      onClose={onClose}
      title={CommonUtility.isValidObject(item) ? 'Edit Tote' : 'Add New Tote'}
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormGroup>
          <ControlledTextFormField
            control={control}
            name="toteNumber"
            label="Tote Number"
            error={errors.toteNumber}
            hint={errors.toteNumber?.message}
            required
            width={16}
          />
          <ControlledColorPickerField
            control={control}
            name="color"
            label="Color"
            error={errors.color}
            hint={errors.color?.message}
          />
        </FormGroup>
        <FormGroup widths="equal">
          <DropdownFormField
            control={control}
            error={errors.zoneId}
            hint={errors.zoneId?.message}
            name="zoneId"
            label="Zone"
            options={zoneOptions}
            selection
          />
        </FormGroup>
        <FormGroup widths="equal">
          <NumberFormField
            control={control}
            error={errors.length}
            hint={errors.length?.message}
            name="length"
            label="Length"
          />
          <NumberFormField
            control={control}
            error={errors.width}
            hint={errors.width?.message}
            name="width"
            label="Width"
          />
          <NumberFormField
            control={control}
            error={errors.height}
            hint={errors.height?.message}
            name="height"
            label="Height"
          />
        </FormGroup>
        <FormGroup widths="equal">
          <NumberFormField
            control={control}
            error={errors.weight}
            hint={errors.weight?.message}
            name="weight"
            label="Weight"
          />
          <NumberFormField
            control={control}
            error={errors.weightLimit}
            hint={errors.weightLimit?.message}
            name="weightLimit"
            label="Weight Limit"
          />
        </FormGroup>
        <div className="d-flex justify-content-end">
          <HoverBorderButton type="button" onClick={onClose}>
            Cancel
          </HoverBorderButton>
          <StyledButton type="submit" loading={loading} className="ml-1">
            Create
          </StyledButton>
        </div>
      </Form>
    </SkupremeModal>
  );
}
