import { Form, Input, TextArea } from 'semantic-ui-react';
import { useEffect, useState } from 'react';
import { SkupremeModal } from '../../../components';
import { HoverBorderButton, MuteText, StyledButton } from '../../../elements';
import { NumberMaskInput } from '../../../elements/CustomMaskInput';
import {
  CommonUtility,
  ShipmentsService,
  ToastMessage,
} from '../../../utility';

export function MarkRecievedPopup({
  open,
  onClose,
  refresh,
  itemRecieving,
  shipmentData,
}) {
  const [recieved, setRecieved] = useState(0);
  const [reason, setReason] = useState('');
  const [totalQty, setTotalQty] = useState(0);
  const [loading, setLoading] = useState('');

  useEffect(() => {
    if (CommonUtility.isValidObject(itemRecieving?.attribute)) {
      setTotalQty(itemRecieving.attribute.metadata.checked_quantity);
      setRecieved(itemRecieving.attribute.metadata.checked_quantity);
    }
  }, [itemRecieving]);

  const onSubmit = async () => {
    try {
      if (!recieved) {
        ToastMessage.error('Recieved quantity must be greater than 0');
        return;
      }
      let payload = {
        customerOrganizationId: shipmentData?.organization_id,
        warehouseId: shipmentData?.destination?.wmsMetadata?.wmsWarehouseId,
        locationId: itemRecieving?.attribute?.location._id,
        note: reason,
        items: [
          {
            productId: itemRecieving?.item?.item_id,
            inventoryType: 'case',
            alias: itemRecieving?.attribute?.alias,
            quantity: {
              available: recieved,
              uom: 'ea',
            },
          },
        ],
      };
      payload = CommonUtility.removeEmptyFields(payload);
      setLoading(true);
      await ShipmentsService.checkInShipment(shipmentData?._id, payload);
      ToastMessage.success('Recieved auantity udpated');
      refresh();
      onClose();
    } catch (error) {
      ToastMessage.apiError(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <SkupremeModal
      onClose={onClose}
      open={open}
      size="tiny"
      title={`Mark ${itemRecieving?.attribute?.alias} as received`}
    >
      <div>
        <div className="d-flex">
          <div className="d-flex flex-column">
            <MuteText>Received</MuteText>
            <Input>
              <NumberMaskInput
                value={recieved}
                onChange={setRecieved}
                placeholder="Quantity"
                maxValue={totalQty}
              />
            </Input>
          </div>
          <div className="d-flex flex-column ml-4">
            <MuteText>Items in Pending Storage</MuteText>
            <span>{totalQty}</span>
          </div>
        </div>
        <div className="d-flex flex-column mt-1">
          <MuteText>Reason</MuteText>
          <Form>
            <TextArea
              onChange={e => setReason(e.target.value)}
              value={reason}
              placeholder="Reason of adjustment"
            />
          </Form>
        </div>
      </div>
      <div className="d-flex justify-content-end mt-4">
        <HoverBorderButton onClick={onClose} disabled={loading}>
          Cancel
        </HoverBorderButton>
        <StyledButton onClick={onSubmit} loading={loading} className="ml-2">
          Mark as Stored
        </StyledButton>
      </div>
    </SkupremeModal>
  );
}
