export const getRecountDefaultValues = items => {
  const userValue = {};
  items.forEach(item => {
    userValue[item._id] = {
      locations: {},
    };
    item.aliases.forEach(alias => {
      if (alias.location?._id) {
        userValue[item._id].locations[alias._id] = alias.location?._id || '';
      }
    });
  });
  return userValue;
};
