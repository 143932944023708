import { GridNine } from '@phosphor-icons/react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { MuteText } from '../../elements';

const RackViewContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: ${({ theme }) => theme.colors.box};
  cursor: pointer;
  padding: 15px;
  width: 125px;
  min-width: 125px;
  color: black;
  border-radius: 5px;
  row-gap: 7px;
  position: relative;
  height: 125px;

  .small {
    font-size: 12px;
  }

  &.is-over {
    background: ${({ theme }) => theme.colors.grey};
  }
`;

export function StaticRackBox({ _id, levelCount, bayCount, color, code }) {
  const navigate = useNavigate();

  const onClick = () => {
    navigate(`racks/${_id}`);
  };

  return (
    <RackViewContainer onClick={onClick}>
      <GridNine color={color} />
      <span>{code}</span>
      <div className="d-flex justify-content-between">
        <MuteText className="small">{levelCount} Levels</MuteText>
        <MuteText className="ml-2 small">{bayCount} Bays</MuteText>
      </div>
    </RackViewContainer>
  );
}
