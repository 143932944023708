import styled from 'styled-components';
import { Plus } from '@phosphor-icons/react';
import { Loader } from 'semantic-ui-react';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import update from 'immutability-helper';
import { useCallback } from 'react';
import { ShadowBox } from '../../elements';
import {
  aisleSides,
  CommonUtility,
  ErrorMessageConstant,
  ToastMessage,
} from '../../utility';
import { AddRack } from './RackBox';
import { AisleRenderer } from './AisleRenderer';
import { NoAisleRenderer } from './NoAisleRenderer';

const AilesContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
`;

const AddAisleBox = styled.div`
  height: 45px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px dashed ${({ theme }) => theme.colors.box};
  color: ${({ theme }) => theme.colors.grey};
  border-radius: 5px;
  cursor: pointer;
  transition: all ease 0.2s;
  &:hover {
    background-color: ${({ theme }) => theme.colors.box};
  }
  user-select: none;
`;

export function RackViewer({
  data,
  addRackInAsile,
  aloneRacks,
  aisleView,
  loading,
  onClickEditAisle,
  openAislePopup,
  setData,
  setAloneRacks,
  color,
  updatePositions,
}) {
  // When dropped in Aisle Plus region
  const onDropRack = useCallback((item, aisleIndex, aisleSide) => {
    const {
      aisleIndex: currentAisleIndex,
      aisleSide: currentAisleSide,
      index: dragIndex,
    } = item;

    const isComingfromNonAisle = currentAisleIndex === '-1';
    let nonAisleRack = null;

    // If Item was from non aisle first delete that
    if (isComingfromNonAisle) {
      setAloneRacks(aloneRacks => {
        nonAisleRack = aloneRacks[dragIndex];
        return update(aloneRacks, {
          $splice: [[dragIndex, 1]],
        });
      });
      if (!nonAisleRack) {
        ToastMessage.error(ErrorMessageConstant.defaultAPIError);
        return;
      }
    }

    setData(aisles => {
      const draggedItem = isComingfromNonAisle
        ? nonAisleRack
        : aisles[currentAisleIndex][currentAisleSide === 0 ? 'left' : 'right'][
            dragIndex
          ];

      let updatedAisles = aisles;

      // if item was from non aisle no need to delete anything from aisles
      if (!isComingfromNonAisle) {
        updatedAisles = update(aisles, {
          [currentAisleIndex]: {
            [currentAisleSide === 0 ? 'left' : 'right']: {
              $splice: [[dragIndex, 1]],
            },
          },
        });
      }
      draggedItem.aisleId = aisles[aisleIndex]._id;
      draggedItem.aisleSide =
        aisleSide === 0 ? aisleSides.left : aisleSides.right;
      return update(updatedAisles, {
        [aisleIndex]: {
          [aisleSide === 0 ? 'left' : 'right']: {
            $push: [draggedItem],
          },
        },
      });
    });

    updatePositions();
  }, []);

  // When hovered over rackes in Aisles
  const onHoverSortRack = useCallback(
    (
      aisleIndex,
      aisleSide,
      dragIndex,
      hoverIndex,
      newAisleIndex,
      newAisleSide,
    ) => {
      const isComingfromNonAisle = aisleIndex === '-1';

      let nonAisleRack = null;

      // If Item was from non aisle first delete that
      if (isComingfromNonAisle) {
        setAloneRacks(aloneRacks => {
          nonAisleRack = aloneRacks[dragIndex];
          return update(aloneRacks, {
            $splice: [[dragIndex, 1]],
          });
        });
      }

      setData(aisles => {
        const draggedItem = isComingfromNonAisle
          ? nonAisleRack
          : aisles[aisleIndex][aisleSide === 0 ? 'left' : 'right'][dragIndex];

        let updatedAisles = aisles;

        // if item was from non aisle no need to delete anything from aisles
        if (!isComingfromNonAisle) {
          updatedAisles = update(aisles, {
            [aisleIndex]: {
              [aisleSide === 0 ? 'left' : 'right']: {
                $splice: [[dragIndex, 1]],
              },
            },
          });
        }

        draggedItem.aisleId = aisles[newAisleIndex]._id;
        draggedItem.aisleSide =
          newAisleSide === 0 ? aisleSides.left : aisleSides.right;
        return update(updatedAisles, {
          [newAisleIndex]: {
            [newAisleSide === 0 ? 'left' : 'right']: {
              $splice: [[hoverIndex, 0, draggedItem]],
            },
          },
        });
      });
      updatePositions();
    },
    [],
  );

  // When dropped in a non Aisle Plus region
  const dropInNoAisleColumn = item => {
    // only one case is possible i.e. bringing a rack from aisle no no aisle region
    const { aisleIndex, aisleSide, index } = item;

    let itemToShift = {};

    setData(aisles => {
      itemToShift =
        aisles[aisleIndex][aisleSide === 0 ? 'left' : 'right'][index];
      return update(aisles, {
        [aisleIndex]: {
          [aisleSide === 0 ? 'left' : 'right']: {
            $splice: [[index, 1]],
          },
        },
      });
    });
    itemToShift.aisleId = null;
    itemToShift.aisleSide = null;
    setAloneRacks(aloneRacks =>
      update(aloneRacks, {
        $push: [itemToShift],
      }),
    );
    updatePositions();
  };

  // When hovered over racks in a non Aisle Region
  const onHoverNoAisle = (aisleIndex, aisleSide, oldIndex, newIndex) => {
    const isComingfromAisle = aisleIndex !== '-1';

    let itemToShift = null;
    // If rack is from aisle region first delete it from aisles array
    if (isComingfromAisle) {
      setData(aisles => {
        itemToShift =
          aisles[aisleIndex][aisleSide === 0 ? 'left' : 'right'][oldIndex];
        return update(aisles, {
          [aisleIndex]: {
            [aisleSide === 0 ? 'left' : 'right']: {
              $splice: [[oldIndex, 1]],
            },
          },
        });
      });
    }

    setAloneRacks(aloneRacks => {
      const draggedItem = isComingfromAisle
        ? itemToShift
        : aloneRacks[oldIndex];

      let updatedAisles = aloneRacks;

      // if Item was from aisle region no need to delete rack from non-aisle region
      if (!isComingfromAisle) {
        updatedAisles = update(aloneRacks, {
          $splice: [[oldIndex, 1]],
        });
      }
      draggedItem.aisleId = null;
      draggedItem.aisleSide = null;
      return update(updatedAisles, {
        $splice: [[newIndex, 0, draggedItem]],
      });
    });
    updatePositions();
  };

  if (
    (!CommonUtility.isValidArray(data) &&
      !CommonUtility.isValidArray(aloneRacks)) ||
    loading
  ) {
    return (
      <ShadowBox className="mt-2">
        {loading && <Loader inline active />}
        {aisleView && (
          <AddAisleBox className="mb-2" onClick={openAislePopup}>
            <Plus className="mr-1" /> Aisle
          </AddAisleBox>
        )}
        <AddRack onClick={() => addRackInAsile()} />
      </ShadowBox>
    );
  }

  return (
    <ShadowBox className="mt-2">
      <DndProvider backend={HTML5Backend}>
        <AilesContainer>
          {aisleView && (
            <>
              {data?.map((aisle, index) => (
                <AisleRenderer
                  onClickEditAisle={onClickEditAisle}
                  addRackInAsile={addRackInAsile}
                  aisle={aisle}
                  key={aisle._id}
                  onDropRack={onDropRack}
                  aisleIndex={index}
                  onHoverSortRack={onHoverSortRack}
                  color={color}
                />
              ))}
              <AddAisleBox onClick={openAislePopup}>
                <Plus className="mr-1" /> Aisle
              </AddAisleBox>
            </>
          )}
          {aisleView && <b className="name mt-2">No Aisle</b>}
          <NoAisleRenderer
            aloneRacks={aloneRacks}
            dropInNoAisleColumn={dropInNoAisleColumn}
            addRackInAsile={addRackInAsile}
            onHoverNoAisle={onHoverNoAisle}
            color={color}
          />
        </AilesContainer>
      </DndProvider>
    </ShadowBox>
  );
}
