import styled from 'styled-components';
import { Plus } from '@phosphor-icons/react';
import { useState } from 'react';
import { AppTable, ConfirmationPopup } from '../../../../components';
import { ToteListWarehouseHook } from '../../../../hooks/tote';
import { ToteRow } from '../../../../page-components/waves/tote/ToteRow';
import { HoverBorderButton } from '../../../../elements';
import { AddTotePopup } from '../../../../page-components/waves';
import { ToastMessage, WaveService } from '../../../../utility';

const HeaderContainer = styled.div`
  position: absolute;
  top: 40px;
  right: 10px;
  z-index: 998;
  height: 67px;
  padding: 14px;
  display: flex;
  align-items: center;
`;

function Header({ openPopup }) {
  return (
    <HeaderContainer>
      <HoverBorderButton className="flex-btn" onClick={openPopup}>
        <Plus className="mr-1" /> Tote
      </HoverBorderButton>
    </HeaderContainer>
  );
}

export function TotesTab({ warehouseId }) {
  const {
    data,
    totalData,
    totalPages,
    loading,
    filter,
    filterChanged,
    pageChanged,
    refresh,
  } = ToteListWarehouseHook(warehouseId, null);

  const [addRulePopup, setAddRulePopup] = useState(false);
  const [deleteTotePopup, setDeletePopup] = useState(null);
  const [processing, setProcessing] = useState(false);

  const columns = [
    {
      text: 'ID',
      key: 'id',
      width: 2,
      textAlign: 'left',
    },
    {
      text: 'Color Coding',
      key: 'color_coding',
      textAlign: 'left',
      width: 2,
    },
    {
      text: 'Type',
      key: 'type',
      textAlign: 'left',
      width: 2,
    },
    {
      text: 'Assigned Zone',
      textAlign: 'left',
      key: 'details',
      width: 2,
    },
    {
      text: 'Size',
      textAlign: 'left',
      key: 'size',
      width: 3,
    },
    {
      text: 'Weight Limit',
      textAlign: 'left',
      key: 'weight_limit',
      width: 2,
    },
    {
      text: 'Status',
      textAlign: 'left',
      key: 'status',
      width: 2,
    },
  ];

  const openPopup = () => {
    setAddRulePopup(true);
  };

  const closePopup = () => {
    setAddRulePopup(false);
  };

  const editTote = tote => {
    setAddRulePopup(tote);
  };

  const onDeleteTote = tote => {
    setDeletePopup(tote);
  };

  const onCloseDelete = () => {
    setDeletePopup(null);
  };

  const deleteTote = async () => {
    try {
      setProcessing(true);
      await WaveService.deleteTote(deleteTotePopup.id);
      ToastMessage.success(
        `${deleteTotePopup.toteNumber} deleted successfully.`,
      );
      setDeletePopup(null);
      refresh();
    } catch (error) {
      ToastMessage.apiError(error?.error || error);
    } finally {
      setProcessing(false);
    }
  };

  return (
    <div>
      <Header openPopup={openPopup} />

      <AppTable
        loading={loading}
        columns={columns}
        data={data || []}
        totalData={totalData}
        totalPages={totalPages}
        currentPage={filter.page}
        pageSizeChanged={size => filterChanged('limit', size)}
        pageSize={filter.limit}
        pageChanged={pageChanged}
        RowComponent={
          <ToteRow editTote={editTote} onDeleteTote={onDeleteTote} />
        }
      />

      <AddTotePopup
        onClose={closePopup}
        open={addRulePopup}
        refresh={refresh}
        warehouseId={warehouseId}
        item={addRulePopup}
      />

      <ConfirmationPopup
        onConfirm={deleteTote}
        onClose={onCloseDelete}
        open={deleteTotePopup}
        loading={processing}
        title="Delete Tote"
        message={`Are you sure to delete ${deleteTotePopup?.toteNumber}`}
      />
    </div>
  );
}
