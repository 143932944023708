import { Fragment, cloneElement, useRef, useEffect } from 'react';
import {
  Icon,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHeader,
  TableHeaderCell,
  TableRow,
} from 'semantic-ui-react';
import styled from 'styled-components';
import { Info } from '@phosphor-icons/react';
import { TablePagination } from './TablePagination';
import { AppTableRowPlaceholder } from './Placeholder';
import { CommonConstant } from '../utility/constants';
import { DarkPopup } from './Popup';

const TableContainer = styled.div`
  border-radius: 5px;
  margin-top: 1em;
  max-width: 100%;
  ${({ noShadow }) =>
    !noShadow && 'box-shadow: 0 5px 25px rgba(57,57,72,0.05)!important;'}
`;

export const ShadowTable = styled(Table)`
  thead tr:first-child > th {
    position: sticky !important;
    top: ${({ headeroffset }) => headeroffset}px;
    z-index: 2;
  }
  border: none !important;

  &.has-footer-table {
    margin-bottom: 0 !important;
  }

  &.sortable {
    thead {
      th {
        border-right: none !important;
        border-left: none !important;
      }
    }
  }

  td,
  th {
    word-break: break-word;
  }

  tr {
    &.action-hover {
      &:hover {
        .more-details {
          opacity: 1 !important;

          &:hover {
            background: ${({ theme }) => theme.colors.box};
            padding: 10px;
          }
        }
        .action-edit-button {
          opacity: 1;
        }
      }
      .action-cell {
        position: relative;
      }
    }
  }

  tr td {
    border-top: 1px solid #f6f6f6 !important;
  }

  tr th {
    border-bottom: 1px solid ${({ theme }) => theme.colors.black} !important;
  }

  tfoot tr th {
    border-bottom: none !important;
    border-top: 1px solid #f6f6f6 !important;
  }
`;

export const HoverTableRow = styled(TableRow)`
  transition: background 0.3s linear;
  &:hover {
    background: ${({ theme }) => theme.colors.box} !important;
    cursor: pointer;
  }
`;

export const ActionHoverTableRow = styled(TableRow)`
  transition: background 0.3s linear;
  &:hover {
    background: ${({ theme }) => theme.colors.box} !important;

    .more-details {
      opacity: 1 !important;
      &:hover {
        background: ${({ theme }) => theme.colors.box};
        padding: 10px;
      }
    }

    .action-container {
      opacity: 1 !important;
    }
  }
  .action-cell {
    position: relative;
  }
`;

export const BorderTableRow = styled(TableRow)`
  &:hover {
    .border {
      display: block;
    }
  }
`;

export const BorderTableCell = styled(TableCell)`
  position: relative;

  .border {
    display: ${({ expand }) => (expand === 'true' ? 'block' : 'none')};
    background: ${({ theme }) => theme.colors.grey};
    width: 5px;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
  }
`;

const CustomHeaderCell = styled(TableHeaderCell)`
  background: ${({ theme, bgcolor }) =>
    bgcolor || theme.colors.white} !important;
  color: ${({ theme, textcolor }) => textcolor || theme.colors.grey} !important;
  font-size: 12px;
  line-height: 14px;
  font-weight: normal !important;
  position: sticky !important;
  top: ${({ headeroffset }) => headeroffset}px;
  z-index: 10 !important;

  &.wrapped-cell {
    vertical-align: bottom;
    text-align: center;
    min-width: 80px;
    span {
      transform: rotate(230deg);
      white-space: normal;
      word-break: keep-all;
    }
  }

  &.no-sort {
    cursor: default !important;
  }
`;

function Columns({
  column,
  sort,
  sortChanged,
  customProps = {},
  headerOffSet,
}) {
  return (
    <CustomHeaderCell
      width={column.width}
      textAlign={column.textAlign || 'center'}
      sorted={
        column.sortKey
          ? sort[column.sortKey] === CommonConstant.sortDirection.ascending
            ? 'ascending'
            : 'descending'
          : null
      }
      onClick={() => column.sortKey && sortChanged(column.sortKey)}
      className={`${column.sortKey ? '' : 'no-sort'} ${column.className}`}
      style={column.style}
      bgcolor={column.bgcolor}
      textcolor={column.textcolor}
      headeroffset={headerOffSet}
    >
      {column.component
        ? cloneElement(column.component, {
            ...column.component.props,
            ...customProps[column.componentPropsKey],
          })
        : column.text}
    </CustomHeaderCell>
  );
}

export function AppTable({
  data,
  loading,
  currentPage,
  totalPages,
  pageChanged,
  pageSizeChanged,
  pageSize,
  RowComponent,
  HeaderRowComponent,
  AdditionalColumnRowComponent,
  totalData,
  wrapperClass,
  columns = [],
  noDataMessage = 'No Data Found',
  structured,
  textAlign = 'center',
  keyField = '_id',
  loadingRows = 4,
  sort,
  sortChanged,
  noShadow,
  customProps,
  headerOffSet = 0,
  isMobile,
  enableScrollToTop = false,
  footerComponent,
  customPageSizes,
}) {
  const myRef = useRef(null);

  const scrollToTop = () => {
    if (myRef?.current) {
      const yOffset = -80;
      const elementFromTop = myRef.current.getBoundingClientRect().top;
      const y = elementFromTop + window.pageYOffset + yOffset;
      window.scrollTo({ top: y });
    }
  };

  useEffect(() => {
    if (enableScrollToTop) scrollToTop();
  }, [data, myRef, enableScrollToTop]);

  const orderChanged = key => {
    let direction = '';
    if (!sort[key]) {
      const col = columns.find(x => x.sortKey === key);
      direction = col?.firstDirection || CommonConstant.sortDirection.ascending;
    } else {
      direction =
        sort[key] === CommonConstant.sortDirection.ascending
          ? CommonConstant.sortDirection.descending
          : CommonConstant.sortDirection.ascending;
    }
    sortChanged({ [key]: direction }, key);
  };

  return (
    <>
      <TableContainer ref={myRef} noShadow={noShadow} className={wrapperClass}>
        <ShadowTable
          headeroffset={headerOffSet}
          structured={structured}
          sortable
          padded
          className={
            (currentPage || 0) > 0 && (totalData || 0) > 10
              ? `${isMobile && 'd-block'} has-footer-table`
              : ''
          }
        >
          <TableHeader>
            {AdditionalColumnRowComponent &&
              cloneElement(AdditionalColumnRowComponent, {
                ...AdditionalColumnRowComponent.props,
              })}
            <TableRow textAlign={textAlign} verticalAlign="middle">
              {columns.map(item => (
                <Columns
                  headerOffSet={headerOffSet}
                  sort={sort}
                  customProps={customProps}
                  sortChanged={orderChanged}
                  key={item.key}
                  column={item}
                />
              ))}
            </TableRow>
          </TableHeader>
          <TableBody className="table-body">
            {HeaderRowComponent &&
              cloneElement(HeaderRowComponent, {
                ...HeaderRowComponent.props,
              })}
            {loading ? (
              <AppTableRowPlaceholder
                rows={loadingRows}
                colSpan={columns.length}
              />
            ) : (
              (data || []).map((item, index) => (
                <Fragment key={item[keyField]}>
                  {cloneElement(RowComponent, {
                    ...RowComponent.props,
                    item,
                    index,
                  })}
                </Fragment>
              ))
            )}
          </TableBody>
          {!loading && (data || []).length === 0 && (
            <TableFooter fullWidth>
              <TableRow>
                <TableHeaderCell colSpan={columns.length}>
                  {noDataMessage}
                </TableHeaderCell>
              </TableRow>
            </TableFooter>
          )}
        </ShadowTable>
      </TableContainer>
      {(footerComponent ||
        ((currentPage || 0) > 0 && (totalData || 0) > 10)) && (
        <TablePagination
          noPadding
          colSpan={columns.length}
          currentPage={currentPage}
          totalPages={totalPages}
          pageChanged={pageChanged}
          pageSizeChanged={pageSizeChanged}
          pageSize={pageSize}
          isMobile={isMobile}
          footerComponent={footerComponent}
          customPageSizes={customPageSizes}
          totalData={totalData}
        />
      )}
    </>
  );
}

const InfoIcon = styled(Info)`
  cursor: pointer;
  margin-left: 5px;
  margin-bottom: -2px;
`;

export function TableHeaderToolTip({
  label = '',
  helpText = '',
  helpPosition = 'top center',
  contentWidth,
  color,
  ...rest
}) {
  return (
    <>
      {label}
      {helpText && (
        <DarkPopup
          contentWidth={contentWidth}
          position={helpPosition}
          trigger={<InfoIcon size={14} color={color} />}
          {...rest}
        >
          {helpText}
        </DarkPopup>
      )}
    </>
  );
}

const ExpandCollapseButtonBox = styled.div`
  display: inline-block;
`;
const ExpandCollapseButton = styled.button`
  display: flex;
  align-items: center;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: 99;
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #393948;
  background: none;
  border: 0;
  cursor: pointer;
  left: ${({ spaceFromLeft }) => spaceFromLeft || 10}px;

  i {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 5px;
  }
`;

export function TableExpandCollapse({
  expand,
  clickHandler,
  text = '',
  spaceFromLeft = 0,
}) {
  return (
    <ExpandCollapseButtonBox>
      {text}
      <ExpandCollapseButton
        spaceFromLeft={spaceFromLeft}
        onClick={e => clickHandler(e, expand)}
      >
        {expand ? 'Collapse All' : 'Expand All'}
        <Icon name={expand ? 'chevron down' : 'chevron right'} />
      </ExpandCollapseButton>
    </ExpandCollapseButtonBox>
  );
}
