import { Circle } from '@phosphor-icons/react';
import styled from 'styled-components';
import { ColorText, GreenPill, GreyPill, OrangePill } from '../../elements';
import { CommonUtility, DateUtility, theme } from '../../utility';

const OrangeStatus = styled(OrangePill)`
  height: 24px;
  padding: 0 10px;
`;

const GreenStatus = styled(GreenPill)`
  height: 24px;
  padding: 0 10px;
`;

const GreyStatus = styled(GreyPill)`
  height: 24px;
  padding: 0 10px;
`;

export function WaveStatusRenderer({ status }) {
  const statusColor = {
    building: theme.colors.orange,
    ready: theme.colors.accent,
    completed: theme.colors.green,
    in_progress: theme.colors.purple,
    sorting: theme.colors.lightBlack,
    sorted: theme.colors.lightGrey,
    cancelled: theme.colors.red,
  };

  if (['cancelled'].includes(status)) {
    return (
      <GreyStatus>
        <Circle weight="fill" />
        {CommonUtility.toTitleCase(status)}
      </GreyStatus>
    );
  }

  if (['ready', 'completed', 'sorted'].includes(status)) {
    return (
      <GreenStatus>
        <Circle weight="fill" />
        {CommonUtility.toTitleCase(status)}
      </GreenStatus>
    );
  }

  return (
    <OrangeStatus color={statusColor[status]}>
      <Circle weight="fill" />
      {CommonUtility.toTitleCase(status)}
    </OrangeStatus>
  );
}

export function OrderTime({ time, isEnabled }) {
  let timeColor = '';
  const noOfDays = DateUtility.noOfDays(time);
  if (isEnabled) {
    if (noOfDays < 1) {
      timeColor = theme.colors.green;
    } else if (noOfDays <= 2) {
      timeColor = theme.colors.yellow;
    } else if (noOfDays <= 5) {
      timeColor = theme.colors.orange;
    } else if (noOfDays > 5) {
      timeColor = theme.colors.danger;
    }
  }
  return (
    <ColorText color={timeColor}>
      <b>{DateUtility.hourMinuteTime(time)}</b>
    </ColorText>
  );
}
