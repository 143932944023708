import styled from 'styled-components';
import { useDrop } from 'react-dnd';
import { AddRack, RackBox } from './RackBox';
import { ItemTypes } from './common';

const RowContainer = styled.div`
  display: flex;
  column-gap: 10px;
  row-gap: 10px;
  flex-wrap: wrap;
`;

const AisleShiftOnDropAccepter = styled.div`
  min-width: 125px;
  min-height: 10px;
  flex: 1;
  transition: all 0.3s;

  &.is-over {
    min-height: 30px;
    background: rgba(0, 0, 0, 0.02);
  }
`;

export function NoAisleRenderer({
  aloneRacks,
  dropInNoAisleColumn,
  addRackInAsile,
  onHoverNoAisle,
  color,
}) {
  const [{ isOver, canDrop, handlerId }, noAisleRef] = useDrop(() => ({
    accept: ItemTypes.rack,
    drop: item => dropInNoAisleColumn(item),
    collect: monitor => ({
      isOver: !!monitor.isOver(),
      canDrop: !!monitor.canDrop(),
      handlerId: monitor.getHandlerId(),
    }),
    canDrop: item => {
      if (item.aisleIndex === '-1') {
        return false;
      }
      return true;
    },
  }));

  return (
    <RowContainer>
      {aloneRacks?.map((rack, index) => (
        <RackBox
          levelCount={rack.levelCount}
          bayCount={rack.bayCount}
          code={rack.code}
          key={rack._id}
          index={index}
          _id={rack._id}
          aisleIndex="-1"
          aisleSide="-1"
          onHoverSortRack={onHoverNoAisle}
          color={color}
        />
      ))}
      <AisleShiftOnDropAccepter
        className={`${canDrop && isOver ? 'is-over' : ''} first-row`}
        ref={noAisleRef}
        data-handler-id={handlerId}
      >
        <AddRack onClick={() => addRackInAsile()} />
      </AisleShiftOnDropAccepter>
    </RowContainer>
  );
}
