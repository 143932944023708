import { useState } from 'react';
import { AppTable } from '../../../../../components/AppTable';
import { AllocationHook } from '../../../../../hooks';
import {
  UnAssignedRow,
  AssignAllocationPopup,
} from '../../../../../page-components';
import { useWavesFilterContext } from '.';
import {
  ToastMessage,
  AllocationService,
  OrderService,
} from '../../../../../utility';

export function UnAssignedListTab() {
  const { warehouseId } = useWavesFilterContext();

  const {
    data,
    totalData,
    totalPages,
    loading,
    filter,
    filterChanged,
    pageChanged,
    refresh,
  } = AllocationHook('unassigned', warehouseId);

  const [allocation, setAllocation] = useState(false);

  const [processing, setProcessing] = useState(false);

  const columns = [
    {
      text: 'Product Description',
      key: 'product',
      width: 5,
      textAlign: 'left',
    },
    {
      text: 'Status',
      key: 'status',
      textAlign: 'left',
      width: 2,
    },
    {
      text: 'Created',
      key: 'created',
      textAlign: 'left',
      width: 2,
    },
    {
      text: 'Zone',
      textAlign: 'left',
      key: 'zone',
      width: 2,
    },
    {
      text: 'Location',
      key: 'location',
      textAlign: 'left',
      width: 2,
    },
    {
      text: 'Details',
      textAlign: 'left',
      key: 'details',
      width: 2,
    },
  ];

  const assignAllocation = allocation => {
    setAllocation(allocation);
  };

  const onClose = () => {
    setAllocation(null);
  };

  const onAssignItem = async waveId => {
    try {
      setProcessing(true);
      await AllocationService.assignAllocationToWave(waveId, allocation.id);
      onClose();
      ToastMessage.success('Item assigned to wave successfully');
      refresh();
    } catch (error) {
      ToastMessage.apiError(error);
    } finally {
      setProcessing(false);
    }
  };

  const fulfillOrder = async item => {
    try {
      setProcessing(true);
      const payload = {
        allocations: [
          {
            quantity: item.quantity,
            allocationId: item.id,
          },
        ],
      };
      await OrderService.fulfillTote(item.order?.id, payload);
      ToastMessage.success('Order marked as fulfilled Successfully');
      refresh();
    } catch (error) {
      ToastMessage.apiError(error);
    } finally {
      setProcessing(false);
    }
  };

  return (
    <div>
      <AppTable
        loading={loading}
        columns={columns}
        data={data || []}
        totalData={totalData}
        totalPages={totalPages}
        currentPage={filter.page}
        pageSizeChanged={size => filterChanged('limit', size)}
        pageSize={filter.limit}
        pageChanged={pageChanged}
        RowComponent={
          <UnAssignedRow
            fulfillOrder={fulfillOrder}
            assignAllocation={assignAllocation}
          />
        }
      />
      <AssignAllocationPopup
        open={allocation}
        onClose={onClose}
        warehouseId={warehouseId}
        allocation={allocation}
        onAssignItem={onAssignItem}
        processing={processing}
      />
    </div>
  );
}
