import styled from 'styled-components';
import { cloneElement, isValidElement, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { HamburgerMenu, MobileModalPopup, StyledLink } from '../elements';
import { DesktopSidebar, MenuIconContainer } from './components/Menu';
import { CommonUtility, theme } from '../utility';
import { MobileSecureFooter } from './MobileSecureFooter';
import { useGlobalContext } from '../contexts';

const MainContainer = styled.div`
  display: flex;
  height: calc(100% - 80px);
`;

const MobileMenuButton = styled.div`
  width: 60px;
  height: 60px;
  right: 7px;
  bottom: 75px;
  position: fixed;
  background: ${({ theme }) => theme.colors.primary};
  filter: drop-shadow(0px 5px 25px rgba(57, 57, 72, 0.05));
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 10000;

  &:hover {
    transform: scale(1.07);
  }
`;

const preventDrag = e => {
  e.preventDefault();
};

const RightLink = styled(StyledLink)`
  display: flex;
  align-items: center;
  padding: 5px;
  transition: opacity 0.2s ease-in-out;
  opacity: 0;

  &.accordion-menu {
    opacity: 1;
  }

  i {
    margin: 0;
    width: 18px;
    height: 18px;
  }

  &.active {
    opacity: 1;
  }
`;

const FlexRow = styled.div`
  display: flex;
  align-items: center;
`;

const CenterFlexRow = styled(FlexRow)`
  justify-content: center;
`;

const UpgradeBox = styled(CenterFlexRow)`
  background: ${({ theme }) => theme.colors.green};
  position: absolute;
  top: 0;
  left: 10px;
  padding: 2px;
  border-radius: 3px;
  font-size: 13px;
  color: ${({ theme }) => theme.colors.white};
`;

const ActiveAccordion = styled.div`
  width: 0;
  height: 0;
  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent;
  border-right: 6px solid ${({ theme }) => theme.colors.lightBlack};
  position: absolute;
  right: -10px;
  top: 24px;
`;

export const MenuLink = styled.div`
  font-size: 10px;
  color: ${({ theme }) => theme.colors.grey};
  padding: 7px 0;
  position: relative;
  max-width: 40px;

  ${UpgradeBox} {
    left: auto;
    top: 10px;
    right: 2px;

    padding: 2px;
    font-size: 10px;
  }

  &.low-opacity {
    opacity: 0.6;
  }

  display: flex;
  flex-direction: column;
  align-items: center;
  line-height: 12px;

  .sidebar-icon-main {
    height: 28px;
    width: 28px;
    object-fit: contain;
  }
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.colors.background};
    .sidebar-icon-main {
      color: ${({ theme }) => theme.colors.white} !important;
    }
  }

  &.active {
    color: ${({ theme }) => theme.colors.white} !important;
    .sidebar-icon-main {
      color: ${({ theme }) => theme.colors.white} !important;
    }
  }
`;

const MobileMenuContainer = styled.div`
  ${RightLink} {
    opacity: 1;
  }

  padding: 0 20px;

  span,
  a {
    text-align: center;
  }
`;

function ParentMenu({ children }) {
  return <>{children}</>;
}

const MobileSecondarySidebarContainer = styled(DesktopSidebar)`
  width: calc(100% - 110px);
  border-left: 1px solid ${({ theme }) => theme.colors.lightBlack};
  padding: 0 10px;
  margin-left: 0px;
  position: static;
  a,
  span {
    font-size: 15px !important;
  }

  .sidebar-icon {
    height: 24px !important;
    width: 24px !important;
    margin-right: 10px !important;
  }
`;

const SubMenuLink = styled(Link)`
  font-size: 11px;
  color: ${({ theme }) => theme.colors.grey};
  padding: 7px;
  position: relative;
  width: 100%;
  margin: 5px 0;
  border-radius: 5px;

  ${UpgradeBox} {
    position: static;
  }

  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: all 0.2s ease;
  line-height: 12px;

  &.low-opacity {
    opacity: 0.6;
  }

  .sidebar-icon,
  .sidebar-icon-img {
    object-fit: contain;
  }

  .sidebar-icon-img {
    opacity: 0.5;
    height: 24px;
    width: 24px;
    margin-right: 10px;
  }

  &:hover {
    color: ${({ theme }) => theme.colors.white};
    background: ${({ theme }) => theme.colors.lightBlack};
    .sidebar-icon-img {
      opacity: 1;
    }
  }

  &.active {
    background: ${({ theme }) => theme.colors.lightBlack};
    color: ${({ theme }) => theme.colors.white} !important;
    .sidebar-icon {
      color: ${({ theme }) => theme.colors.white} !important;
    }
    .sidebar-icon-img {
      opacity: 1;
    }
  }
`;

function SubMenu({ item, closeSidebar }) {
  const active = CommonUtility.compareUrls(item.link, window.location.pathname);

  const count = useMemo(() => {
    if (!item.count) return null;
    if (item.count > 98) {
      return 99;
    }
    return item.count;
  }, [item]);

  return (
    <SubMenuLink
      className={`${active ? 'active' : ''} ${
        item.lowerOpacity ? 'low-opacity' : ''
      }`}
      to={item.link}
      data-field-value={`sidebar-${item.key}`}
      onDragStart={preventDrag}
      onClick={closeSidebar}
      key={item.key}
    >
      <div className="d-flex align-items-center">
        {isValidElement(item.phosphorIcon) &&
          cloneElement(item.phosphorIcon, {
            weight: active ? 'duotone' : 'regular',
          })}
        <span>{item.text}</span>
      </div>
      {!!count && <CountBadge>{count}</CountBadge>}
    </SubMenuLink>
  );
}

function SecondarySidebar({ activeMenu, menus, isCollapsed, closeSidebar }) {
  const hideSidebar = activeMenu < 0 || isCollapsed;

  const subMenus = useMemo(
    () => menus?.[activeMenu]?.menus || [],
    [menus, activeMenu],
  );

  return (
    <MobileSecondarySidebarContainer className={hideSidebar ? 'hidden' : ''}>
      {subMenus?.map(subMenu => (
        <SubMenu closeSidebar={closeSidebar} item={subMenu} />
      ))}
    </MobileSecondarySidebarContainer>
  );
}

const CountBadge = styled.div`
  background: ${({ theme }) => theme.colors.danger};
  position: absolute;
  top: 10px;
  right: 2px;
  border-radius: 3px;
  font-size: 10px;
  color: ${({ theme }) => theme.colors.white};

  height: 15px;
  width: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

function Menu({ item, activateMenu, isMenuActive }) {
  const active = window.location.pathname?.includes(item.parentLink);

  const count = useMemo(() => {
    if (!item.count) return null;
    if (item.count > 98) {
      return 99;
    }
    return item.count;
  }, [item]);

  return (
    <ParentMenu>
      <MenuLink
        className={`${active || isMenuActive ? 'active' : ''} ${
          item.lowerOpacity ? 'low-opacity' : ''
        }`}
        data-field-value={`sidebar-${item.key}`}
        onClick={activateMenu}
        onMouseEnter={activateMenu}
        onDragStart={preventDrag}
        key={item.key}
      >
        <MenuIconContainer
          className={`${active || isMenuActive ? 'active' : ''}`}
        >
          {isValidElement(item.phosphorIcon) && cloneElement(item.phosphorIcon)}
        </MenuIconContainer>
        <span>{item.text}</span>
        {!!count && <CountBadge>{count}</CountBadge>}
        {isMenuActive && <ActiveAccordion />}
      </MenuLink>
    </ParentMenu>
  );
}

export function MobileSecureMenu({
  activeMenu,
  closeMobilePopupSidebar,
  isCollapsed,
  openMobileMenu,
  setActiveMenu,
  setOpenMobileMenu,
  sidebarMenu,
}) {
  const { showSidebar } = useGlobalContext();

  return (
    <>
      <MobileModalPopup
        bgColor={theme.colors.primary}
        open={openMobileMenu}
        closeModal={closeMobilePopupSidebar}
        style={{
          height: '80vh',
          overflowY: 'auto',
        }}
      >
        <MobileSecureFooter />
        <MainContainer>
          <MobileMenuContainer>
            {sidebarMenu.map((item, i) => (
              <Menu
                activateMenu={() => {
                  setActiveMenu(i);
                }}
                item={item}
                key={item.key}
                isMenuActive={activeMenu === i && !isCollapsed}
              />
            ))}
          </MobileMenuContainer>
          <SecondarySidebar
            isCollapsed={isCollapsed}
            activeMenu={activeMenu}
            menus={sidebarMenu}
            closeSidebar={closeMobilePopupSidebar}
          />
        </MainContainer>
      </MobileModalPopup>
      {!openMobileMenu && showSidebar && (
        <MobileMenuButton>
          <HamburgerMenu onClick={() => setOpenMobileMenu(!openMobileMenu)} />
        </MobileMenuButton>
      )}
    </>
  );
}
