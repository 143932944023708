import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import styled from 'styled-components';
import { useEffect, useMemo } from 'react';
import { GearSix } from '@phosphor-icons/react';
import { BodyContainer, HeaderBar, PageTitle } from '../../../elements';
import { CacheUtility, CommonUtility, Tabs } from '../../../utility';
import { TabStyleMenu } from '../../../elements/Tab';
import { GeneralSettingsTab } from './integration';
import { OrganisationDetailTab } from './details';
import { UsersTab } from './users';
import { WarehouseTab } from './warehouse';
import { WarehouseListHook } from '../../../hooks';
import { BackButton, WarehouseDropdown } from '../../../components';
import { FulfillmentTab } from './fulfillment';

function Header({
  warehouseId,
  warehouseLoading,
  warehouseOptions,
  setWarehouseId,
  isWarehouseTab,
  showWarehouse,
  defaultWarehouse,
}) {
  return (
    <HeaderBar className="row tab-layout">
      <div className="col-6 d-flex align-items-center">
        {isWarehouseTab && <BackButton />}
        <PageTitle className="d-flex align-items-center">
          <GearSix size={25} className="mr-1" /> <span>Settings</span>
        </PageTitle>
        {showWarehouse && (
          <div className="d-flex align-items-center">
            <PageTitle className="mx-2">for</PageTitle>
            <WarehouseDropdown
              warehouseOptions={warehouseOptions}
              warehouseId={warehouseId}
              warehouseLoading={warehouseLoading}
              setWarehouseId={setWarehouseId}
              defaultWarehouseId={defaultWarehouse}
              className="mb-1"
            />
          </div>
        )}
      </div>
    </HeaderBar>
  );
}

const TabContainer = styled.div`
  margin-top: 20px;
`;

export function SettingsDetailScreen() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const {
    data: warehouseList,
    loading: warehouseLoading,
    refresh,
  } = WarehouseListHook();

  const warehouseOptions = useMemo(
    () =>
      warehouseList?.map(warehouse => ({
        value: warehouse._id,
        text: warehouse.name,
      })),
    [warehouseList],
  );

  const warehouseId = useMemo(() => {
    if (location.pathname) {
      return CommonUtility.getSegmentByPosition(location.pathname, 4);
    }
    return null;
  }, [pathname]);

  useEffect(() => {}, [warehouseList]);

  const currentTab = useMemo(() => {
    if (location.pathname) {
      return CommonUtility.getSegmentByPosition(location.pathname, 3);
    }
    return null;
  }, [pathname]);

  const tabClicked = tabName => {
    if (
      [Tabs.settingsTab.warehouse, Tabs.settingsTab.fulfillment].includes(
        tabName,
      ) &&
      warehouseId
    ) {
      navigate(`/app/settings/${tabName}/${warehouseId}`);
    } else {
      navigate(`/app/settings/${tabName}`);
    }
  };

  const menus = [
    {
      text: 'Organization Settings',
      value: Tabs.settingsTab.organisation,
    },
    {
      text: 'Integration Settings',
      value: Tabs.settingsTab.integration,
    },
    {
      text: 'Users',
      value: Tabs.settingsTab.users,
    },
    {
      text: 'Warehouse',
      value: Tabs.settingsTab.warehouse,
    },
    {
      text: 'Fullfillment',
      value: Tabs.settingsTab.fulfillment,
    },
  ];

  const onChangeWarehouse = warehouseId => {
    navigate(`/app/settings/${currentTab}/${warehouseId}`);
  };

  const onCreateWarehouse = id => {
    onChangeWarehouse(id);

    const cacheKey = 'warehouse_id_key';
    CacheUtility.save(cacheKey, { id });

    refresh();
  };

  const isWarehouseTab = currentTab === Tabs.settingsTab.warehouse;
  const showWarehouse = [
    Tabs.settingsTab.warehouse,
    Tabs.settingsTab.fulfillment,
  ].includes(currentTab);

  const defaultWarehouse = useMemo(() => {
    if (
      CommonUtility.isValidArray(warehouseList) &&
      !warehouseId &&
      showWarehouse
    ) {
      return warehouseList[0]?._id;
    }
    return null;
  }, [currentTab, showWarehouse]);

  return (
    <BodyContainer>
      <Header
        setWarehouseId={onChangeWarehouse}
        warehouseId={warehouseId}
        warehouseLoading={warehouseLoading}
        warehouseOptions={warehouseOptions}
        isWarehouseTab={isWarehouseTab}
        showWarehouse={showWarehouse}
        defaultWarehouse={defaultWarehouse}
      />
      <TabStyleMenu
        menus={menus}
        currentMenu={currentTab}
        onTabChange={tabClicked}
      />
      <TabContainer>
        <Routes>
          <Route index element={<Navigate to="organisation" />} />
          <Route path="integration" element={<GeneralSettingsTab />} />
          <Route path="organisation" element={<OrganisationDetailTab />} />
          <Route path="users" element={<UsersTab />} />
          <Route
            path="warehouse/*"
            element={
              <WarehouseTab
                warehouseId={warehouseId}
                onCreateWarehouse={onCreateWarehouse}
              />
            }
          />
          <Route
            path="fulfillment/*"
            element={<FulfillmentTab warehouseId={warehouseId} />}
          />
        </Routes>
      </TabContainer>
    </BodyContainer>
  );
}
