export const storageColumns = [
  {
    text: 'Product Information',
    key: 'product_information',
    width: 2,
    textAlign: 'left',
  },
  {
    text: 'Carton Properties',
    key: 'carton_properties',
    width: 2,
    textAlign: 'left',
  },
  {
    text: 'Location',
    key: 'location',
    textAlign: 'center',
    width: 2,
  },
  {
    text: '',
    key: 'action',
    textAlign: 'left',
    width: 2,
  },
];
