import { CaretDown, CaretRight, Check } from '@phosphor-icons/react';
import styled from 'styled-components';
import { Input, TableCell } from 'semantic-ui-react';
import { useMemo } from 'react';
import { BoldText, GreenPill, GreyPill, MuteText } from '../../../elements';
import { NumberMaskInput } from '../../../elements/CustomMaskInput';
import { ItemImage } from '../../../components';
import { ProductPropertiesConverted } from '../common';

export function ExpandCollapseToggle({
  open,
  setOpen,
  title = 'Multiple Aliases',
  className = '',
  expandText = 'Expand',
  collapseText = 'Collapse',
}) {
  return (
    <div
      className={`d-flex flex-column align-items-start justify-content-center ${className}`}
    >
      <MuteText>{title}</MuteText>
      <div
        onClick={() => setOpen(!open)}
        className="d-flex align-items-center cursor-pointer"
      >
        {open ? (
          <>
            <b>{collapseText}</b>
            <CaretDown className="ml-1" />
          </>
        ) : (
          <>
            <b>{expandText}</b>
            <CaretRight className="ml-1" />
          </>
        )}
      </div>
    </div>
  );
}

const MiniInput = styled(Input)`
  width: 70px;
`;

export function ItemMiniInput({
  value,
  onChange,
  maxQty,
  noMax = false,
  maskOptions = {},
  disabled = false,
}) {
  const maxValue = Math.floor(maxQty);

  return (
    <MiniInput size="mini">
      <NumberMaskInput
        value={value && Math.round(value)}
        onKeyPress={e => {
          e.key === 'Enter' && e.preventDefault();
        }}
        onChange={onChange}
        maxValue={noMax ? undefined : maxValue < 0 ? 0 : maxValue}
        maskOptions={maskOptions}
        disabled={disabled}
      />
    </MiniInput>
  );
}

export function ReadOnlyItemDetails({
  item,
  open,
  setOpen,
  latestAttribute,
  imperial,
}) {
  const alias = useMemo(
    () => item.alias || latestAttribute.alias || item.aliases?.[0]?.alias,
    [item],
  );

  const isMultiple = useMemo(() => item?.aliases?.length > 1, [item]);

  return (
    <>
      <TableCell>
        <div className="d-flex align-items-center">
          <ItemImage
            item={item}
            imageKey="p_images"
            displayImageKey="display_image"
          />
          <div className="ml-3">
            <div>{item.p_title}</div>
            <div className="d-flex mt-1">
              <MuteText>SKU</MuteText>
              <BoldText className="ml-2">{item.p_sku}</BoldText>
            </div>
            <div className="d-flex mt-1">
              <MuteText>UPC</MuteText>
              <span className="ml-2">{item.item_upc}</span>
            </div>
          </div>
        </div>
      </TableCell>
      <TableCell>
        {isMultiple ? (
          <ExpandCollapseToggle open={open} setOpen={setOpen} />
        ) : (
          <ProductPropertiesConverted
            attributes={{ ...latestAttribute, alias }}
            imperial={imperial}
          />
        )}
      </TableCell>
    </>
  );
}

export function StatusRenderer({ qty, received_qty }) {
  if (qty === received_qty) {
    return (
      <GreenPill>
        <Check />
        <span>Stored</span>
      </GreenPill>
    );
  }

  return (
    <GreyPill>
      <span>Pending Storage</span>
    </GreyPill>
  );
}
