import { useEffect, useState } from 'react';
import { Input } from 'semantic-ui-react';
import { AppTable, SkupremeModal } from '../../../components';
import { HoverBorderButton, MuteText, StyledButton } from '../../../elements';
import {
  ItemAllocationToteDetails,
  ToteListWarehouseHook,
} from '../../../hooks/tote';
import { CommonUtility, ToastMessage, WaveService } from '../../../utility';
import { PopupToteRow } from './ToteRow';

export function TotePopup({
  item,
  open,
  onClose,
  warehouse,
  afterToteCreation,
}) {
  const { data, loading: assignedToteLoading } = ItemAllocationToteDetails(
    item?.id,
  );
  const [toteName, setToteName] = useState('');
  const [loading, setLoading] = useState(false);
  const {
    loading: toteLoading,
    data: totes,
    totalData,
    totalPages,
    filterChanged,
    pageChanged,
    filter,
  } = ToteListWarehouseHook(warehouse?._id, 'open', !!open);

  useEffect(() => {
    setToteName('');
  }, [open]);

  const tote = CommonUtility.isValidObject(data?.tote) ? data?.tote : null;

  const createTote = async () => {
    try {
      setLoading(true);
      if (!toteName) {
        ToastMessage.error('Tote Number is required');
        return;
      }
      const payload = {
        warehouseId: warehouse?._id,
        toteNumber: toteName,
      };
      const res = await WaveService.createTote(payload);
      afterToteCreation(item, res._id);
      onClose();
    } catch (error) {
      ToastMessage.apiError(error);
    } finally {
      setLoading(false);
    }
  };

  const assignToExistingTote = tote => {
    onClose();
    afterToteCreation(item, tote._id || tote.id);
  };

  const columns = [
    {
      text: 'ID',
      key: 'id',
      width: 3,
      textAlign: 'left',
    },
    {
      text: 'Color Coding',
      key: 'color_coding',
      textAlign: 'left',
      width: 2,
    },
    {
      text: 'Type',
      key: 'type',
      textAlign: 'left',
      width: 2,
    },
    {
      text: 'Assigned Zone',
      textAlign: 'left',
      key: 'details',
      width: 2,
    },
    {
      text: 'Size',
      textAlign: 'left',
      key: 'size',
      width: 3,
    },
    {
      text: 'Weight Limit',
      textAlign: 'left',
      key: 'weight_limit',
      width: 2,
    },
    {
      text: '',
      textAlign: 'left',
      key: 'actions',
      width: 3,
    },
  ];

  const thereAreNoTotesAvailable =
    !loading && !CommonUtility.isValidArray(totes);

  return (
    <SkupremeModal
      title={
        <div className="d-flex">
          Assign Tote from
          <div className="d-flex ml-1">
            <MuteText>Warehouse</MuteText>
            <span className="ml-1">{warehouse?.name}</span>
          </div>
        </div>
      }
      size={thereAreNoTotesAvailable ? 'small' : 'large'}
      open={open}
      onClose={onClose}
    >
      <div className="my-4">
        {tote
          ? 'This item will be assinged to an exising Tote'
          : (toteLoading || CommonUtility.isValidArray(totes)) && (
              <div>
                <AppTable
                  loading={toteLoading}
                  data={totes}
                  RowComponent={
                    <PopupToteRow assignToExistingTote={assignToExistingTote} />
                  }
                  noShadow
                  columns={columns}
                  totalData={totalData}
                  totalPages={totalPages}
                  currentPage={filter.page}
                  pageSizeChanged={size => filterChanged('limit', size)}
                  pageSize={filter.limit}
                  pageChanged={pageChanged}
                />
              </div>
            )}

        {tote ? (
          <div>
            <MuteText>Tote Number:</MuteText>
            <span className="ml-2">{tote.toteNumber}</span>
          </div>
        ) : (
          thereAreNoTotesAvailable && (
            <div>
              <MuteText>Tote Number</MuteText>
              <Input
                fluid
                placeholder="TOTE-X0X1"
                value={toteName}
                onChange={e => setToteName(e.target.value)}
              />
            </div>
          )
        )}
      </div>

      {(tote || thereAreNoTotesAvailable) && (
        <div className="d-flex justify-content-end">
          <HoverBorderButton disabled={loading} type="button" onClick={onClose}>
            Cancel
          </HoverBorderButton>
          {tote ? (
            <StyledButton
              loading={loading || assignedToteLoading}
              type="button"
              onClick={() => assignToExistingTote(tote)}
              className="ml-2"
            >
              Confirm
            </StyledButton>
          ) : (
            <StyledButton
              loading={loading || assignedToteLoading}
              type="button"
              onClick={createTote}
              className="ml-2"
            >
              Create Tote and Assign
            </StyledButton>
          )}
        </div>
      )}
    </SkupremeModal>
  );
}
