import { TableCell, TableRow } from 'semantic-ui-react';
import {
  Circle,
  PencilSimple,
  RectangleDashed,
  TrashSimple,
} from '@phosphor-icons/react';
import styled from 'styled-components';
import { ColoredPill, MuteText } from '../../../elements';
import { CommonUtility, ToteStatusColors } from '../../../utility';
import { ColorSwatch } from '../../../elements/ColorPicker';
import { ActionEditDots, ActionMenuItem } from '../../../components';

const ActionEditTableRow = styled(TableRow)`
  position: relative;

  .action-container {
    margin: auto;
    background: transparent;
    right: 10px;
    bottom: 0;
  }

  &:hover {
    .action-container {
      opacity: 1 !important;
    }
  }
`;

export function ToteRow({ item, editTote, onDeleteTote }) {
  return (
    <ActionEditTableRow>
      <TableCell>{item.toteNumber}</TableCell>
      <TableCell>
        <ColorSwatch backgroundColor={item.color} />
      </TableCell>
      <TableCell>{CommonUtility.toTitleCase(item.type)}</TableCell>
      <TableCell>
        {item.zone?.code ? (
          <ColoredPill color={item.zone?.color}>
            <RectangleDashed /> {item.zone?.code}
          </ColoredPill>
        ) : (
          <MuteText>Not Set</MuteText>
        )}
      </TableCell>
      <TableCell>
        {item.dimensions?.length ? (
          <div>
            <span>{item.dimensions?.length}</span>
            <MuteText className="mx-1">x</MuteText>
            <span>{item.dimensions?.height}</span>
            <MuteText className="mx-1">x</MuteText>
            <span>{item.dimensions?.width}</span>
          </div>
        ) : (
          <MuteText>Not Set</MuteText>
        )}
      </TableCell>
      <TableCell>
        {item.dimensions?.weightLimit ? (
          item.dimensions.weightLimit
        ) : (
          <MuteText>Not Set</MuteText>
        )}
      </TableCell>
      <TableCell>
        <ColoredPill color={ToteStatusColors[item.status]}>
          <Circle weight="fill" />
          {CommonUtility.toTitleCase(item.status)}
        </ColoredPill>
        <ActionEditDots preventChangingBg on="hover" position="left center">
          <ActionMenuItem
            onClick={() => {
              editTote(item);
            }}
          >
            <PencilSimple />
            Edit
          </ActionMenuItem>
          <ActionMenuItem
            onClick={() => {
              onDeleteTote(item);
            }}
            className="danger"
          >
            <TrashSimple />
            Delete
          </ActionMenuItem>
        </ActionEditDots>
      </TableCell>
    </ActionEditTableRow>
  );
}
