import { List } from '@phosphor-icons/react';
import styled from 'styled-components';

const Nav = styled.div`
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
  color: white;
`;

export function HamburgerMenu({ onClick }) {
  return (
    <Nav onClick={onClick}>
      <List weight="bold" size={24} />
    </Nav>
  );
}
