import {
  Check,
  Circle,
  Cube,
  FileText,
  User,
  Warehouse,
  Waves,
} from '@phosphor-icons/react';
import styled from 'styled-components';
import {
  ActionEditDots,
  ActionMenuItem,
  BackButton,
  OrganisationAvatar,
} from '../../../components';
import { HeaderBar, OrangePill, PageTitle, WhitePill } from '../../../elements';
import { CommonUtility, theme } from '../../../utility';

const Info = styled(WhitePill)`
  height: 24px;
  padding: 0 10px;
`;

const Status = styled(OrangePill)`
  height: 24px;
  padding: 0 10px;
`;

export function WaveDetailsHeader({
  data,
  summary,
  markWaveReady,
  loading,
  isWaveInBuilding,
  assignToUser,
  processing,
  generateDocument,
  canGenerateDocument,
}) {
  const user = data?.assignedTo?.userId || null;
  return (
    <HeaderBar className="row">
      <div className="col-9 d-flex">
        <BackButton />
        <div className="d-flex align-items-center">
          <PageTitle className="d-flex align-items-center">
            <Waves size={25} />
            <span className="ml-2">{data?.waveNumber}</span>
          </PageTitle>
          <Status className="ml-4">
            <Circle weight="fill" color={theme.colors.orange} />
            {CommonUtility.toTitleCase(data?.status || '')}
          </Status>
          <Info className="ml-4">
            <Warehouse />
            {data?.warehouseId?.name}
          </Info>
          {summary?.totalAllocations && (
            <Info className="ml-4">
              <Cube />
              {summary?.totalAllocations || 0} Items,
              {summary?.allocationsByStatus?.pending || 0} Pending
            </Info>
          )}
        </div>
      </div>
      <div className="col-3 d-flex justify-content-end align-items-center">
        {user && (
          <div className="d-flex align-items-center">
            <OrganisationAvatar name={user.firstName} />
            &nbsp;{user.firstName}
          </div>
        )}
        <div className="ml-4">
          <ActionEditDots
            position="bottom right"
            preventChangingBg
            showByDefault
            inline
          >
            {isWaveInBuilding && (
              <ActionMenuItem loading={loading} onClick={markWaveReady}>
                <Check />
                Mark Wave Ready
              </ActionMenuItem>
            )}
            <ActionMenuItem loading={loading} onClick={assignToUser}>
              <User />
              Assign to User
            </ActionMenuItem>
            {canGenerateDocument && (
              <ActionMenuItem loading={processing} onClick={generateDocument}>
                <FileText />
                Generate Document
              </ActionMenuItem>
            )}
          </ActionEditDots>
        </div>
      </div>
    </HeaderBar>
  );
}
