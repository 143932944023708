import { CommonUtility } from '../common';
import { APIPath } from '../constants';
import { BaseService } from './base';
import { CRUDService } from './crud';

class Wave extends CRUDService {
  constructor() {
    super(APIPath.orderWaves);
  }

  waveList(params) {
    const url = `${APIPath.orderWavesList}?${CommonUtility.objectToParams(
      params,
    )}`;
    return BaseService.get(url);
  }

  sortedWaveList(params) {
    const url = `${APIPath.sortedOrderWavesList}?${CommonUtility.objectToParams(
      params,
    )}`;
    return BaseService.get(url);
  }

  toteList(warehouseId, params) {
    const url = `${
      APIPath.orderTotes
    }/${warehouseId}/?${CommonUtility.objectToParams(params)}`;
    return BaseService.get(url);
  }

  waveRuleList(params) {
    return BaseService.get(
      `${APIPath.WavesRulesList}/?${CommonUtility.objectToParams(params)}`,
    );
  }

  markWaveReady(id) {
    return BaseService.patch(`${APIPath.orderWaves}/${id}/ready`);
  }

  addRule(data) {
    return BaseService.post(APIPath.orderWaveRules, data);
  }

  updateRule(id, data) {
    return BaseService.put(`${APIPath.orderWaveRules}/${id}`, data);
  }

  rulesMetadata() {
    return BaseService.get(APIPath.orderWaveRulesMetadata);
  }

  pickWaveAllocation(waveId, allocationId, data) {
    return BaseService.post(
      `${APIPath.orderWaves}/${waveId}/allocations/${allocationId}/pick`,
      data,
    );
  }

  sortSingleItem(waveId, allocationId, data) {
    return BaseService.post(
      `${APIPath.orderWaves}/${waveId}/allocations/${allocationId}/sort`,
      data,
    );
  }

  getAllocationTote(allocaitonId) {
    return BaseService.get(`${APIPath.orderAllocationTote}/${allocaitonId}`);
  }

  createTote(data) {
    return BaseService.post(APIPath.orderTotes, data);
  }

  updateTote(data, toteId) {
    return BaseService.put(`${APIPath.orderTotes}/${toteId}`, data);
  }

  deleteTote(toteId) {
    return BaseService.remove(`${APIPath.orderTotes}/${toteId}`);
  }

  deleteWaveRule(ruleId) {
    return BaseService.remove(`${APIPath.orderWaveRules}/${ruleId}`);
  }

  assignUser(waveId, data) {
    return BaseService.post(`${APIPath.orderWaves}/${waveId}/assign`, data);
  }

  generateDocument(waveId, data) {
    return BaseService.post(
      `${APIPath.orderWaves}/${waveId}/generate-document`,
      data,
    );
  }
}

const WaveService = new Wave();
Object.freeze(WaveService);
export { WaveService };
