import {
  CaretDoubleLeft,
  CaretDoubleRight,
  CaretLeft,
  CaretRight,
} from '@phosphor-icons/react';
import {
  Pagination,
  Table,
  TableCell,
  TableFooter,
  TableRow,
} from 'semantic-ui-react';
import styled from 'styled-components';
import { useMemo } from 'react';
import { CenterDropdownStyle } from '../elements/Dropdown';
import { CommonConstant } from '../utility/constants';
import { CommonUtility } from '../utility';

const Container = styled.div`
  display: flex;
  width: 100%;
`;

const FooterTable = styled(Table)`
  margin-top: 0 !important;
  background: inherit !important;
  border: none !important;
`;

const NoPaddingCell = styled(TableCell)`
  ${({ nopadding }) => nopadding && 'padding: 11px 0px !important;'}
`;

const WhiteDropDown = styled(CenterDropdownStyle)`
  border: 1px solid #f6f6f6;
  filter: drop-shadow(0px 5px 25px rgba(57, 57, 72, 0.05)) !important;
  border-radius: 6px !important;
  height: 40px;

  text-transform: capitalize;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #393948 !important;

  &:not(.active.visible.selection) {
    border-color: #fff !important;
  }

  &.selection.active.dropdown .menu {
    background: white !important;
  }

  > svg {
    top: unset;
  }
`;

const StyledPagination = styled(Pagination)`
  border: 0px solid white !important;
  filter: drop-shadow(0px 5px 25px rgba(57, 57, 72, 0.05)) !important;
  border-radius: 6px !important;
  box-shadow: unset !important;
  width: fit-content;
  display: flex !important;

  .item {
    border: none !important;
    &:hover {
      background: #e9e9e9 !important;
    }
    &:before {
      background: #f6f6f6 !important;
    }
    &.active {
      background: #393948 !important;
      color: white !important;
    }
  }
`;

export function TablePagination({
  colSpan,
  currentPage,
  totalPages,
  pageChanged,
  pageSize,
  pageSizeChanged,
  noPadding,
  totalData,
  footerComponent,
  customPageSizes = null,
}) {
  const isMobileDevice = useMemo(() => CommonUtility.isMobileDevice(), []);

  if (isMobileDevice) {
    return (
      <Container className="mt-2" hasComponent={!!footerComponent}>
        {footerComponent}
        {(currentPage || 0) > 0 && (totalData || 0) > 10 && (
          <Container className="flex-column">
            {pageSize && (
              <WhiteDropDown
                placeholder="Page Size"
                selection
                selectOnBlur={false}
                options={customPageSizes || CommonConstant.pageSizes}
                value={pageSize}
                onChange={(_, item) => pageSizeChanged(item.value)}
                className="mb-2"
              />
            )}
            {(totalPages || 0) > 1 && (
              <StyledPagination
                activePage={currentPage}
                onPageChange={(_, data) => pageChanged(data.activePage)}
                totalPages={totalPages}
                boundaryRange={0}
                siblingRange={0}
                nextItem={
                  currentPage !== totalPages && {
                    'aria-label': 'Next item',
                    content: <CaretRight />,
                  }
                }
                prevItem={
                  currentPage !== 1 && {
                    'aria-label': 'Previous item',
                    content: <CaretLeft />,
                  }
                }
                lastItem={
                  currentPage !== totalPages && {
                    'aria-label': 'Last item',
                    content: <CaretDoubleRight />,
                  }
                }
                firstItem={
                  currentPage !== 1 && {
                    'aria-label': 'First item',
                    content: <CaretDoubleLeft />,
                  }
                }
              />
            )}
          </Container>
        )}
      </Container>
    );
  }

  return (
    <FooterTable>
      <TableFooter>
        <TableRow>
          <NoPaddingCell nopadding={noPadding ? 1 : 0} colSpan={colSpan}>
            <Container hasComponent={!!footerComponent}>
              {footerComponent}
              {(currentPage || 0) > 0 && (totalData || 0) > 10 && (
                <Container className="align-items-center justify-content-between">
                  {pageSize && (
                    <WhiteDropDown
                      placeholder="Page Size"
                      selection
                      selectOnBlur={false}
                      options={customPageSizes || CommonConstant.pageSizes}
                      value={pageSize}
                      onChange={(_, item) => pageSizeChanged(item.value)}
                      className="mr-3"
                    />
                  )}
                  {(totalPages || 0) > 1 && (
                    <StyledPagination
                      activePage={currentPage}
                      onPageChange={(_, data) => pageChanged(data.activePage)}
                      totalPages={totalPages}
                      nextItem={
                        currentPage !== totalPages && {
                          'aria-label': 'Next item',
                          content: <CaretRight />,
                        }
                      }
                      prevItem={
                        currentPage !== 1 && {
                          'aria-label': 'Previous item',
                          content: <CaretLeft />,
                        }
                      }
                      lastItem={
                        currentPage !== totalPages && {
                          'aria-label': 'Last item',
                          content: <CaretDoubleRight />,
                        }
                      }
                      firstItem={
                        currentPage !== 1 && {
                          'aria-label': 'First item',
                          content: <CaretDoubleLeft />,
                        }
                      }
                    />
                  )}
                </Container>
              )}
            </Container>
          </NoPaddingCell>
        </TableRow>
      </TableFooter>
    </FooterTable>
  );
}
