import { useEffect, useMemo, useState } from 'react';
import { AppTable } from '../../../components';
import { CommonUtility } from '../../../utility';
import { storageColumns } from './Columns';
import { getRecountDefaultValues } from './commonFunctions';
import { AlertFooterBar } from '../../../elements/FooterAlert';
import { ShipmentItemRow } from './ItemRow';

export function EditShipmentItems({
  data,
  imperial,
  search,
  loading,
  openRecievingPopup,
  isRecount,
  isStorageView,
  setUserValue,
  onConfirm,
  processing,
  ...props
}) {
  const [items, setItems] = useState([]);
  const [changed, setChanged] = useState(false);

  useEffect(() => {
    let allItems = JSON.parse(JSON.stringify(data.items || []));
    if (search) {
      allItems = allItems.filter(
        x =>
          CommonUtility.searchQueryCompare(search, x.p_title) ||
          CommonUtility.searchQueryCompare(search, x.p_sku) ||
          CommonUtility.searchQueryCompare(search, x.item_upc),
      );
    }
    setItems(allItems);
  }, [data?.items, search]);

  const pureRecountState = useMemo(() => {
    if (!CommonUtility.isValidArray(data?.items)) return false;

    return data.items.every(item =>
      item.aliases.every(alias => alias?.metadata?.checked_quantity),
    );
  }, [data]);

  useEffect(() => {
    setChanged(false);
  }, [data]);

  useEffect(() => {
    if (!CommonUtility.isValidArray(data?.items) || pureRecountState) return;

    const defaultValue = getRecountDefaultValues(data?.items);
    setUserValue(defaultValue);
  }, [pureRecountState, data]);

  const onLocationChange = (itemId, aliasId, locationId) => {
    setUserValue(userValue => ({
      ...userValue,
      [itemId]: {
        ...userValue[itemId],
        locations: {
          ...(userValue[itemId]?.locations || {}),
          [aliasId]: locationId,
        },
      },
    }));
  };

  const onUnitsRecievedChange = (itemId, aliasId, receivedQty) => {
    setUserValue(userValue => ({
      ...userValue,
      [itemId]: {
        ...userValue[itemId],
        received: {
          ...(userValue[itemId]?.received || {}),
          [aliasId]: receivedQty,
        },
      },
    }));
    setChanged(true);
  };

  const onCancel = () => {
    if (!pureRecountState) {
      const defaultValue = getRecountDefaultValues(data?.items);
      setUserValue(defaultValue);
    } else {
      setUserValue({});
    }

    setChanged(false);
  };

  return (
    <>
      <AppTable
        columns={storageColumns}
        RowComponent={
          <ShipmentItemRow
            openRecievingPopup={openRecievingPopup}
            imperial={imperial}
            onLocationChange={onLocationChange}
            onUnitsRecievedChange={onUnitsRecievedChange}
            {...props}
          />
        }
        data={items}
        loading={loading}
      />
      {changed && (
        <AlertFooterBar
          save={onConfirm}
          cancel={onCancel}
          message="Changes made to Item(s)"
          loading={processing}
        />
      )}
    </>
  );
}
