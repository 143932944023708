import { Loader, TableCell, TableRow } from 'semantic-ui-react';
import {
  BoxArrowUp,
  Check,
  GridNine,
  RectangleDashed,
  StackSimple,
  TrashSimple,
  Tray,
} from '@phosphor-icons/react';
import styled from 'styled-components';
import { useEffect, useMemo, useState } from 'react';
import { CommonUtility, DateFormat, DateUtility, theme } from '../../utility';
import { ColoredPill, HoverBorderButton, MuteText } from '../../elements';
import {
  ActionEditDots,
  ActionMenuItem,
  BorderTableCell,
  BorderTableRow,
  ItemImage,
} from '../../components';
import { ExpandCollapseToggle } from '../shipments/Items/commonComponents';

const ActionEditTableRow = styled(BorderTableRow)`
  position: relative;

  .action-container {
    margin: auto;
    background: transparent;
    right: 10px;
    bottom: 0;
  }

  &:hover {
    .action-container {
      opacity: 1 !important;
    }
  }
`;

export function WaveItemRow({
  item,
  markItemPicked,
  itemMarkedTracker,
  isWaveInBuilding,
  isWaveInSorting,
  markItemAsAllocated,
  removeAllocation,
  cancelAllocation,
  customProps,
}) {
  if (CommonUtility.isValidArray(item.sameItems)) {
    return (
      <MultipleItemRow
        item={item}
        markItemPicked={markItemPicked}
        itemMarkedTracker={itemMarkedTracker}
        isWaveInBuilding={isWaveInBuilding}
        isWaveInSorting={isWaveInSorting}
        markItemAsAllocated={markItemAsAllocated}
        removeAllocation={removeAllocation}
        cancelAllocation={cancelAllocation}
        customProps={customProps}
      />
    );
  }

  return (
    <SingleItemRow
      item={item}
      markItemPicked={markItemPicked}
      itemMarkedTracker={itemMarkedTracker}
      isWaveInBuilding={isWaveInBuilding}
      isWaveInSorting={isWaveInSorting}
      markItemAsAllocated={markItemAsAllocated}
      removeAllocation={removeAllocation}
      cancelAllocation={cancelAllocation}
    />
  );
}

function SingleItemRow({
  item,
  markItemPicked,
  itemMarkedTracker,
  isWaveInBuilding,
  isWaveInSorting,
  markItemAsAllocated,
  removeAllocation,
  cancelAllocation,
  isChild = false,
}) {
  function Actions() {
    if (itemMarkedTracker[item.id] === 'loading') {
      return <Loader active inline size="tiny" />;
    }

    if (itemMarkedTracker[item.id] === 'pending' && !isWaveInBuilding) {
      return (
        <div className="d-flex justify-content-center">
          <HoverBorderButton
            onClick={() => markItemPicked(item)}
            className="flex-btn"
          >
            <Check className="mr-1" size={16} />
            Mark Picked
          </HoverBorderButton>
        </div>
      );
    }

    if (itemMarkedTracker[item.id] === 'picked') {
      if (isWaveInSorting) {
        return (
          <div className="d-flex justify-content-center">
            <HoverBorderButton
              onClick={() => markItemAsAllocated(item)}
              className="flex-btn"
            >
              <StackSimple className="mr-1" size={16} />
              Sort
            </HoverBorderButton>
          </div>
        );
      }

      return (
        <div className="d-flex justify-content-center">
          <Check size={17} color={theme.colors.green} />
          <span className="ml-2">Picked</span>
        </div>
      );
    }

    return (
      <div className="d-flex align-items-center justify-content-center">
        {isWaveInBuilding && (
          <Loader active indeterminate inline size="tiny" className="mr-2" />
        )}
        {CommonUtility.removeUnderscoreAndCapitalize(
          itemMarkedTracker[item.id],
        )}
      </div>
    );
  }

  return (
    <ActionEditTableRow>
      <BorderTableCell expand={isChild ? 'true' : 'false'}>
        <div className="border" />
        <div className={`d-flex align-items-start ${isChild ? 'ml-4' : ''}`}>
          <ItemImage
            item={item.inventory.product}
            imageKey="images"
            displayImageKey="display_image"
          />
          <div className="d-flex flex-column ml-4">
            <span>{item.inventory?.product?.name}</span>
            <div className="d-flex mt-1">
              <MuteText className="mr-2">SKU</MuteText>
              <span>{item.inventory?.product?.sku}</span>
            </div>
          </div>
        </div>
      </BorderTableCell>
      <TableCell>
        {DateUtility.formatDate(item.allocatedAt, DateFormat.dateTime)}
      </TableCell>
      {isChild ? (
        <>
          <TableCell />
          <TableCell />
          <TableCell />
        </>
      ) : (
        <>
          <TableCell>
            <ColoredPill color={item?.inventory?.zone?.color}>
              <RectangleDashed size={17} />
              <span>{item?.inventory?.zone?.code}</span>
            </ColoredPill>
          </TableCell>
          <TableCell>
            <ColoredPill color={item?.inventory?.zone?.color}>
              <GridNine size={17} />
              <span>{item?.inventory?.rack?.code}</span>
            </ColoredPill>
          </TableCell>
          <TableCell>
            <ColoredPill color={item?.inventory?.zone?.color}>
              <Tray size={17} />
              <span>{item?.inventory?.location?.code}</span>
            </ColoredPill>
          </TableCell>
        </>
      )}
      <TableCell textAlign="center">{item?.quantity}</TableCell>
      <TableCell textAlign="center">
        <Actions />
        {!['completed', 'sorted'].includes(itemMarkedTracker[item.id]) && (
          <ActionEditDots preventChangingBg on="hover" position="left center">
            <ActionMenuItem
              onClick={() => {
                removeAllocation(item);
              }}
            >
              <BoxArrowUp />
              Remove Allocaiton
            </ActionMenuItem>
            <ActionMenuItem
              onClick={() => {
                cancelAllocation(item);
              }}
              className="danger"
            >
              <TrashSimple />
              Cancel Allocaiton
            </ActionMenuItem>
          </ActionEditDots>
        )}
      </TableCell>
    </ActionEditTableRow>
  );
}

function MultipleItemRow({
  item,
  markItemPicked,
  itemMarkedTracker,
  isWaveInBuilding,
  isWaveInSorting,
  markItemAsAllocated,
  removeAllocation,
  cancelAllocation,
  customProps,
}) {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(customProps.allExpandCollapse.expand);
  }, [customProps.allExpandCollapse.expand]);

  const totalQty = useMemo(() => {
    let total = 0;
    item.sameItems.forEach(item => {
      total += item.quantity || 0;
    });
    return total;
  }, [item]);

  return (
    <>
      <TableRow>
        <TableCell>
          <div className="d-flex align-items-start">
            <ItemImage
              item={item.inventory.product}
              imageKey="images"
              displayImageKey="display_image"
            />
            <div className="d-flex flex-column ml-4">
              <span>{item.inventory?.product?.name}</span>
              <div className="d-flex mt-1">
                <MuteText className="mr-2">SKU</MuteText>
                <span>{item.inventory?.product?.sku}</span>
              </div>
            </div>
          </div>
        </TableCell>
        <TableCell>
          <MuteText>Multiple</MuteText>
        </TableCell>
        <TableCell>
          <ColoredPill color={item?.inventory?.zone?.color}>
            <RectangleDashed size={17} />
            <span>{item?.inventory?.zone?.code}</span>
          </ColoredPill>
        </TableCell>
        <TableCell>
          <ColoredPill color={item?.inventory?.zone?.color}>
            <GridNine size={17} />
            <span>{item?.inventory?.rack?.code}</span>
          </ColoredPill>
        </TableCell>
        <TableCell>
          <ColoredPill color={item?.inventory?.zone?.color}>
            <Tray size={17} />
            <span>{item?.inventory?.location?.code}</span>
          </ColoredPill>
        </TableCell>
        <TableCell textAlign="center">
          <MuteText className="mr-2">Total</MuteText>
          {totalQty}
        </TableCell>
        <TableCell>
          <ExpandCollapseToggle
            title="Multiple Items"
            open={open}
            setOpen={setOpen}
          />
        </TableCell>
      </TableRow>
      {open &&
        item.sameItems.map(sameItem => (
          <SingleItemRow
            item={sameItem}
            isChild
            markItemPicked={markItemPicked}
            itemMarkedTracker={itemMarkedTracker}
            isWaveInBuilding={isWaveInBuilding}
            isWaveInSorting={isWaveInSorting}
            markItemAsAllocated={markItemAsAllocated}
            removeAllocation={removeAllocation}
            cancelAllocation={cancelAllocation}
          />
        ))}
    </>
  );
}
