import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { WaveDetailHook } from '../../../../hooks/waves';
import { AppTable, TableExpandCollapse } from '../../../../components/AppTable';
import {
  AssignWaveToUserPopup,
  TotePopup,
  WaveDetailsHeader,
  WaveItemRow,
} from '../../../../page-components/waves';
import {
  AllocationService,
  CommonUtility,
  ToastMessage,
  WaveService,
} from '../../../../utility';
import { ConfirmationPopup } from '../../../../components';

const cancellationTypes = {
  cancel: 'cancel',
  remove: 'remove',
};

export function DesktopWaveDetailScreen() {
  const { id } = useParams();

  const { data, loading, refresh, setLoading, allocations } =
    WaveDetailHook(id);
  const [userPopup, setUserPopup] = useState(false);
  const [totePopupItem, setTotePopupItem] = useState(null);

  const [itemMarkedTracker, setItemMarkedTracker] = useState({});
  const [confirmationPopup, setConfirmationPopup] = useState({
    open: false,
    item: null,
    type: null,
  });

  const [processing, setProcessing] = useState(false);

  const [customProps, setCustomProps] = useState({
    allExpandCollapse: {
      expand: false,
    },
  });

  const columns = [
    {
      text: 'Product Information',
      key: 'item',
      width: 4,
      textAlign: 'left',
    },
    {
      text: 'Allocation Time',
      key: 'allocation_time',
      width: 2,
      textAlign: 'left',
    },
    {
      text: 'Zone',
      key: 'zone',
      width: 1,
      textAlign: 'left',
    },
    {
      text: 'Rack',
      key: 'rack',
      width: 1,
      textAlign: 'left',
    },
    {
      text: 'Location',
      key: 'location',
      width: 1,
      textAlign: 'left',
    },
    {
      text: 'Quantity',
      key: 'quantity',
      width: 1,
    },
    {
      text: '',
      component: (
        <TableExpandCollapse
          expand={customProps.allExpandCollapse.expand}
          clickHandler={(e, toggle) => {
            setCustomProps({
              ...customProps,
              allExpandCollapse: { expand: !toggle },
            });
            e.stopPropagation();
          }}
        />
      ),
      key: 'action',
      width: 2,
      textAlign: 'left',
    },
  ];

  useEffect(() => {
    if (CommonUtility.isValidObject(data?.wave)) {
      const currentStatus = {};
      data?.wave?.allocations?.forEach(allocation => {
        currentStatus[allocation?.id] = allocation.status;
      });

      setItemMarkedTracker(currentStatus);
    }
  }, [data]);

  const setItem = (itemId, value) => {
    setItemMarkedTracker(itemMarkedTracker => ({
      ...itemMarkedTracker,
      [itemId]: value,
    }));
  };

  const checkIfAllItemsHaveBeenPicked = statusTracker => {
    const statusArray = Object.values(statusTracker || {});
    const everyItemIsPicked = statusArray.every(x => x === statusArray[0]);
    if (everyItemIsPicked) {
      refresh();
    }
  };

  const markItemPicked = async item => {
    const previousStatus = item.status;
    try {
      setItem(item.id, 'loading');
      const payload = {
        pickedQuantity: {
          value: item.quantity,
          uom: 'units',
        },
      };
      const res = await WaveService.pickWaveAllocation(id, item.id, payload);
      setItem(item.id, res?.data?.status);
      checkIfAllItemsHaveBeenPicked({
        ...itemMarkedTracker,
        [item.id]: res?.data?.status,
      });
      ToastMessage.success('Item marked as picked successfully');
    } catch (error) {
      setItem(item.id, previousStatus);
      ToastMessage.apiError(error);
    }
  };

  const markWaveReady = async () => {
    try {
      setLoading(true);
      await WaveService.markWaveReady(id);
      ToastMessage.success('Wave marked as ready successfully');
      refresh();
    } catch (error) {
      setLoading(false);
      ToastMessage.apiError(error);
    }
  };

  const sendItemForAllocation = async (item, toteId) => {
    const previousStatus = item.status;
    try {
      setItem(item.id, 'loading');

      const payload = {};
      if (item.sorting?.requiresTote) {
        payload.toteId = toteId;
      }

      const res = await WaveService.sortSingleItem(id, item.id, payload);
      setItem(item.id, res?.data?.status);
      checkIfAllItemsHaveBeenPicked({
        ...itemMarkedTracker,
        [item.id]: res?.data?.status,
      });
      ToastMessage.success('Item marked as picked successfully');
    } catch (error) {
      setItem(item.id, previousStatus);
      ToastMessage.apiError(error);
    }
  };

  const markItemAsAllocated = item => {
    if (item.sorting?.requiresTote) {
      setTotePopupItem(item);
      return;
    }

    sendItemForAllocation(item);
  };

  const closeTotePopup = () => {
    setTotePopupItem(null);
  };

  const removeAllocation = item => {
    setConfirmationPopup({
      open: true,
      type: cancellationTypes.remove,
      item,
    });
  };

  const cancelAllocation = item => {
    setConfirmationPopup({
      open: true,
      type: cancellationTypes.cancel,
      item,
    });
  };

  const onCloseConfirmation = () => {
    setConfirmationPopup({
      open: false,
    });
  };

  const onConfirm = async () => {
    try {
      setProcessing(true);
      if (confirmationPopup.type === cancellationTypes.cancel) {
        await AllocationService.cancelAllocation(confirmationPopup.item.id);
        ToastMessage.success('Allocaiton cancelled successfully');
      } else {
        await AllocationService.removeAllocation(id, confirmationPopup.item.id);
        ToastMessage.success('Allocation removed successfully');
      }
      onCloseConfirmation();
      refresh();
    } catch (error) {
      ToastMessage.apiError(error);
    } finally {
      setProcessing(false);
    }
  };

  const openAssignUserPopup = () => {
    setUserPopup(true);
  };

  const closeAssignUserPopup = () => {
    setUserPopup(false);
  };

  const generateDocument = async () => {
    try {
      setProcessing(true);
      await WaveService.generateDocument(id);
      ToastMessage.success('The labels have been sent to your email.');
    } catch (error) {
      ToastMessage.apiError(error);
    } finally {
      setProcessing(false);
    }
  };

  return (
    <div>
      <WaveDetailsHeader
        itemMarkedTracker={itemMarkedTracker}
        data={data?.wave}
        summary={data?.summary}
        markWaveReady={markWaveReady}
        loading={loading}
        isWaveInBuilding={data?.wave?.status === 'building'}
        assignToUser={openAssignUserPopup}
        generateDocument={generateDocument}
        processing={processing}
        canGenerateDocument={['sorted', 'completed'].includes(
          data?.wave?.status,
        )}
      />
      <h3 className="mt-1">Items in Wave</h3>
      <AppTable
        columns={columns}
        loading={loading}
        data={allocations}
        customProps={customProps}
        RowComponent={
          <WaveItemRow
            markItemPicked={markItemPicked}
            itemMarkedTracker={itemMarkedTracker}
            isWaveInBuilding={data?.wave?.status === 'building'}
            isWaveInSorting={data?.wave?.status === 'sorting'}
            markItemAsAllocated={markItemAsAllocated}
            removeAllocation={removeAllocation}
            cancelAllocation={cancelAllocation}
            customProps={customProps}
          />
        }
      />
      <TotePopup
        item={totePopupItem}
        open={!!totePopupItem}
        onClose={closeTotePopup}
        warehouse={data?.wave?.warehouseId}
        afterToteCreation={sendItemForAllocation}
      />
      <AssignWaveToUserPopup
        waveId={id}
        open={userPopup}
        onClose={closeAssignUserPopup}
        refresh={refresh}
      />
      <ConfirmationPopup
        open={confirmationPopup.open}
        title={`${
          confirmationPopup.type === cancellationTypes.cancel
            ? 'Cancel'
            : 'Remove'
        } ${confirmationPopup?.item?.inventory?.product.sku} Allocation`}
        message={
          confirmationPopup.type === cancellationTypes.cancel
            ? 'This will remove allocaion and item from the wave.'
            : 'This will remove the allocation but keep the item'
        }
        onClose={onCloseConfirmation}
        onConfirm={onConfirm}
        loading={processing}
        closeText="Close"
        confirmText="Remove"
      />
    </div>
  );
}
