import styled from 'styled-components';
import { CommonConstant } from '../utility/constants';
import { SemanticImageWithFallback } from './FallbackImage';
import { MuteText } from '../elements';

export const CustomImage = styled(SemanticImageWithFallback)`
  position: static !important;
  aspect-ratio: 1/1;
  object-fit: contain;
  border-radius: 5px;
`;

const getImage = (product, imageKey, displayImageKey = 'displayImage') => {
  if (
    product &&
    product.hasOwnProperty(imageKey) &&
    product[imageKey][displayImageKey]
  ) {
    return product[imageKey][displayImageKey];
  }
  return CommonConstant.defaultImage;
};

export function ItemImage({
  disabled,
  item,
  size,
  imageKey = 'pImages',
  extraClasses,
  isMobile,
  noMargin = false,
  displayImageKey,
}) {
  const src = getImage(item, imageKey, displayImageKey);

  return (
    <div className={noMargin ? '' : 'd-flex align-items-center'}>
      <CustomImage
        disabled={disabled}
        className={[
          extraClasses,
          isMobile ? 'mobile-img' : '',
          src === CommonConstant.defaultImage ? 'no-image' : '',
        ]
          .filter(d => !!d)
          .join(' ')}
        src={src}
        size={size || 'tiny'}
      />
    </div>
  );
}

const ProductContainer = styled.div`
  display: flex;
  margin-bottom: 1em;
  text-align: left;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  min-height: 80px;
`;

const ItemCount = styled.div`
  position: absolute;
  bottom: 0.5rem;
  width: 90%;
`;

export function ProductRow({
  products,
  containerClass = '',
  showDetail = true,
  imageKey = 'p_images',
  displayImageKey = 'display_image',
}) {
  return (
    <>
      <ProductContainer className={containerClass}>
        {products.slice(0, 3).map(item => (
          <div className="mx-2" key={item?._id || item?.price || ''}>
            <ItemImage
              displayImageKey={displayImageKey}
              imageKey={imageKey}
              item={item}
            />
          </div>
        ))}
      </ProductContainer>
      {showDetail && products?.length > 3 && (
        <ItemCount className="d-flex">
          <MuteText>{products?.length} total items</MuteText>
        </ItemCount>
      )}
    </>
  );
}
