import { TableCell, TableRow } from 'semantic-ui-react';
import {
  BoxArrowDown,
  Circle,
  Flag,
  MapPin,
  Note,
  RectangleDashed,
} from '@phosphor-icons/react';
import styled from 'styled-components';
import {
  ActionEditDots,
  ActionMenuItem,
  DarkPopup,
  ItemImage,
  OrganisationAvatar,
} from '../../components';
import { BoldText, ColoredPill, MuteText } from '../../elements';
import { CommonUtility, DateFormat, DateUtility, theme } from '../../utility';

const statusColors = {
  pending: theme.colors.darkBlue,
};

const priorityColors = {
  medium: theme.colors.yellow,
};

const NoteContainer = styled.div`
  .note-icon {
    min-width: 21px;
  }
`;

const ActionRow = styled(TableRow)`
  position: relative;

  .action-container {
    margin: auto;
    background: transparent;
    right: 10px;
    bottom: 0;
  }

  &:hover {
    .action-container {
      opacity: 1 !important;
    }
  }
`;

const ReasonClip = styled.span`
  white-space: nowrap;
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ItemTitle = styled.div`
  .title {
    max-width: 12vw;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export function InventoryTransferRow({ item, completeTransfer }) {
  return (
    <ActionRow>
      <TableCell>
        <div className="d-flex">
          <ItemImage
            imageKey="images"
            displayImageKey="display_image"
            item={item.product}
          />
          <ItemTitle className="ml-4">
            <div className="title">{item.product.name}</div>
            <div className="d-flex">
              <MuteText className="mr-2">SKU</MuteText>
              <BoldText>{item.product.sku}</BoldText>
            </div>
          </ItemTitle>
        </div>
      </TableCell>
      <TableCell>
        <ColoredPill color={statusColors[item.status]}>
          <Circle weight="fill" />
          {CommonUtility.toTitleCase(item.status)}
        </ColoredPill>
        <ColoredPill className="mt-1" color={priorityColors[item.priority]}>
          <Flag />
          {CommonUtility.toTitleCase(item.priority)}
        </ColoredPill>
      </TableCell>
      <TableCell>
        <div>
          <ColoredPill color={item.sourceLocation.zone.color}>
            <RectangleDashed /> {item.sourceLocation.zone.code}
          </ColoredPill>
          <ColoredPill className="mt-1" color={item.sourceLocation.zone.color}>
            <MapPin />
            {item?.sourceLocation.code}
          </ColoredPill>
        </div>
      </TableCell>
      <TableCell>
        <div>
          <ColoredPill color={item.destinationLocation.zone.color}>
            <RectangleDashed /> {item.destinationLocation.zone.code}
          </ColoredPill>
          <ColoredPill
            className="mt-1"
            color={item.destinationLocation.zone.color}
          >
            <MapPin />
            {item?.destinationLocation.code}
          </ColoredPill>
        </div>
      </TableCell>
      <TableCell>
        {item.quantity} <MuteText>Units</MuteText>
      </TableCell>
      <TableCell>
        <div className="d-flex align-items-center">
          <OrganisationAvatar name={item?.initiatedBy?.firstName} />
          &nbsp;{item?.initiatedBy?.firstName}
        </div>
      </TableCell>
      <TableCell>
        {DateUtility.formatDate(item.createdAt, DateFormat.barChartMonth)}
      </TableCell>
      <TableCell>
        <NoteContainer className="d-flex align-items-center">
          <Note className="note-icon" size={21} color={theme.colors.grey} />
          <div className="d-flex flex-column ml-2">
            <DarkPopup trigger={<ReasonClip>{item.reason}</ReasonClip>}>
              {item.reason}
            </DarkPopup>
            <MuteText>{item?.initiatedBy?.firstName}</MuteText>
          </div>
        </NoteContainer>
      </TableCell>
      <TableCell>
        <ActionEditDots preventChangingBg on="hover" position="left center">
          <ActionMenuItem onClick={() => completeTransfer(item)}>
            <BoxArrowDown />
            Complete Transfer
          </ActionMenuItem>
        </ActionEditDots>
      </TableCell>
    </ActionRow>
  );
}
