import { useEffect } from 'react';

const elementId = 'hubspot-messages-iframe-container';

export const useChatPosition = ({ refreshId = '0', height }) => {
  const updateChatPosition = (bottom = 0) => {
    if (typeof document !== 'undefined') {
      const element = document.getElementById(elementId);
      if (element) {
        element.style.setProperty(
          'bottom',
          `${parseInt(bottom ?? '0', 10)}px`,
          'important',
        );
      }
    }
  };

  useEffect(() => {
    setTimeout(() => updateChatPosition(height), 0);
    const timer1 = setTimeout(() => updateChatPosition(height), 100);
    const timer2 = setTimeout(() => updateChatPosition(height), 1000);
    return () => {
      clearTimeout(timer1);
      clearTimeout(timer2);
      updateChatPosition();
    };
  }, [height, refreshId]);

  return { updateChatPosition };
};
