import { useState } from 'react';
import { Search } from 'semantic-ui-react';
import styled from 'styled-components';
import { MagnifyingGlass, X } from '@phosphor-icons/react';
import { wavesAutocompleteHook } from '../../hooks/waves';
import { CommonUtility } from '../../utility';
import { WaveStatusRenderer } from './common';
import { MuteText } from '../../elements';
import { useDebounce } from '../../hooks';

const OptionContainer = styled.div`
  padding: 8px 12px;
`;

const isValidWaveSearch = search => !!(search && typeof search === 'string');

const CustomSearchBox = styled(Search)`
  &.top {
    .results {
      top: auto !important;
      bottom: 35px !important;
      max-height: 500px;
      overflow-y: auto;
    }
  }

  &.bottom {
    .results {
      overflow-y: auto;
    }
  }

  input {
    border-radius: 5px !important;
    border: 1px solid #acadb6 !important;
  }

  .result {
    padding: 0 !important;
  }

  @media screen and (max-width: 1366px) {
    .ui.input {
      width: 200px !important;
    }
  }

  &&& {
    .results {
      min-width: 100%;
      width: 450px;
      max-height: 60vh;
    }

    @media screen and (max-width: 1270px) {
      .results {
        width: 350px;
      }
    }

    @media screen and (max-width: 1170px) {
      .results {
        width: 250px;
      }
    }
  }

  &.w-100 {
    .ui.input {
      width: 100% !important;
    }
  }
`;

const SearchIcon = styled(MagnifyingGlass)`
  pointer-events: none;
  cursor: default;
  position: absolute;
  line-height: 1;
  text-align: center;
  top: 8px;
  right: 7px;
  margin: 0;
  opacity: 0.5;
  border-radius: 0 0.28571429rem 0.28571429rem 0;
  transition: opacity 0.3s ease;
`;

function resultRenderer({ search, _id, status, waveNumber, metadata }) {
  const showResult = string => {
    try {
      const highlighted = string.replace(
        new RegExp(search, 'gi'),
        d => `<strong>${d}</strong>`,
      );

      // eslint-disable-next-line react/no-danger
      return <span dangerouslySetInnerHTML={{ __html: highlighted }} />;
    } catch (error) {
      return string;
    }
  };

  return (
    <OptionContainer className="d-flex flex-column" key={_id}>
      <div className="d-flex justify-content-between align-items-center">
        <span>{showResult(waveNumber)}</span>
        <WaveStatusRenderer status={status} />
      </div>
      <div className="d-flex align-items-center">
        <div>
          <MuteText>Items:</MuteText>
          <span className="ml-1">{metadata.itemCount}</span>
        </div>
        <div className="ml-3">
          <MuteText>Starting Rack:</MuteText>
          <span className="ml-1">{metadata.startingRack?.code}</span>
        </div>
      </div>
    </OptionContainer>
  );
}

const CrossIcon = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

function CloseIcon({ onClick, loading }) {
  if (loading) {
    return <i className="icon" />;
  }

  return (
    <CrossIcon onClick={onClick} className="cursor-pointer">
      <X size={17} />
    </CrossIcon>
  );
}

export function WaveSearchInput({
  top,
  onResultClick,
  inputClassName,
  setTableSearch = () => {},
  warehouseId,
}) {
  const [focus, setFocus] = useState(false);
  const [search, setSearch] = useState('');

  const finalSearch = useDebounce(search, 700);
  const { data, loading } = wavesAutocompleteHook(finalSearch, warehouseId);

  const selection = data => {
    if (data?.result?._id) {
      onResultClick(data.result);
      setSearch('');
    }
    setFocus(false);
    document?.activeElement?.blur?.();
  };

  const resetSearch = () => {
    setSearch('');
    setTableSearch('');
  };

  return (
    <CustomSearchBox
      aligned="right"
      className={`${inputClassName} ${top ? 'top' : 'bottom'}`}
      input={{ fluid: false, placeholder: 'Wave Number' }}
      loading={loading || finalSearch !== search}
      onBlur={() => setFocus(false)}
      onFocus={() => setFocus(true)}
      onResultSelect={(_, data) => selection(data)}
      onSearchChange={(_, data) => {
        setSearch(data.value);
        setTableSearch(data.value);
      }}
      minCharacters={2}
      open={focus && isValidWaveSearch(search)}
      resultRenderer={resultRenderer}
      results={finalSearch === search ? data : []}
      showNoResults={!loading && data.length === 0 && finalSearch === search}
      value={search}
      icon={
        CommonUtility.isNotEmpty(search) ? (
          <CloseIcon
            onClick={resetSearch}
            loading={loading || finalSearch !== search}
          />
        ) : (
          <SearchIcon size={24} />
        )
      }
    />
  );
}
