import { useParams } from 'react-router-dom';
import { useMemo, useState } from 'react';
import {
  BodyContainer,
  HeaderBar,
  MuteText,
  PageTitle,
} from '../../../../elements';
import { InventoryLogsListHook } from '../../../../hooks/inventory';
import { AppTable, ItemImage } from '../../../../components';
import {
  InventoryAdjustmentPopup,
  ItemLogRow,
  ItemLogSummary,
} from '../../../../page-components/inventory';
import { CommonUtility } from '../../../../utility';

function Header({ item }) {
  return (
    <HeaderBar className="row">
      <div className="col-8 d-flex align-items-center">
        <PageTitle className="d-flex align-items-center">
          <ItemImage size="mini" item={item} imageKey="product" />
          <span className="ml-2">Change Logs for {item?.product?.sku}</span>
          <MuteText>: {item?.location?.code}</MuteText>
        </PageTitle>
      </div>
    </HeaderBar>
  );
}

export function ItemLogsScreen() {
  const { id } = useParams();

  const [adjustmentItem, setAdjusmentItem] = useState(null);

  const {
    data,
    item,
    loading,
    totalData,
    totalPages,
    filter,
    filterChanged,
    pageChanged,
    refresh,
  } = InventoryLogsListHook(id);

  const columns = [
    {
      text: 'Timestamp',
      key: 'timestamp',
      width: 2,
      textAlign: 'left',
    },
    {
      text: 'Update',
      key: 'update',
      width: 2,
      textAlign: 'left',
    },
    {
      text: 'By',
      key: 'by',
      width: 2,
      textAlign: 'left',
    },
    {
      text: 'Note',
      key: 'note',
      width: 3,
      textAlign: 'left',
    },
    {
      text: 'Previous Quantity',
      key: 'previous_quantity',
      width: 2,
      textAlign: 'left',
    },
    {
      text: 'Adjustment',
      key: 'adjustment',
      width: 2,
      textAlign: 'left',
    },
    {
      text: 'Allocated Inv',
      key: 'allocated_inventory',
      width: 2,
      textAlign: 'left',
    },
    {
      text: 'New Quantity',
      key: 'new_quantity',
      width: 2,
      textAlign: 'left',
    },
  ];

  const openAdjustmentPopup = () => {
    setAdjusmentItem({
      product: {
        p_images: {
          display_image: item.product.displayImage,
        },
        p_sku: item.product.sku,
      },
      productId: item.product?._id,
      locationSummary: [
        {
          lotNumber: item.location.code,
          inventoryId: id,
          quantity: {
            available: item.currentState.available,
          },
        },
      ],
      lotIndex: 0,
    });
  };

  const closeAdjustmentPopup = () => {
    setAdjusmentItem(null);
  };

  const customPageSizes = [
    { key: 25, value: 25, text: '25 results per page' },
    { key: 75, value: 75, text: '75 results per page' },
    { key: 150, value: 150, text: '150 results per page' },
    { key: 250, value: 250, text: '250 results per page' },
  ];

  const firstModified = useMemo(() => {
    if (CommonUtility.isValidArray(data)) {
      return data[data?.length - 1].createdAt;
    }
    return new Date();
  }, [data]);

  return (
    <BodyContainer>
      <Header item={item} />
      <ItemLogSummary
        firstModified={firstModified}
        openAdjustmentPopup={openAdjustmentPopup}
        item={item}
      />
      <AppTable
        loading={loading}
        columns={columns}
        data={data || []}
        totalData={totalData}
        totalPages={totalPages}
        currentPage={filter.page}
        pageSizeChanged={size => filterChanged('limit', size)}
        pageSize={filter.limit}
        RowComponent={<ItemLogRow />}
        pageChanged={pageChanged}
        customPageSizes={customPageSizes}
      />
      <InventoryAdjustmentPopup
        onClose={closeAdjustmentPopup}
        open={!!adjustmentItem}
        item={adjustmentItem}
        refresh={refresh}
        warehouseId={item?.warehouseId}
      />
    </BodyContainer>
  );
}
