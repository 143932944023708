import styled from 'styled-components';
import { DotsThree, ListMagnifyingGlass, X } from '@phosphor-icons/react';
import { Loader } from 'semantic-ui-react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { MobileModalPopup, WhiteBackgroundButton } from '../../../elements';
import { theme } from '../../../utility';

const Container = styled.div`
  display: flex;
  background: ${({ theme }) => theme.colors.box};
  padding: 15px;
  position: fixed;
  bottom: 0px;
  left: 0px;
  width: 100%;
  column-gap: 16px;
  z-index: 150;
`;

const ElipsisContainer = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.primary};
  border-radius: 5px;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LargeButton = styled.div`
  border-radius: 5px;
  background: ${({ theme }) => theme.colors.primary};
  color: white;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  font-size: 20px;
`;

export function MobileWaveDetailFooter({
  text,
  onClick,
  loading,
  showPickList,
}) {
  const [openMobileMenu, setOpenMobileMenu] = useState(false);

  const navigate = useNavigate();

  const closeMobilePopupSidebar = () => {
    setOpenMobileMenu(false);
  };

  const openMobilePopupSidebar = () => {
    setOpenMobileMenu(true);
  };

  const goToWaveList = () => {
    navigate('/app/fulfill/waves/list');
  };

  return (
    <>
      <Container>
        <ElipsisContainer onClick={openMobilePopupSidebar}>
          <DotsThree size={42} />
        </ElipsisContainer>
        {onClick && (
          <LargeButton
            onClick={() => {
              !loading && onClick();
            }}
          >
            {loading ? <Loader active inverted inline /> : text}
          </LargeButton>
        )}
      </Container>
      <MobileModalPopup
        open={openMobileMenu}
        closeModal={closeMobilePopupSidebar}
        bgColor={theme.colors.box}
        style={{
          height: '30vh',
          overflowY: 'auto',
        }}
      >
        <div className="d-flex flex-column mt-4">
          {showPickList && (
            <WhiteBackgroundButton
              onClick={showPickList}
              size="large"
              className="flex-btn"
            >
              <ListMagnifyingGlass size={24} className="mr-2" />
              View Pick List
            </WhiteBackgroundButton>
          )}
          <WhiteBackgroundButton
            onClick={goToWaveList}
            size="large"
            className="flex-btn mt-3"
          >
            <X size={24} className="mr-2" />
            Close Wave
          </WhiteBackgroundButton>
        </div>
      </MobileModalPopup>
    </>
  );
}
