import ActionSheet from 'actionsheet-react';
import { useEffect, useRef } from 'react';
import styled from 'styled-components';

const ModalContainer = styled.div`
  flex-direction: column;
  background-color: ${({ theme, bgcolor }) => bgcolor || theme.colors.white};
  flex: 1;
  overflow: hidden;
  z-index: 1001;
  transition: 0.3s;
  padding: 10px;
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
`;

const CloseContainer = styled.div`
  background: ${({ theme }) => theme.colors.grey};
  opacity: 0.3;
  border-radius: 50px;
  width: 113px;
  height: 6px;
`;

export function MobileModalPopup({
  open,
  bgColor,
  closeModal,
  children,
  style = {},
}) {
  const ref = useRef();

  useEffect(() => {
    if (open) {
      ref.current.open();
    } else {
      ref.current.close();
    }
  }, [open]);

  const handleClose = () => {
    ref.current.close();
    closeModal();
  };

  return (
    <ActionSheet
      ref={ref}
      onClose={handleClose}
      sheetStyle={{
        overflow: 'hidden',
        zIndex: 10001,
        borderRadius: '0px',
      }}
    >
      <ModalContainer bgcolor={bgColor} style={style}>
        <Container>
          <CloseContainer onClick={() => handleClose()} />
        </Container>
        {children}
      </ModalContainer>
    </ActionSheet>
  );
}
