import styled from 'styled-components';
import { AppTable, ItemImage, SkupremeModal } from '../../../components';
import { BoldText, HoverBorderButton, MuteText } from '../../../elements';
import { WaveListHook } from '../../../hooks';
import { WaveItemRow } from './WaveRow';

const ItemContainer = styled.div`
  background: ${({ theme }) => theme.colors.box};
  padding: 10px;
  border-radius: 5px;
`;

export function AssignAllocationPopup({
  warehouseId,
  open,
  onClose,
  allocation,
  onAssignItem,
  processing,
}) {
  const {
    data,
    totalData,
    totalPages,
    loading,
    filter,
    filterChanged,
    pageChanged,
  } = WaveListHook('building', warehouseId);

  const columns = [
    {
      text: 'Wave Number',
      key: 'wave-number',
      width: 2,
      textAlign: 'left',
    },
    {
      text: 'Status',
      key: 'status',
      textAlign: 'left',
      width: 2,
    },
    {
      text: 'Created',
      key: 'created',
      textAlign: 'left',
      width: 2,
    },
    {
      text: 'Rule',
      key: 'rule',
      textAlign: 'left',
      width: 2,
    },
    {
      text: 'Contents',
      textAlign: 'left',
      key: 'details',
      width: 3,
    },
    {
      text: ' ',
      textAlign: 'left',
      key: 'action',
      width: 2,
    },
  ];

  return (
    <SkupremeModal
      onClose={onClose}
      size="large"
      open={open}
      title="Assign Allocation to Wave"
    >
      <ItemContainer className="d-flex justify-content-between mb-4">
        <div className="d-flex">
          <ItemImage
            imageKey="images"
            displayImageKey="display_image"
            item={allocation?.inventory?.product}
            size="mini"
          />
          <div className="ml-4">
            <div>{allocation?.inventory?.product?.name}</div>
            <div className="d-flex">
              <MuteText className="mr-2">SKU</MuteText>
              <BoldText>{allocation?.inventory?.product?.sku}</BoldText>
            </div>
          </div>
        </div>
        <div>
          <div className="d-flex ml-4">
            <MuteText className="mr-2">Quantity:</MuteText>
            <BoldText>{allocation?.quantity}</BoldText>
            <MuteText>&nbsp;Unit(s)</MuteText>
          </div>
          <div className="d-flex ml-4">
            <MuteText className="mr-2">Order ID</MuteText>
            <BoldText>{allocation?.order?.marketplaceOrderDisplayId}</BoldText>
          </div>
        </div>
      </ItemContainer>
      <p>Select a Wave to Assign Allocaiton to it</p>
      <AppTable
        loading={loading}
        columns={columns}
        data={data || []}
        noShadow
        totalData={totalData}
        totalPages={totalPages}
        currentPage={filter.page}
        pageSizeChanged={size => filterChanged('limit', size)}
        pageSize={filter.limit}
        pageChanged={pageChanged}
        RowComponent={
          <WaveItemRow processing={processing} onAssignItem={onAssignItem} />
        }
        noDataMessage="No Waves in Building Status"
      />
      <div className="mt-4">
        <HoverBorderButton loading={processing}>Close</HoverBorderButton>
      </div>
    </SkupremeModal>
  );
}
