export class RandomUtility {
  static generateDarkColor() {
    const red = Math.floor(Math.random() * 128); // 0 to 127
    const green = Math.floor(Math.random() * 128); // 0 to 127
    const blue = Math.floor(Math.random() * 128); // 0 to 127

    // Convert the values to hexadecimal and ensure they are two digits.
    const toHex = value => value.toString(16).padStart(2, '0');

    // Combine the components into a hex color string.
    return `#${toHex(red)}${toHex(green)}${toHex(blue)}`;
  }
}
