import { createContext, useContext, useState } from 'react';

const GlobalContext = createContext();

export function GlobalProvider({ children }) {
  const [showSidebar, setShowSidebar] = useState(true);

  return (
    <GlobalContext.Provider
      value={{
        showSidebar,
        setShowSidebar,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
}

export const useGlobalContext = () => useContext(GlobalContext);
