import styled from 'styled-components';
import { Input, Loader } from 'semantic-ui-react';
import { MagnifyingGlass, X } from '@phosphor-icons/react';
import { forwardRef } from 'react';

const SearchIcon = styled(MagnifyingGlass)`
  pointer-events: none;
  cursor: default;
  position: absolute;
  line-height: 1;
  text-align: center;
  top: 8px;
  right: 7px;
  margin: 0;
  opacity: 0.5;
  border-radius: 0 0.28571429rem 0.28571429rem 0;
  transition: opacity 0.3s ease;
`;
const CrossIcon = styled(X)`
  cursor: pointer;
  position: absolute;
  line-height: 1;
  text-align: center;
  pointer-events: all;
  top: 8px;
  right: 7px;
  margin: 0;
  opacity: 0.5;
  border-radius: 0 0.28571429rem 0.28571429rem 0;
  transition: opacity 0.3s ease;
`;

const StyledInput = styled(Input)`
  .secondary-icon {
    display: none;
  }

  height: 40px;

  input {
    border-radius: 5px !important;
    border: 1px solid #acadb6 !important;
  }

  &.no-border {
    input {
      border: none !important;
      box-shadow: 0 2px 15px rgba(57, 57, 72, 0.05);
    }
  }

  .default-icon {
    display: ${({ showSecondary }) =>
      showSecondary ? 'none' : 'block'} !important;
  }

  .secondary-icon {
    display: ${({ showSecondary }) =>
      !showSecondary ? 'none' : 'block'} !important;
  }
`;

const LoaderCircle = styled(Loader)`
  position: absolute;
  pointer-events: none;
  cursor: default;
  line-height: 1;
  text-align: center;
  top: 20px !important;
  right: -4px !important;
  left: unset !important;
  bottom: unset !important;
`;

export const SearchInput = forwardRef(
  (
    {
      placeholder,
      search,
      onChange,
      setSearch,
      loading,
      iconSize = 24,
      ...props
    },
    ref,
  ) => {
    const onChangeHandler = value => {
      setSearch && setSearch(value);
      onChange && onChange(value);
    };

    return (
      <StyledInput
        {...props}
        ref={ref}
        showSecondary={props?.value !== undefined ? props.value : search}
        placeholder={placeholder || ' '}
        value={search}
        onChange={(_, { value }) => {
          onChangeHandler(value);
        }}
        icon={
          loading ? (
            <LoaderCircle size="small" active />
          ) : (
            <>
              <SearchIcon className="default-icon" size={iconSize} />
              <CrossIcon
                onMouseDown={() => onChangeHandler('')}
                className="secondary-icon"
                size={iconSize}
              />
            </>
          )
        }
      />
    );
  },
);
