import styled from 'styled-components';
import { Input } from 'semantic-ui-react';
import { DateFormat, DateUtility } from '../utility';
import { MuteText } from './Common';

const DateInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export function CustomDateInput({ date = '', label = '', ...rest }) {
  delete rest.value;
  const dateValue = DateUtility.formatDate(date, DateFormat.date);
  return (
    <DateInputWrapper>
      {label && <MuteText>{label}</MuteText>}
      <Input value={dateValue} {...rest} />
    </DateInputWrapper>
  );
}

export const DatepickerContainer = styled.div`
  display: flex;
  flex-direction: column;
  .react-datepicker-wrapper {
    width: 100% !important;
  }
  .react-datepicker__triangle {
    stroke: ${({ theme }) => theme.colors.box};
    fill: ${({ theme }) => theme.colors.white} !important;
    color: ${({ theme }) => theme.colors.white} !important;
  }
  .react-datepicker-time__header {
    padding: 10px 0 !important;
  }
  .react-datepicker {
    display: flex;
    flex-direction: column;
    font-family: 'Nunito Sans', sans-serif !important;
    border: 1px solid ${({ theme }) => theme.colors.box} !important;
    .react-datepicker__triangle {
      margin-left: 10px;
      &::before {
        border-bottom-color: ${({ theme }) => theme.colors.grey} !important;
      }
      &::after {
        border-bottom-color: ${({ theme }) => theme.colors.white} !important;
      }
    }
    .react-datepicker__header {
      background: ${({ theme }) => theme.colors.white};
      padding: 0;
      border-bottom: none;
    }
    .react-datepicker__navigation--next,
    .react-datepicker__navigation--previous {
      top: 3px;
    }
    .react-datepicker__current-month {
      margin-bottom: 7px;
      margin-top: 7px;
    }
    .react-datepicker__day {
      flex-grow: 1 !important ;
      width: 35px;
      height: 35px;
      font-size: 14px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-right: 1px solid ${({ theme }) => theme.colors.box};
      border-bottom: 1px solid ${({ theme }) => theme.colors.box};
      box-sizing: border-box;
      margin: 0 !important;
    }

    .react-datepicker__day--range-start,
    .react-datepicker__day--selecting-range-start,
    .react-datepicker__day--range-end,
    .react-datepicker__day--keyboard-selected,
    .react-datepicker__day--selected {
      background: ${({ theme }) => theme.colors.primary} !important;
      border: none !important;
      color: ${({ theme }) => theme.colors.white} !important;
      border-radius: 3px !important;
    }
    .react-datepicker__day--in-selecting-range {
      background: rgba(172, 173, 182, 0.5);
      color: ${({ theme }) => theme.colors.black};
    }
    .react-datepicker__week {
      display: flex !important ;
      border-left: 1px solid ${({ theme }) => theme.colors.box};
      margin-bottom: 4px !important;
    }
    .react-datepicker__month {
      margin-top: 0 !important;
    }
    .react-datepicker__day-name {
      flex-grow: 1 !important ;
      color: ${({ theme }) => theme.colors.grey};
      border-right: 1px solid ${({ theme }) => theme.colors.box};
      width: 35px;
      height: 35px;
      margin: 0;
      font-size: 12px;
      display: flex;
      justify-content: center;
      box-sizing: border-box;
      align-items: center;
    }
    .react-datepicker__day-names {
      border: 1px solid ${({ theme }) => theme.colors.box};
      border-right: none;
      margin: 0 0.4rem;
      display: flex !important ;
    }
    .react-datepicker__day--in-range {
      background: rgba(172, 173, 182, 0.5);
      color: ${({ theme }) => theme.colors.black};
      border-radius: unset;
      border-right: none;
    }
  }
`;

export const DateRangeInputContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
  .ui.input > input {
    width: 50% !important;
    max-width: 120px;
  }
`;
