import { createContext, useContext, useEffect } from 'react';
import { useAuth } from './auth';
import { useDebounce } from '../hooks';

const HubSpotContext = createContext();

export function HubSpotProvider({ children }) {
  const { isAuthenticated, user, loading } = useAuth();

  const clearHubSpotAndReload = () => {
    const status = window.HubSpotConversations?.widget?.status();
    let onConversationsAPIReady_load_count = 0;
    let onConversationsAPIReady_reset_count = 0;

    function onConversationsAPIReady() {
      if (onConversationsAPIReady_load_count > 0) return;

      onConversationsAPIReady_load_count += 1;
      window.HubSpotConversations?.widget.load();
    }

    function resetConversations() {
      if (onConversationsAPIReady_reset_count > 0) return;

      onConversationsAPIReady_reset_count += 1;
      window.HubSpotConversations.clear({ resetWidget: true });
      window.HubSpotConversations.widget.load();
    }

    if (status?.loaded) {
      resetConversations();
    } else if (window.HubSpotConversations) {
      onConversationsAPIReady();
    } else {
      window.hsConversationsOnReady = [onConversationsAPIReady];
    }
  };

  const loadUser = () => {
    if (!user.email || !user.userHash) return;

    window.hsConversationsSettings = {
      loadImmediately: false,
      identificationEmail: user.email,
      identificationToken: user.userHash,
    };

    clearHubSpotAndReload();
  };

  const unmountUser = () => {
    window.hsConversationsSettings = {
      loadImmediately: false,
      identificationEmail: '',
      identificationToken: '',
    };

    clearHubSpotAndReload();
  };

  const debouncedAuthenticated = useDebounce(isAuthenticated, 1000);

  useEffect(() => {
    if (loading) return;

    if (debouncedAuthenticated) {
      loadUser();
    } else {
      unmountUser();
    }
  }, [debouncedAuthenticated]);

  return (
    <HubSpotContext.Provider value={{}}>{children}</HubSpotContext.Provider>
  );
}

export const useHubspotContext = () => useContext(HubSpotContext);
