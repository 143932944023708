import { useState } from 'react';
import { ClientService } from '../utility/services/client';
import {
  CommonUtility,
  DateFormat,
  DateUtility,
  ToastMessage,
} from '../utility';
import { useDebouncedEffect } from './debounce';

export const ClientHook = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [error, setError] = useState(null);
  const [exporting, setExporting] = useState(false);
  const [defaultFilter, setDefaultFilter] = useState({});

  const [filter, setFilter] = useState({
    startDate: '',
    endDate: '',
    timeRange: '',
  });

  const prepareQuery = () => {
    let params = {
      ...filter,
    };

    params = CommonUtility.removeEmptyFields(params);
    return params;
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const params = prepareQuery();
      const res = await ClientService.get(params);
      setData(res);
      setDefaultFilter({
        startDate: res.start,
        endDate: res.end,
      });
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useDebouncedEffect(
    () => {
      fetchData();
    },
    [filter],
    500,
  );

  const exportCSV = async () => {
    setExporting(true);
    try {
      const params = prepareQuery();
      const response = await ClientService.export(params);
      const date = DateUtility.formatDate(new Date(), DateFormat.dateTime);
      const fileName = `client-list-${date}`;
      CommonUtility.downloadCSV(response, fileName);
      ToastMessage.success('The Client list has been downloaded successfully.');
    } catch (error) {
      ToastMessage.apiError(error);
    } finally {
      setExporting(false);
    }
  };

  const exportIndividualCSV = async customerOrganizationId => {
    setLoading(true);
    try {
      const params = prepareQuery();
      params.customerOrganizationId = customerOrganizationId;
      if (!params.startDate) {
        params.startDate = defaultFilter.startDate;
      }
      if (!params.endDate) {
        params.endDate = defaultFilter.endDate;
      }
      const response = await ClientService.exportIndividual(params);
      const date = DateUtility.formatDate(new Date(), DateFormat.dateTime);
      const fileName = `client-list-${date}`;
      CommonUtility.downloadCSV(response, fileName);
      ToastMessage.success('The Client list has been downloaded successfully.');
    } catch (error) {
      ToastMessage.apiError(error);
    } finally {
      setLoading(false);
    }
  };

  const updateAllFilter = obj => {
    setFilter(filter => ({
      ...filter,
      ...obj,
    }));
  };

  return {
    data,
    loading,
    error,
    exportCSV,
    exporting,
    filter,
    updateAllFilter,
    exportIndividualCSV,
  };
};
