import { Image } from 'semantic-ui-react';
import styled from 'styled-components';
import {
  useEffect,
  cloneElement,
  useState,
  useMemo,
  isValidElement,
} from 'react';
import { ArrowUp } from '@phosphor-icons/react';
import { Link, useLocation } from 'react-router-dom';
import { DesktopSidebar, MenuIconContainer } from './components/Menu';
import { DarkPopup } from '../components';
import { useAuth } from '../contexts/auth';
import { CommonUtility } from '../utility/common';
import { Permissions } from '../utility/constants/permission';
import { Images } from '../images';

const Logo = styled(Image)`
  opacity: 1;
  height: auto;
  transition: all ease 0.15s;
  width: 26px;
  height: 26px;
`;

const StyledLink = styled.a`
  color: ${({ theme }) => theme.colors.white} !important;
  position: relative;
`;

const HeaderBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  margin-bottom: 5px;
  width: 100%;
`;

const LogoHeaderBar = styled(HeaderBar)`
  margin-top: 3px;
  display: flex;
  justify-content: space-between;
  a {
    user-select: none;
  }
  max-height: 55px;
  min-height: 55px;
`;

const RightLink = styled(StyledLink)`
  display: flex;
  align-items: center;
  padding: 5px;
  transition: opacity 0.2s ease-in-out;
  opacity: 0;

  &.accordion-menu {
    opacity: 1;
  }

  i {
    margin: 0;
    width: 18px;
    height: 18px;
  }

  &.active {
    opacity: 1;
  }
`;

export const MenuLinkContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  margin: 0 10px;
  padding: 0 10px;
  color: ${({ theme }) => theme.colors.white} !important;

  &.accordion {
    cursor: pointer;
  }

  &.active {
    background: ${({ theme }) => theme.colors.lightBlack};
  }

  &.sidebar-collapsed {
    padding: 0;
    margin: 0;
    justify-content: center;
  }

  ${({ active, theme }) =>
    active &&
    `
        background-color: ${theme.colors.lightBlack};
    `}

  &:hover {
    background-color: ${({ theme }) => theme.colors.lightBlack};

    ${RightLink} {
      opacity: 1;
    }
    font-weight: bold;
    span,
    .sidebar-icon-main {
      color: ${({ theme }) => theme.colors.background};
    }
  }
`;

const UpgradeBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.colors.green};
  position: absolute;
  top: 0;
  left: 10px;
  padding: 2px;
  border-radius: 3px;
  font-size: 13px;
  color: ${({ theme }) => theme.colors.white};
`;

export const MenuLink = styled.div`
  font-size: 10px;
  color: ${({ theme }) => theme.colors.grey};
  padding: 7px 0;
  position: relative;
  max-width: 40px;

  ${UpgradeBox} {
    left: auto;
    top: 10px;
    right: 2px;

    padding: 2px;
    font-size: 10px;
  }

  &.low-opacity {
    opacity: 0.6;
  }

  display: flex;
  flex-direction: column;
  align-items: center;
  line-height: 12px;

  .sidebar-icon-main {
    height: 28px;
    width: 28px;
    object-fit: contain;
  }
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.colors.background};
    .sidebar-icon-main {
      color: ${({ theme }) => theme.colors.white} !important;
    }
  }

  &.active {
    color: ${({ theme }) => theme.colors.white} !important;
    .sidebar-icon-main {
      color: ${({ theme }) => theme.colors.white} !important;
    }
  }
`;

const MenusContainer = styled.div`
  max-height: 100%;
  ::-webkit-scrollbar {
    display: none;
  }
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const preventDrag = e => {
  e.preventDefault();
};

function UpgradeIcon({ item }) {
  return item.permission === Permissions.upgrade ? (
    <DarkPopup
      trigger={
        <UpgradeBox>
          <ArrowUp />
        </UpgradeBox>
      }
    >
      Upgrade Required
    </DarkPopup>
  ) : null;
}

export const SubMenuLink = styled(Link)`
  font-size: 11px;
  color: ${({ theme }) => theme.colors.grey};
  padding: 7px;
  position: relative;
  width: 100%;
  border-radius: 5px;

  ${UpgradeBox} {
    position: static;
  }

  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: all 0.2s ease;
  line-height: 12px;

  &.low-opacity {
    opacity: 0.6;
  }

  .sidebar-icon,
  .sidebar-icon-img {
    object-fit: contain;
  }

  .sidebar-icon {
    margin-right: 5px;
    height: 18px;
    width: 18px;
  }

  .sidebar-icon-img {
    opacity: 0.5;
    height: 16px;
    width: 16px;
    margin-right: 7px;
  }

  &:hover {
    color: ${({ theme }) => theme.colors.white};
    background: ${({ theme }) => theme.colors.lightBlack};
    .sidebar-icon-img {
      opacity: 1;
    }
  }

  &.active {
    background: ${({ theme }) => theme.colors.lightBlack};
    color: ${({ theme }) => theme.colors.white} !important;
    .sidebar-icon {
      color: ${({ theme }) => theme.colors.white} !important;
    }
    .sidebar-icon-img {
      opacity: 1;
    }
  }
`;

function SubMenu({ item, closeSidebar }) {
  const active = CommonUtility.compareUrls(item.link, window.location.pathname);

  const count = useMemo(() => {
    if (!item.count) return null;
    if (item.count > 98) {
      return 99;
    }
    return item.count;
  }, [item]);

  const isUpgrade = item.permission === Permissions.upgrade;

  return (
    <SubMenuLink
      className={`${active ? 'active' : ''} ${
        item.lowerOpacity || isUpgrade ? 'low-opacity' : ''
      }`}
      to={item.link}
      data-field-value={`sidebar-${item.key}`}
      onDragStart={preventDrag}
      onClick={closeSidebar}
      key={item.key}
    >
      <div className="d-flex align-items-center">
        {isValidElement(item.phosphorIcon) &&
          cloneElement(item.phosphorIcon, {
            weight: active ? 'duotone' : 'regular',
          })}
        <span>{item.text}</span>
      </div>
      {isUpgrade ? (
        <UpgradeIcon item={item} />
      ) : (
        !!count && <CountBadge>{count}</CountBadge>
      )}
    </SubMenuLink>
  );
}

const PopupMenusContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 5px;
  min-width: 200px;
`;

const WMStag = styled.span`
  font-size: 10px;
  margin-top: -13px;
  margin-bottom: 10px;
`;

const OrgLogo = styled.div`
  img {
    width: 45px;
    height: 45px;
    object-fit: contain;
  }

  margin-top: 13px;
  margin-bottom: 20px;

  display: flex;
  align-items: center;
  flex-direction: column;

  a {
    user-select: none;
  }

  span {
    font-size: 10px;
  }
`;

function MenuHeaderBar() {
  const { currentOrg } = useAuth();

  const isTactical = useMemo(
    () => currentOrg?.name?.toLowerCase?.()?.includes?.('tactical'),
    [currentOrg],
  );

  return (
    <>
      {isTactical ? (
        <OrgLogo>
          <Image src={CommonUtility.getCdnLink('integrations/tactical.svg')} />
        </OrgLogo>
      ) : (
        <>
          <Link to="/app/dashboard/">
            <LogoHeaderBar>
              <Logo src={Images.smallWhiteLogo} />
            </LogoHeaderBar>
          </Link>
          <WMStag>WMS</WMStag>
        </>
      )}
    </>
  );
}

const ActiveAccordion = styled.div`
  width: 0;
  height: 0;
  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent;
  border-right: 6px solid ${({ theme }) => theme.colors.lightBlack};
  position: absolute;
  right: -10px;
  top: 24px;
`;

const CountBadge = styled.div`
  background: ${({ theme }) => theme.colors.danger};
  position: absolute;
  top: 10px;
  right: 2px;
  border-radius: 3px;
  font-size: 10px;
  color: ${({ theme }) => theme.colors.white};

  height: 15px;
  width: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

function Menu({ item, activateMenu, isMenuActive }) {
  const active = window.location.pathname?.includes(item.parentLink);

  const count = useMemo(() => {
    if (!item.count) return null;
    if (item.count > 98) {
      return 99;
    }
    return item.count;
  }, [item]);

  const isUpgrade = item.permission === Permissions.upgrade;

  return (
    <DarkPopup
      hoverable
      primaryDark
      position="right center"
      offset={[-10, 5]}
      padding="10px"
      key={item.key}
      trigger={
        <MenuLink
          className={`${active || isMenuActive ? 'active' : ''} ${
            item.lowerOpacity || isUpgrade ? 'low-opacity' : ''
          }`}
          data-field-value={`sidebar-${item.key}`}
          onClick={activateMenu}
          onMouseEnter={activateMenu}
          onDragStart={preventDrag}
          key={item.key}
        >
          <MenuIconContainer
            className={`${active || isMenuActive ? 'active' : ''}`}
          >
            {isValidElement(item.phosphorIcon) &&
              cloneElement(item.phosphorIcon)}
          </MenuIconContainer>
          <span>{item.text}</span>

          {isUpgrade ? (
            <UpgradeIcon item={item} />
          ) : (
            !!count && <CountBadge>{count}</CountBadge>
          )}
          {isMenuActive && <ActiveAccordion />}
        </MenuLink>
      }
    >
      <PopupMenusContainer key={item.key}>
        {item.menus.map(itemMenu => (
          <SubMenu key={itemMenu.key} item={itemMenu} />
        ))}
      </PopupMenusContainer>
    </DarkPopup>
  );
}

export function DesktopMenuBar({ hideSidebar, isCollapsed, menus }) {
  const location = useLocation();
  const asPath = location.pathname;

  const [activeMenu, setActiveMenu] = useState(0);

  useEffect(() => {
    if (!CommonUtility.isValidArray(menus) || !asPath) {
      return;
    }

    let activeMenu = 0;
    menus.forEach((item, i) => {
      const active = asPath?.includes(item.parentLink);
      if (active) {
        activeMenu = i;
      }
    });
    setActiveMenu(activeMenu);
  }, [menus, asPath]);

  if (hideSidebar) {
    return <></>;
  }

  return (
    <DesktopSidebar>
      <MenusContainer>
        <MenuHeaderBar />
        {menus.map((item, i) => (
          <Menu
            activateMenu={() => {
              setActiveMenu(i);
            }}
            item={item}
            key={item.key}
            isMenuActive={activeMenu === i && !isCollapsed}
          />
        ))}
      </MenusContainer>
    </DesktopSidebar>
  );
}
