import { useEffect, useState } from 'react';
import {
  AllocationService,
  CommonConstant,
  CommonUtility,
  WaveService,
} from '../utility';
import { useDebounce, useDebouncedEffect } from './debounce';

export const WaveListHook = (status, warehouseId, search) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [totalData, setTotalData] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [filter, setFilter] = useState({
    limit: 10,
    page: 1,
  });

  const fetchData = async () => {
    setLoading(true);
    try {
      const params = {
        ...filter,
        status,
        warehouseId,
      };
      if (search) {
        params.search = search;
      }
      const res = await WaveService.waveList(params);
      setData(res?.data);
      setTotalData(res?.total);
      setTotalPages(res?.totalPages);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const finalSearch = useDebounce(search, 200);

  useDebouncedEffect(
    () => {
      if (warehouseId) {
        fetchData();
      }
    },
    [filter, status, warehouseId, finalSearch],
    300,
  );

  const pageChanged = page => {
    const temp = {
      ...filter,
      page,
    };
    setFilter({ ...temp });
  };

  const filterChanged = (key, value) => {
    const temp = {
      ...filter,
      page: 1,
      [key]: value,
    };
    setFilter({ ...temp });
  };

  return {
    data,
    loading,
    error,
    totalData,
    totalPages,
    filter,
    pageChanged,
    filterChanged,
  };
};

const isValidWaveSearch = search => !!(search && typeof search === 'string');

export const wavesAutocompleteHook = (search, warehouseId) => {
  const [data, setData] = useState([]);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const fetchData = async () => {
    try {
      setLoading(true);
      const result = await WaveService.waveList({ search, warehouseId });
      const mapped = result.data.map(d => ({ ...d, search }));
      setData(mapped);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setData([]);
    if (isValidWaveSearch(search) && warehouseId) {
      fetchData();
    }
  }, [search, warehouseId]);

  return { data, error, loading };
};

export const AllocationHook = (status, warehouseId) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [totalData, setTotalData] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [refreshKey, setRefreshKey] = useState(0);
  const [filter, setFilter] = useState({
    limit: 10,
    page: 1,
  });

  const fetchData = async () => {
    setLoading(true);
    try {
      const params = {
        ...filter,
        status,
        warehouseId,
      };
      const res = await AllocationService.getAllocations(params);
      setData(res?.data?.allocations || []);
      setTotalData(res?.total);
      setTotalPages(res?.totalPages);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useDebouncedEffect(
    () => {
      if (warehouseId) {
        fetchData();
      }
    },
    [filter, status, warehouseId, refreshKey],
    300,
  );

  const pageChanged = page => {
    const temp = {
      ...filter,
      page,
    };
    setFilter({ ...temp });
  };

  const filterChanged = (key, value) => {
    const temp = {
      ...filter,
      page: 1,
      [key]: value,
    };
    setFilter({ ...temp });
  };

  const refresh = () => {
    setRefreshKey(Math.random());
  };

  return {
    data,
    loading,
    error,
    totalData,
    totalPages,
    filter,
    pageChanged,
    filterChanged,
    refresh,
  };
};

export const WaveRuleListHook = warehouseId => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [totalData, setTotalData] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [refreshKey, setRefreshKey] = useState(0);
  const [filter, setFilter] = useState({
    limit: CommonConstant.defaultPageSize,
    page: 1,
  });

  const fetchData = async () => {
    setLoading(true);
    try {
      let params = { ...filter, warehouseId };
      params = CommonUtility.removeEmptyFields(params);
      const res = await WaveService.waveRuleList(params);
      setData(res?.rules);
      setTotalData(res?.total);
      setTotalPages(res?.totalPages);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useDebouncedEffect(
    () => {
      fetchData();
    },
    [warehouseId, refreshKey],
    500,
  );

  const pageChanged = page => {
    const temp = {
      ...filter,
      page,
    };
    setFilter({ ...temp });
  };

  const filterChanged = (key, value) => {
    const temp = {
      ...filter,
      page: 1,
      [key]: value,
    };
    setFilter({ ...temp });
  };

  const refresh = () => {
    setRefreshKey(Math.random());
  };

  const updateAllFilter = obj => {
    setFilter(filter => ({
      ...filter,
      page: 1,
      ...obj,
    }));
  };

  return {
    data,
    loading,
    error,
    totalData,
    totalPages,
    filter,
    pageChanged,
    filterChanged,
    refresh,
    updateAllFilter,
  };
};

export const WaveDetailHook = id => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [error, setError] = useState(null);
  const [allocations, setAllocations] = useState([]);
  const [refreshKey, setRefreshKey] = useState(0);

  const fetchData = async () => {
    setLoading(true);
    try {
      const res = await WaveService.getById(id);
      setData(JSON.parse(JSON.stringify(res?.data)) || {});
      let items = [];
      (res?.data?.wave?.allocations || [])?.forEach(item => {
        const itemExistAtIndex = items.findIndex(
          x => x.inventory.product.id === item.inventory.product.id,
        );
        if (itemExistAtIndex > -1) {
          if (CommonUtility.isValidArray(items[itemExistAtIndex].sameItems)) {
            items[itemExistAtIndex].sameItems.push(item);
          } else {
            items[itemExistAtIndex].sameItems = [items[itemExistAtIndex], item];
          }
        } else {
          items.push(item);
        }
      });
      setAllocations(items);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      fetchData();
    }
  }, [id, refreshKey]);

  const refresh = () => {
    setRefreshKey(Math.random());
  };

  return { data, loading, error, refresh, setLoading, allocations };
};

export const SortedWaveListHook = warehouseId => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [totalData, setTotalData] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [refreshKey, setRefreshKey] = useState(0);
  const [filter, setFilter] = useState({
    limit: 10,
    page: 1,
  });

  const fetchData = async () => {
    setLoading(true);
    try {
      const params = {
        ...filter,
        warehouseId,
      };
      const res = await WaveService.sortedWaveList(params);
      setData(res?.data?.allocations || []);
      setTotalData(res?.total);
      setTotalPages(res?.totalPages);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useDebouncedEffect(
    () => {
      if (warehouseId) fetchData();
    },
    [filter, warehouseId, refreshKey],
    100,
  );

  const pageChanged = page => {
    const temp = {
      ...filter,
      page,
    };
    setFilter({ ...temp });
  };

  const filterChanged = (key, value) => {
    const temp = {
      ...filter,
      page: 1,
      [key]: value,
    };
    setFilter({ ...temp });
  };

  const refresh = () => {
    setRefreshKey(Math.random());
  };

  return {
    data,
    loading,
    error,
    totalData,
    totalPages,
    filter,
    pageChanged,
    filterChanged,
    refresh,
  };
};
