import { Menu, Tab } from 'semantic-ui-react';
import styled from 'styled-components';
import { useTabletAndBelowMediaQuery } from '../layouts/ResponsiveMedia';

export const StyledHeaderTab = styled(Tab)`
  .ui.secondary.menu {
    transition: all 0.2s;
    margin: 0 -20px;
    padding: 0 10px;
    position: fixed;
    top: 70px;
    width: calc(100% - 65px);
    left: 75px;
    z-index: 998;
    background: ${({ theme }) => theme.colors.box};
  }

  .segment.tab {
    background: none;
    padding: 0;
    margin-top: 20px;
    border: none;
    box-shadow: none;
  }
`;

const StyledTabStyleMenu = styled(Menu)`
  transition: all 0.2s;
  margin: 0 -20px;
  margin-top: 0 !important;
  padding: 0 20px;
  position: fixed;
  top: 100px;
  width: calc(100% - 70px);
  left: 60px;
  z-index: 996;
  background: ${({ theme }) => theme.colors.box} !important;
  color: ${({ theme }) => theme.colors.primary};
  .item {
    color: ${({ theme }) => theme.colors.primary} !important;
    padding: 0px !important;
    padding-bottom: 14px !important;
    font-size: 15px;
  }
  column-gap: 30px;

  &.is-mobile {
    left: 0px;
    width: 100%;
    top: 60px;
  }
`;

export function TabStyleMenu({
  menus,
  onTabChange,
  currentMenu,
  className = '',
  ...props
}) {
  const isMobile = useTabletAndBelowMediaQuery();

  return (
    <StyledTabStyleMenu
      className={`${className} ${isMobile ? 'is-mobile' : ''}`}
      pointing
      secondary
      {...props}
    >
      {menus.map(item => (
        <Menu.Item
          name={item.text}
          key={item.value}
          active={item.value === currentMenu}
          onClick={() => {
            onTabChange(item.value);
          }}
        >
          <span>{item.text}</span>
        </Menu.Item>
      ))}
    </StyledTabStyleMenu>
  );
}

export const BorderLessTabs = styled(Tab)`
  .ui.attached.segment {
    border: none !important;
  }
  .ui.attached.menu {
    border: none !important;
    padding: 0 15px;
    padding-bottom: 2px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.box} !important;
    overflow-y: hidden;
    overflow-x: hidden;
    border-radius: 0;
  }
  .ui.secondary.pointing.menu .item {
    padding: 17px;
  }
  a.item:not(.active) {
    border-bottom: 1.5px solid transparent !important;
    color: ${({ theme }) => theme.colors.grey};
    &:not(.disabled):hover {
      border-bottom: 1.5px solid ${({ theme }) => theme.colors.box} !important;
    }
  }
  &.not-active {
    opacity: 0.2;
    pointer-events: none;
  }
`;
