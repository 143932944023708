import styled from 'styled-components';

const Container = styled.div`
  min-width: 35px;
  min-height: 35px;
  max-width: 35px;
  max-height: 35px;
  &.mini {
    min-width: 20px;
    min-height: 20px;
    max-width: 20px;
    max-height: 20px;
    div {
      font-size: 10px !important;
    }
  }
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.black};
  color: ${({ theme }) => theme.colors.white};
  position: relative;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const Avatar = styled.div`
  width: 100%;
  height: 100%;
  border-radius: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  line-height: 1;
  font-weight: bold;
  text-transform: uppercase;
  padding-top: 0.5px;
`;

export const OrgImage = styled.img`
  width: 100%;
`;

export const ImageContainer = styled.div`
  width: 100%;
  overflow: hidden;
  border-radius: 50%;
  height: 100%;
  max-width: 35px;
  max-height: 35px;
  min-width: 35px;
  min-height: 35px;
  &.mini {
    min-width: 20px;
    min-height: 20px;
    max-width: 20px;
    max-height: 20px;
  }
`;

export function ImageAvatar({ image, name, ...rest }) {
  const initial = name ? name[0] : '';

  return image ? (
    <img src={image} alt={name} {...rest} />
  ) : (
    <Avatar {...rest}>{initial}</Avatar>
  );
}

export function OrganisationAvatar({ mini, image, name, ...rest }) {
  const initial = name ? name[0] : '';

  return image ? (
    <ImageContainer className={mini ? 'mini' : ''}>
      <OrgImage src={image} alt={name} {...rest} />
    </ImageContainer>
  ) : (
    <Container className={mini ? 'mini' : ''}>
      <Avatar {...rest}>{initial}</Avatar>
    </Container>
  );
}
