import { Circle, LinkSimpleBreak, PuzzlePiece } from '@phosphor-icons/react';
import { ColoredPill } from '../../elements';
import { ItemStates, theme } from '../../utility';

export function ItemTags({ state }) {
  if (state === ItemStates.resolved) {
    return <></>;
  }

  if (state === ItemStates.component) {
    return (
      <ColoredPill color={theme.colors.darkBlue}>
        <PuzzlePiece weight="fill" /> Component
      </ColoredPill>
    );
  }

  if ([ItemStates.inactive, ItemStates.discontinued].includes(state)) {
    return (
      <ColoredPill color={theme.colors.orange}>
        <LinkSimpleBreak weight="bold" /> {state}
      </ColoredPill>
    );
  }

  return (
    <ColoredPill color={theme.colors.red}>
      <Circle weight="fill" /> {state}
    </ColoredPill>
  );
}
