import styled from 'styled-components';
import { useMemo } from 'react';
import { Equals, Info, Minus, Plus } from '@phosphor-icons/react';
import {
  DangerText,
  HoverBorderButton,
  MuteText,
  ShadowBox,
} from '../../../elements';
import { CommonUtility, DateUtility } from '../../../utility';
import { DarkPopup } from '../../../components';

const LargeText = styled.b`
  font-size: 20px;
`;

const Container = styled(ShadowBox)`
  .equation {
    display: flex;
    column-gap: 20px;
    align-items: center;
  }
  display: flex;
  column-gap: 20px;
  align-items: center;
  justify-content: space-between;
`;

const TriggerContainer = styled.div`
  max-width: fit-content;
`;

const Equation = styled.div``;

export function ItemLogSummary({ item, openAdjustmentPopup, firstModified }) {
  const change = useMemo(
    () => item.finalCalculatedState?.available - item.initialState?.available,
    [item],
  );

  const timeAgo = useMemo(
    () => DateUtility.hourMinuteTime(firstModified, null, 1),
    [firstModified],
  );

  return (
    <Container>
      <div className="equation">
        <div className="d-flex flex-column">
          <LargeText>
            {CommonUtility.numberWithCommas(item.initialState?.available || 0)}{' '}
            Units
          </LargeText>
          <MuteText className="mt-1">Previous Quantity</MuteText>
        </div>
        <Equation>{change > 0 ? <Plus /> : <Minus />}</Equation>
        <div className="d-flex flex-column">
          <LargeText>
            {item.hasDiscrepancies ? (
              <DarkPopup
                trigger={
                  <TriggerContainer className="d-flex">
                    <DangerText>
                      {CommonUtility.numberWithCommas(Math.abs(change) || 0)}{' '}
                      Units
                    </DangerText>
                    <MuteText>
                      <Info className="ml-1 cursor-pointer" />
                    </MuteText>
                  </TriggerContainer>
                }
                position="right center"
              >
                There is a inventory discrepancy or missing log. Please contact
                support
              </DarkPopup>
            ) : (
              `${CommonUtility.numberWithCommas(Math.abs(change) || 0)} Units`
            )}
          </LargeText>
          <MuteText className="mt-1">
            Adjusment Quantity, Past {timeAgo}
          </MuteText>
        </div>
        <Equation>
          <Equals />
        </Equation>
        <div className="d-flex flex-column">
          <LargeText>
            {CommonUtility.numberWithCommas(item.currentState?.available || 0)}{' '}
            Units
          </LargeText>
          <MuteText className="mt-1">Current Quantity</MuteText>
        </div>
      </div>
      <div>
        <HoverBorderButton className="flex-btn" onClick={openAdjustmentPopup}>
          <Plus className="mr-1" /> Adjusment
        </HoverBorderButton>
      </div>
    </Container>
  );
}
