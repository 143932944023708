import styled from 'styled-components';
import { useEffect } from 'react';
import { LightBorderButton, StyledButton } from './Button';
import { useTabletAndBelowMediaQuery } from '../layouts/ResponsiveMedia';
import { useSidebar } from '../contexts/sidebar';
import { useChatPosition } from '../hooks';

const CommonFooterStyle = styled.div`
  background: ${({ type, theme }) =>
    type === 'info'
      ? theme.colors.primaryLight
      : type === 'black'
      ? theme.colors.primaryLight
      : type === 'green'
      ? theme.colors.green
      : theme.colors.success};
  color: ${({ theme }) => theme.colors.white};
  padding: 1.5em 0;
  position: fixed;
  bottom: 10px;
  border-radius: 0 0 7px 7px;
  margin: 0;
  text-align: center;
  font-size: 15px;
  line-height: 18px;
  z-index: 10;
`;

const FooterRow = styled(CommonFooterStyle)`
  transition: all 0.2s;
  left: ${({ isCollapsed, fullWidth = false }) =>
    fullWidth ? '0px' : isCollapsed ? '60px' : '245px'};
  width: calc(
    100% -
      ${({ isCollapsed, fullWidth = false }) =>
        fullWidth ? '0px' : isCollapsed ? '70px' : '255px'}
  );

  &.is-mobile {
    width: 100%;
    left: 0;
    bottom: 0;
    border-radius: 0;
  }

  z-index: 1010;
  @media only screen and (max-width: 768px) {
    left: 0px;
    width: 100%;
  }
`;

const FooterHeader = styled.div`
  font-size: 20px;
  line-height: 23px;
  font-weight: bold;
`;

const FooterContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  @media only screen and (max-width: 768px) {
    padding-left: 15px !important;
    padding-right: 15px !important;
    .footer-title {
      font-size: 16px;
      text-align: left;
    }
    &.assign-items {
      align-items: flex-end;
    }
  }
`;

function FooterAlert({ type, children, className, fullWidth }) {
  const isTabletAndBelow = useTabletAndBelowMediaQuery();
  const { isCollapsed, setFooterActive } = useSidebar();

  useEffect(() => {
    setFooterActive(true);
    return () => setFooterActive(false);
  }, []);

  useChatPosition({ height: isTabletAndBelow ? 140 : 85 });

  return (
    <FooterRow
      type={type}
      isCollapsed={isCollapsed || isTabletAndBelow}
      className={`d-flex ${isTabletAndBelow ? 'is-mobile' : ''} ${
        className || ''
      }`}
      fullWidth={fullWidth}
    >
      {children}
    </FooterRow>
  );
}

export function AlertFooterBar({
  cancel,
  cancelButtonText = 'Discard',
  disabled,
  loading = false,
  message,
  save,
  saveButtonText = 'Save Changes',
  submitButton,
  disabledSaveButton = false,
}) {
  return (
    <FooterAlert type="green">
      <FooterContainer className="justify-content-between px-4">
        <FooterHeader className="m-0 footer-title">{message}</FooterHeader>
        <div className="d-flex align-items-center">
          <LightBorderButton disabled={disabled} type="button" onClick={cancel}>
            {cancelButtonText}
          </LightBorderButton>
          <StyledButton
            {...(submitButton ? {} : { onClick: save })}
            className="ml-3 save-action"
            disabled={disabled || disabledSaveButton}
            loading={loading}
            type={submitButton ? 'submit' : 'button'}
          >
            {saveButtonText}
          </StyledButton>
        </div>
      </FooterContainer>
    </FooterAlert>
  );
}
