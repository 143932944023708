import { CaretDown } from '@phosphor-icons/react';
import { useEffect } from 'react';
import { Dropdown } from 'semantic-ui-react';
import styled from 'styled-components';
import { CacheUtility } from '../utility';

const StyledDropdown = styled(Dropdown)`
  font-size: 20px;
`;

const DropdownIcon = styled(CaretDown)`
  cursor: pointer;
  position: absolute;
  width: auto;
  height: auto;
  line-height: 1.21428571em;
  top: 0px;
  right: 0.7em;
  z-index: 3;
  right: -24px;
  opacity: 0.8;
  transition: opacity 0.1s ease;
`;

export function WarehouseDropdown({
  warehouseOptions,
  warehouseId,
  warehouseLoading,
  setWarehouseId,
  defaultWarehouseId,
  ...props
}) {
  const cacheKey = 'warehouse_id_key';

  useEffect(() => {
    const cache = CacheUtility.get(cacheKey);
    if (cache?.id) {
      setWarehouseId(cache?.id);
    } else if (defaultWarehouseId) {
      setWarehouseId(defaultWarehouseId);
    }
  }, [defaultWarehouseId]);

  const onChange = id => {
    setWarehouseId(id);
    CacheUtility.save(cacheKey, { id });
  };

  return (
    <StyledDropdown
      inline
      options={warehouseOptions}
      value={warehouseId}
      loading={warehouseLoading}
      onChange={(e, { value }) => onChange(value)}
      icon={<DropdownIcon size={18} />}
      {...props}
    />
  );
}
