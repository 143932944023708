import { theme } from '../theme';

export const ToteStatus = {
  open: 'open',
  shipped: 'shipped',
  ready_to_pack: 'ready_to_pack',
  in_progress: 'in_progress',
};

export const ToteStatusColors = {
  [ToteStatus.open]: theme.colors.green,
  [ToteStatus.shipped]: theme.colors.primary,
  [ToteStatus.ready_to_pack]: theme.colors.darkBlue,
  [ToteStatus.in_progress]: theme.colors.orange,
};
