import { CommonUtility } from '../common';
import { APIPath } from '../constants';
import { BaseService } from './base';
import { CRUDService } from './crud';

class Allocation extends CRUDService {
  constructor() {
    super(APIPath.allocation);
  }

  removeAllocation(waveId, allocationId) {
    const url = `${APIPath.orderWaves}/${waveId}/allocations/${allocationId}/remove`;
    return BaseService.post(url);
  }

  cancelAllocation(allocationId) {
    const url = `${APIPath.orderAllocations}/${allocationId}/cancel`;
    return BaseService.post(url);
  }

  getAllocations(params) {
    const url = `${APIPath.orderAllocations}/?${CommonUtility.objectToParams(
      params,
    )}`;
    return BaseService.get(url);
  }

  assignAllocationToWave(waveId, allocationId) {
    const url = `${APIPath.orderWaves}/${waveId}/allocations/${allocationId}/add`;
    return BaseService.post(url);
  }
}

const AllocationService = new Allocation();
Object.freeze(AllocationService);
export { AllocationService };
