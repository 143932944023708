import { useCallback } from 'react';
import {
  ArrowsHorizontal,
  ArrowsVertical,
  PencilSimpleLine,
  SplitVertical,
} from '@phosphor-icons/react';
import styled from 'styled-components';
import { AddRack } from './RackBox';
import { MuteText } from '../../elements';
import { CommonUtility } from '../../utility';
import { StaticRackBox } from './RackBoxStatic';

const AisleViewContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
`;

const IconButton = styled.div`
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${({ theme }) => theme.colors.grey};
  border-radius: 5px;
  &:hover {
    background: ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.white};
  }
`;

const RowContainer = styled.div`
  display: flex;
  column-gap: 10px;
  overflow-x: auto;
  border-radius: 5px;
`;

const AisleView = styled.div`
  width: 100%;
  height: 45px;
  padding: 5px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  .icon {
    opacity: 0;
    transition: all ease 0.2s;
  }

  border: 2px solid ${({ theme }) => theme.colors.box};
  border-left: none;
  border-right: none;

  cursor: pointer;
  user-select: none;
  transition: all ease 0.2s;

  &:hover {
    background: ${({ theme }) => theme.colors.box};
    .icon {
      opacity: 1;
    }
  }
`;

export function StaticAisleRenderer({
  aisle,
  addRackInAsile,
  onClickEditAisle,
  aisleIndex,
  color,
}) {
  const renderRack = useCallback(
    (side, rack, index) => (
      <StaticRackBox
        color={color}
        index={index}
        _id={rack._id}
        aisleSide={side}
        levelCount={rack.levelCount}
        bayCount={rack.bayCount}
        code={rack.code}
        key={rack._id}
        aisleIndex={aisleIndex}
      />
    ),
    [],
  );

  return (
    <AisleViewContainer>
      <RowContainer>
        {CommonUtility.isValidArray(aisle.left) &&
          aisle.left?.map((rack, index) => renderRack(0, rack, index))}
        <AddRack className="first-row" onClick={() => addRackInAsile(aisle)} />
      </RowContainer>
      <AisleView onClick={() => onClickEditAisle(aisle)}>
        <div className="d-flex">
          <SplitVertical color={color} className="mr-2" size={17} />
          {aisle.code}
        </div>
        <div className="d-flex align-items-center">
          <IconButton className="ml-2 icon">
            <PencilSimpleLine size={17} />
          </IconButton>
          <MuteText className="d-flex">
            <ArrowsVertical size={17} />
            {aisle.dimensions?.width}
            {aisle.dimensions?.measurementUnit}
          </MuteText>
          <MuteText className="ml-3 d-flex">
            <ArrowsHorizontal className="mr-1" size={17} />
            {aisle.dimensions?.length}
            {aisle.dimensions?.measurementUnit}
          </MuteText>
        </div>
      </AisleView>
      <RowContainer>
        {CommonUtility.isValidArray(aisle.right) &&
          aisle.right?.map((rack, index) => renderRack(1, rack, index))}
      </RowContainer>
    </AisleViewContainer>
  );
}
