import { useEffect, useState } from 'react';
import { InventoryService } from '../../utility';

export const ShipmentItemLocationsHook = (
  warehouseId,
  productId,
  currentQuantity,
  newInventory,
) => {
  const [data, setData] = useState([]);
  const [error, setError] = useState([]);
  const [loading, setLoading] = useState([]);

  const fetchDestinationOptions = async () => {
    try {
      setLoading(true);
      const payload = {
        warehouseId,
        items: [
          {
            productId,
            inventoryType: 'case',
            quantity: {
              total: currentQuantity,
              available: newInventory,
            },
          },
        ],
      };
      const res = await InventoryService.getInventoryProposals(payload);
      const options = (res?.[0]?.proposedLocations || [])?.map(location => ({
        text: location?.location?.code,
        value: location?.location?._id,
        key: location?.location?._id,
      }));
      setData(options);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (warehouseId && productId) {
      fetchDestinationOptions();
    }
  }, [warehouseId, productId]);

  return {
    data,
    loading,
    error,
  };
};

export const ItemDetailsExtractorHook = () => {};
