import { createContext, useContext, useMemo, useState } from 'react';
import {
  ChartLine,
  ShoppingCart,
  ProjectorScreenChart,
  Stack,
  StackPlus,
  Truck,
  ListBullets,
  Waves,
  Cube,
  CalendarX,
} from '@phosphor-icons/react';
import { Image } from 'semantic-ui-react';
import { useAuth } from './auth';
import { Images } from '../images';

const SidebarContext = createContext();

export function SidebarProvider({ children }) {
  const { isAuthenticated } = useAuth();
  // in some cases, we may need to hide the sidebar
  const [showSidebar, setShowSidebar] = useState(true);
  const [footerActive, setFooterActive] = useState(false);

  const toggleSidebarVisibility = (showSidebar = true) => {
    setShowSidebar(showSidebar);
  };

  const [isCollapsed, setIsCollapsed] = useState(true);

  const menus = useMemo(() => {
    const order = {
      text: 'Fulfill',
      parentLink: '/app/fulfill',
      key: 'order-parent',
      phosphorIcon: <ShoppingCart className="sidebar-icon-main" />,
      menus: [
        {
          phosphorIcon: <Cube className="sidebar-icon" />,
          key: 'fulfill-orders',
          text: 'Orders',
          link: '/app/fulfill/orders/',
        },
        {
          phosphorIcon: <Waves className="sidebar-icon" />,
          text: 'Waves',
          key: 'wave-list',
          link: '/app/fulfill/waves/list/building',
        },
      ],
    };

    const temp = [];
    temp.push(
      {
        text: 'Dash',
        link: '/app/dashboard',
        parentLink: '/app/dashboard',
        key: 'dash-parent',
        phosphorIcon: <ChartLine className="sidebar-icon-main" />,
        menus: [
          {
            phosphorIcon: <ProjectorScreenChart className="sidebar-icon" />,
            key: 'analytics',
            text: 'Analytics',
            link: '/app/dashboard/',
          },
        ],
      },
      {
        text: 'Inven-tory',
        link: '/app/inventory',
        parentLink: '/app/inventory',
        key: 'inventory-parent',

        phosphorIcon: <Stack className="sidebar-icon-main" />,
        menus: [
          {
            phosphorIcon: <StackPlus className="sidebar-icon" />,
            text: 'Inventory Import',
            link: '/app/inventory/import',
            key: 'inventory-import',
          },
          {
            phosphorIcon: <ListBullets className="sidebar-icon" />,
            key: 'inventory-list',
            text: 'Inventory List',
            link: '/app/inventory/list',
          },
          {
            phosphorIcon: <CalendarX className="sidebar-icon" />,
            key: 'inventory-expiration',
            text: 'Inventory Expirations',
            link: '/app/inventory/expirations',
          },
          {
            phosphorIcon: (
              <Image
                src={Images.Icons.transferLogoWhite}
                className="sidebar-icon-img"
              />
            ),
            key: 'inventory-transfer',
            text: 'Inventory Transfer',
            link: '/app/inventory/transfer',
          },
        ],
      },
      {
        text: 'Ship-ments',
        link: '/app/shipments',
        parentLink: '/app/shipments',
        key: 'shipment-parent',

        phosphorIcon: <Truck className="sidebar-icon-main" />,
        menus: [
          {
            phosphorIcon: <Truck className="sidebar-icon" />,
            text: 'Shipments',
            key: 'shipment-list',
            link: '/app/shipments/',
          },
        ],
      },
    );

    temp.push(order);

    return temp;
  }, [isAuthenticated]);

  return (
    <SidebarContext.Provider
      value={{
        menus,
        isCollapsed,
        setIsCollapsed,
        toggleSidebarVisibility,
        showSidebar,
        setFooterActive,
        footerActive,
      }}
    >
      {children}
    </SidebarContext.Provider>
  );
}

export const useSidebar = () => useContext(SidebarContext);
