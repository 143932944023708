import { TableCell, TableRow } from 'semantic-ui-react';
import { Check, Circle, Waves } from '@phosphor-icons/react';
import styled from 'styled-components';
import { DateFormat, DateUtility, theme } from '../../../utility';
import { BoldText, ColoredPill, ColorText, MuteText } from '../../../elements';
import { WaveStatusRenderer } from '../common';
import { ActionEditDots, ActionMenuItem, ItemImage } from '../../../components';

function OrderTime({ time, isEnabled }) {
  let timeColor = '';
  const noOfDays = DateUtility.noOfDays(time);
  if (isEnabled) {
    if (noOfDays < 1) {
      timeColor = theme.colors.green;
    } else if (noOfDays <= 2) {
      timeColor = theme.colors.yellow;
    } else if (noOfDays <= 5) {
      timeColor = theme.colors.orange;
    } else if (noOfDays > 5) {
      timeColor = theme.colors.danger;
    }
  }
  return (
    <ColorText color={timeColor}>
      <b>{DateUtility.hourMinuteTime(time)}</b>
    </ColorText>
  );
}

const ActionEditTableRow = styled(TableRow)`
  position: relative;

  .action-container {
    margin: auto;
    background: transparent;
    right: 10px;
    bottom: 0;
  }

  &:hover {
    img.m-auto {
      opacity: 0.3;
    }
    .action-container {
      opacity: 1 !important;
    }
  }
`;

export function UnAssignedRow({ item, assignAllocation, fulfillOrder }) {
  return (
    <ActionEditTableRow>
      <TableCell>
        <div className="d-flex">
          <ItemImage
            imageKey="images"
            displayImageKey="display_image"
            item={item.inventory.product}
          />
          <div className="ml-4">
            <div>{item.inventory.product.name}</div>
            <div className="d-flex">
              <MuteText className="mr-2">SKU</MuteText>
              <BoldText>{item.inventory.product.sku}</BoldText>
            </div>
          </div>
        </div>
      </TableCell>
      <TableCell>
        <WaveStatusRenderer status={item.status} />
      </TableCell>
      <TableCell>
        <OrderTime time={item.createdAt} isEnabled />
        <div>
          <MuteText>
            {DateUtility.formatDate(item.createdAt, DateFormat.barChartMonth)}
          </MuteText>
        </div>
      </TableCell>
      <TableCell>
        <ColoredPill color={item.inventory?.zone?.color}>
          <Circle weight="fill" /> {item.inventory?.zone?.code}
        </ColoredPill>
      </TableCell>
      <TableCell>
        <div>
          <MuteText>Rack:</MuteText>
          <span className="ml-2">{item.inventory?.rack?.code}</span>
        </div>
        <div>
          <MuteText>Bay:</MuteText>
          <span className="ml-2">{item.inventory?.location?.code}</span>
        </div>
      </TableCell>
      <TableCell>
        <div>
          <MuteText>Qty:</MuteText>
          <span className="ml-2">
            {item.quantity} <MuteText>Unit(s)</MuteText>
          </span>
        </div>
        <div>
          <MuteText>Order ID:</MuteText>
          <span className="ml-2">{item.order?.marketplaceOrderDisplayId}</span>
        </div>
        <ActionEditDots preventChangingBg on="hover" position="left center">
          <ActionMenuItem
            onClick={() => {
              assignAllocation(item);
            }}
          >
            <Waves />
            Assign To Wave
          </ActionMenuItem>
          <ActionMenuItem onClick={() => fulfillOrder(item)}>
            <Check /> Mark Fulfilled
          </ActionMenuItem>
        </ActionEditDots>
      </TableCell>
    </ActionEditTableRow>
  );
}
