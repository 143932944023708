import { TableCell, TableRow } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { FlowArrow } from '@phosphor-icons/react';
import { DateFormat, DateUtility } from '../../utility';
import { MuteText } from '../../elements';
import { OrderTime, WaveStatusRenderer } from './common';
import { OrganisationAvatar } from '../../components';

export function WaveRow({ item }) {
  const user = item.assignedTo?.userId;

  return (
    <TableRow>
      <TableCell>
        <Link to={`/app/fulfill/waves/${item._id}`}>{item.waveNumber}</Link>
      </TableCell>
      <TableCell>
        <WaveStatusRenderer status={item.status} />
      </TableCell>
      <TableCell>
        <OrderTime time={item.createdAt} isEnabled />
        <div>
          <MuteText>
            {DateUtility.formatDate(item.createdAt, DateFormat.barChartMonth)}
          </MuteText>
        </div>
      </TableCell>
      <TableCell>{item.warehouseId?.name}</TableCell>
      <TableCell>
        <div className="text-nowrap d-flex">
          <FlowArrow size={17} className="mr-1" /> {item.waveRuleId?.name}
        </div>
      </TableCell>
      <TableCell>
        {user ? (
          <div className="d-flex align-items-center">
            <OrganisationAvatar name={user?.firstName} />
            &nbsp;{user?.firstName}
          </div>
        ) : (
          <MuteText>Not Assigned</MuteText>
        )}
      </TableCell>
      <TableCell>
        <div>
          <div className="d-flex">
            <MuteText>Items</MuteText>
            <span className="ml-2">{item.metadata.itemCount}</span>
          </div>
          <div className="d-flex">
            <MuteText>Starting Rack</MuteText>
            <span className="ml-2">{item.metadata.startingRack?.code}</span>
          </div>
        </div>
      </TableCell>
    </TableRow>
  );
}
