import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { DesktopMode, MobileMode } from './ResponsiveMedia';
import { DesktopMenuBar } from './DesktopSecureMenu';
import { MobileSecureMenu } from './MobileSecureMenu';
import { useSidebar } from '../contexts/sidebar';

export function SecureMenu({ hideSidebar }) {
  const { pathname } = useLocation();

  const [openMobileMenu, setOpenMobileMenu] = useState(false);
  const [activeMenu, setActiveMenu] = useState(0);

  const { isCollapsed, menus } = useSidebar();

  useEffect(() => {
    closeMobilePopupSidebar();
  }, [pathname]);

  const closeMobilePopupSidebar = () => {
    setOpenMobileMenu(false);
  };

  return (
    <>
      <DesktopMode>
        <DesktopMenuBar
          isCollapsed={isCollapsed}
          menus={menus}
          hideSidebar={hideSidebar}
        />
      </DesktopMode>
      <MobileMode>
        <MobileSecureMenu
          activeMenu={activeMenu}
          closeMobilePopupSidebar={closeMobilePopupSidebar}
          isCollapsed={isCollapsed}
          openMobileMenu={openMobileMenu}
          setActiveMenu={setActiveMenu}
          setOpenMobileMenu={setOpenMobileMenu}
          sidebarMenu={menus}
        />
      </MobileMode>
    </>
  );
}
