import { useMemo } from 'react';
import styled from 'styled-components';
import { StylingUtility, theme } from '../utility';

const Pill = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 7px;
  padding: 3px 13px;
  border-radius: 15px;
  white-space: nowrap;
  width: fit-content;
`;

export const GreyPill = styled(Pill)`
  background: ${({ theme }) => theme.colors.box};
`;

export const WhitePill = styled(Pill)`
  background: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.grey};
`;

export const GreenPill = styled(Pill)`
  background: #e4f1e5;
  color: ${({ theme }) => theme.colors.green};
`;

export const OrangePill = styled(Pill)`
  background: #f7eee0;
  color: ${({ theme }) => theme.colors.orange};
`;

const CustomPill = styled(Pill)`
  background: ${({ bgColor }) => bgColor};
  color: ${({ textColor }) => textColor};
`;

export function ColoredPill({ color, children, ...rest }) {
  const bgColor = useMemo(() => {
    if (['rgb(255,255,255)', '#FFFFFF', '#ffffff'].includes(color)) {
      return '#000000';
    }

    if (color) {
      return StylingUtility.getLowerOpacityColor(color);
    }

    return theme.colors.box;
  }, [color]);

  return (
    <CustomPill bgColor={bgColor} textColor={color} {...rest}>
      {children}
    </CustomPill>
  );
}
