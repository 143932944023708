import styled from 'styled-components';
import { useMemo } from 'react';
import { MapPin, Package } from '@phosphor-icons/react';
import { ActionEditDots, ActionMenuItem, ItemImage } from '../../../components';
import { MuteText } from '../../../elements';
import { CommonUtility } from '../../../utility';

const NameContainer = styled.div`
  .product-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 120px;
  }
  .sku {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 85px;
  }
`;

const IconContainer = styled.div`
  color: ${({ theme }) => theme.colors.grey};
  margin-bottom: -4px;
`;

export function MobileWaveDetailRow({
  item,
  onClick,
  isActionAllowed,
  isSorting,
  itemMarkedTracker,
}) {
  const totalQty = useMemo(() => {
    if (CommonUtility.isValidArray(item.sameItems)) {
      let sum = 0;
      item.sameItems.forEach(item => {
        sum += item.quantity;
      });
      return sum;
    }
    return item.quantity;
  }, [item]);

  const needsAction = useMemo(() => {
    if (CommonUtility.isValidArray(item.sameItems)) {
      let anyAction = false;
      item.sameItems.forEach(sameItem => {
        if (
          (isSorting && itemMarkedTracker[sameItem.id] !== 'sorted') ||
          (!isSorting && itemMarkedTracker[sameItem.id] !== 'picked')
        ) {
          anyAction = true;
        }
      });
      return anyAction;
    }
    return (
      (isSorting && itemMarkedTracker[item.id] !== 'sorted') ||
      (!isSorting && itemMarkedTracker[item.id] !== 'picked')
    );
  }, [item, itemMarkedTracker]);

  return (
    <div className="row mobile-row">
      <div className="col-7 d-flex align-items-center">
        <ItemImage
          item={item.inventory.product}
          imageKey="images"
          displayImageKey="display_image"
          size="mini"
        />
        <NameContainer className="d-flex flex-column ml-2">
          <MuteText className="product-name">
            {item.inventory?.product?.name}
          </MuteText>
          <div className="d-flex align-items-center">
            <MuteText>SKU</MuteText>
            <span className="ml-1 sku">{item.inventory?.product?.sku}</span>
          </div>
        </NameContainer>
      </div>
      <div className="col-4 d-flex flex-column">
        <div className="d-flex align-items-center">
          <IconContainer>
            <Package size={14} />
          </IconContainer>
          <span className="ml-1">{totalQty}</span>
        </div>
        <div className="d-flex align-items-center">
          <IconContainer>
            <MapPin size={14} />
          </IconContainer>
          <span className="ml-1">{item?.inventory?.location?.code}</span>
        </div>
      </div>
      <div className="col-1 d-flex align-items-center">
        {isActionAllowed && needsAction && (
          <ActionEditDots
            preventChangingBg
            on="hover"
            position="bottom left"
            showByDefault
            offset={[0, -10]}
          >
            <ActionMenuItem
              onClick={() => {
                onClick(item);
              }}
            >
              {isSorting ? 'Sort' : 'Pick'}
            </ActionMenuItem>
          </ActionEditDots>
        )}
      </div>
    </div>
  );
}
