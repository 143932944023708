import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { ThemeProvider } from 'styled-components';
import { BrowserRouter as Router } from 'react-router-dom';
import { ToastContainer, Zoom } from 'react-toastify';
import App from './App';
import { theme } from './utility/theme';
import { AuthProvider } from './contexts/auth';
import 'react-toastify/dist/ReactToastify.css';
import 'semantic-ui-css/semantic.min.css';
import 'react-image-crop/dist/ReactCrop.css';
import 'react-datepicker/dist/react-datepicker.css';
import { HubSpotProvider } from './contexts/hubspot';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ThemeProvider theme={theme}>
    <ToastContainer
      icon={false}
      enableMultiContainer
      transition={Zoom}
      theme="colored"
    />
    <Router>
      <AuthProvider>
        <HubSpotProvider>
          <App />
        </HubSpotProvider>
      </AuthProvider>
    </Router>
  </ThemeProvider>,
);
