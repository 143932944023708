export class CacheUtility {
  static cache = {};

  static save = (key, value, minutes = 10) => {
    CacheUtility.cache[key] = {
      data: Array.isArray(value) ? [...value] : { ...value },
      valid: new Date().getTime() + minutes * 60 * 1000,
    };
  };

  static get = key => {
    if (CacheUtility.cache[key]) {
      if (CacheUtility.cache[key].valid > new Date().getTime()) {
        return CacheUtility.cache[key].data;
      }
    }
    return null;
  };

  static remove = key => {
    delete CacheUtility.cache[key];
  };

  static containsRemoved = key => {
    const keys = Object.keys(CacheUtility.cache);
    const temp = keys.filter(x => x.key?.includes(key));
    temp.forEach(item => {
      CacheUtility.remove(item);
    });
  };

  static removeAll = () => {
    CacheUtility.cache = {};
  };
}

export const CacheTimes = {
  TWO_HOURS: 120,
};
