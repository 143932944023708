import { APIPath } from '../constants';
import { BaseService } from './base';
import { CRUDService } from './crud';

class Account extends CRUDService {
  constructor() {
    super(APIPath.account);
  }

  switchOrganization(organizationId) {
    return BaseService.put(APIPath.accountOrganization, { organizationId });
  }
}

const AccountService = new Account();
Object.freeze(AccountService);
export { AccountService };
