import { useMemo } from 'react';
import { Placeholder, Segment, Card, Table } from 'semantic-ui-react';
import styled from 'styled-components';

const SegmentContainer = styled(Segment)`
  background: ${({ theme }) => theme.colors.white} !important;
`;

export function AppListPlaceholder({ hideImages, size = 3 }) {
  const lines = useMemo(
    () => Array.from({ length: size }, (_, k) => k),
    [size],
  );

  return (
    <>
      {lines.map(line => (
        <SegmentContainer basic key={line}>
          <Placeholder fluid>
            {!hideImages && (
              <Placeholder.Header image>
                <Placeholder.Line />
                <Placeholder.Line />
              </Placeholder.Header>
            )}
            <Placeholder.Paragraph>
              <Placeholder.Line />
              <Placeholder.Line />
              <Placeholder.Line />
              <Placeholder.Line />
            </Placeholder.Paragraph>
          </Placeholder>
        </SegmentContainer>
      ))}
    </>
  );
}

export function AppImagePlaceholder({ rows = 3, itemPerRow = 3 }) {
  const lines = useMemo(
    () => Array.from({ length: rows }, (_, k) => k),
    [rows],
  );
  const columns = useMemo(
    () => Array.from({ length: itemPerRow }, (_, k) => k),
    [rows],
  );

  return (
    <>
      {lines.map(line => (
        <Card.Group key={line} doubling itemsPerRow={itemPerRow} stackable>
          {columns.map(column => (
            <Card key={column}>
              <Placeholder>
                <Placeholder.Image square />
              </Placeholder>
              <Card.Content>
                <Placeholder>
                  <Placeholder.Header>
                    <Placeholder.Line length="very short" />
                    <Placeholder.Line length="medium" />
                  </Placeholder.Header>
                  <Placeholder.Paragraph>
                    <Placeholder.Line length="short" />
                  </Placeholder.Paragraph>
                </Placeholder>
              </Card.Content>
            </Card>
          ))}
        </Card.Group>
      ))}
    </>
  );
}

export function AppTableRowPlaceholder({ colSpan, rows = 3, catalogMobile }) {
  const lines = useMemo(
    () => Array.from({ length: rows }, (_, k) => k),
    [rows],
  );

  return (
    <>
      {catalogMobile ? (
        <Placeholder fluid className="m-3">
          <Placeholder.Header image>
            <Placeholder.Line />
            <Placeholder.Line />
          </Placeholder.Header>
        </Placeholder>
      ) : (
        lines.map(line => (
          <Table.Row key={line}>
            <Table.Cell colSpan={colSpan}>
              <Placeholder fluid>
                <Placeholder.Header image>
                  <Placeholder.Line />
                  <Placeholder.Line />
                </Placeholder.Header>
              </Placeholder>
            </Table.Cell>
          </Table.Row>
        ))
      )}
    </>
  );
}
